<template>
  <v-container class="px-1">
    <BaseScreenHeader
      title="My Workouts"
      screenInfo="WRK-002"
      :showBack="false"
      :refresh="true"
      @refresh="refresh"
    />
    <template
      v-if="
        loadedList.length == 0 && loaded && currentPage == 0 && search == null
      "
    >
      <v-sheet
        rounded
        :class="
          textSizeXSmall + ' paper--text mt-4 pa-2 charcoal charcoalTileMenu'
        "
      >
        <v-icon color="paper" x-large>mdi-information</v-icon>
        <span :class="textSizeSmall">You have no workouts.</span>
        <p class="ml-2">
          To get started, copy the default workouts using the button below. You
          can always add a new workout from scratch later.
        </p>
      </v-sheet>
      <v-card-actions class="pa-0">
        <v-spacer />
        <BaseActionButton
          xlarge
          icon="mdi-pencil-box-multiple-outline"
          title="Copy Default Workouts"
          label="Copy Default Workouts"
          tonal
          block
          class="paper--text"
          @clickedThis="copyDefaultWorkouts"
        />
      </v-card-actions>
    </template>
    <template v-else>
      <BaseFreeTier v-if="loaded && loadedList.length > 4" />
      <v-icon
        v-if="scrollPosition <= 50 && loaded"
        large
        color="paper"
        :class="isPhone ? 'addBtn' : 'addBtnDesktop'"
        @click="addMore"
      >
        mdi-plus-circle-outline
      </v-icon>
      <span
        v-if="scrollPosition <= 50 && loaded"
        :class="
          isPhone
            ? 'leftTools charcoal charcoalTileMenu fabsearch'
            : 'leftToolsDesktop charcoal charcoalTileMenu fabsearch'
        "
      >
        <v-btn
          color="transparent silver--text "
          fab
          dark
          class="elevation-0 btn-fix "
          :retain-focus-on-click="false"
          @click="toggleFavorites"
        >
          <v-icon color="accent" class="ml-2" large>
            {{ favoritesOnly ? 'mdi-heart' : 'mdi-heart-outline' }}
          </v-icon>
        </v-btn>
        <v-btn
          color="transparent silver--text  "
          fab
          dark
          class="elevation-0 btn-fix "
          :retain-focus-on-click="false"
          @click="showSearchingDialog = !showSearchingDialog"
        >
          <v-icon class="ml-2" large>
            {{ showSearchingDialog ? 'mdi-chevron-left' : 'mdi-magnify' }}
          </v-icon>
        </v-btn>
      </span>
      <v-dialog
        v-model="showSearchingDialog"
        scrollable
        :fullscreen="isPhone"
        overlay-opacity="0.90"
        @keydown.esc="cancelSearch"
        :max-width="dialogWidth"
      >
        <v-card
          v-touch:swipe.left="cancelSearch"
          tile
          flat
          class="d-flex flex-column noscroll charcoal "
        >
          <BaseCloseDialogHeader
            heading="Search Workouts"
            icon=""
            color="paper"
            @cancel="cancelSearch"
            class="noscroll"
          />
          <v-sheet
            class="transparent charcoalTileMenu mx-2 px-2 mb-2 py-10 rounded-lg"
            elevation="1"
          >
            <v-card-actions class="pa-0">
              <v-text-field
                height="70"
                dark
                solo
                background-color="charcoal lighten-2"
                clearable
                color="paper"
                :label="'Search your (' + num_workouts + ') workouts'"
                v-model="search"
                prepend-inner-icon="mdi-magnify"
                single-line
                hide-details
                @focus="$event.target.select()"
                @click:clear="debouncedSearch"
                @keydown.enter="debouncedSearch"
              />
              <v-icon
                color="paper"
                x-large
                title="Search Workouts"
                @click="debouncedSearch"
              >
                mdi-magnify
              </v-icon>
            </v-card-actions>
          </v-sheet>
          <v-card-actions :class="textSize + ' silver--text'">
            <v-spacer />
            OR
            <v-spacer />
          </v-card-actions>
          <v-sheet
            class="transparent charcoalTileMenu mx-2 px-2 py-10 pb-5 rounded-lg"
            elevation="1"
          >
            <v-select
              height="70"
              solo
              background-color="charcoal lighten-2"
              dark
              v-model="sortBy"
              :items="workoutSortByList"
              label="Sort By?"
              persistent-hint
              item-text="text"
              item-value="value"
              prepend-inner-icon="mdi-sort"
              class="success--text"
              color="success"
              item-color="success"
              @change="changeSort"
              :menu-props="{
                maxHeight: 320,
                closeOnClick: true,
                transition: 'fab-transition'
              }"
            >
              <template
                v-slot:selection="{ item, index, selected, disabled, remove }"
              >
                <v-chip
                  class="silver lighten-3 pa-4 text-h6"
                  :key="index"
                  :input-value="item.value"
                  :disabled="disabled"
                >
                  <span class="black--text"> {{ item.text }}</span>
                </v-chip>
              </template>
              <template v-slot:item="{ item, on, attrs }">
                <v-list-item v-bind="attrs" v-on="on" class="transparent">
                  <v-list-item-content class="paper lighten-2">
                    <v-list-item-title class="text-h6 black--text">{{
                      item.text
                    }}</v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </template>
            </v-select>
          </v-sheet>
          <v-spacer />
          <v-sheet rounded class="transparent mx-1 mb-1" elevation="2">
            <v-card-actions :class="textSize + ' silver--text'">
              <v-spacer />
              OR
              <v-spacer />
            </v-card-actions>
            <v-card-actions class="pa-0">
              <v-spacer />
              <BaseActionButton
                class="charcoalTileMenu fullbtn"
                color="paper"
                icon="mdi-magnify-plus-outline"
                label="Use Advanced Search"
                title="Use Advanced Search"
                text
                block
                large
                @clickedThis="
                  $router.push({
                    path: '/searchworkouts'
                  })
                "
              />
            </v-card-actions>
          </v-sheet>
        </v-card>
      </v-dialog>
      <v-sheet rounded class="charcoal pa-0 mx-0 mt-2 pt-10" elevation="0">
        <v-card-actions class="py-0 mb-n5 mt-4">
          <v-spacer />
          <v-checkbox
            dark
            v-model="showDetails"
            on-icon="mdi-chevron-up-circle"
            off-icon="mdi-chevron-down-circle"
            label="Show Details?"
            color="progressActive"
          />
        </v-card-actions>
        <v-row no-gutters v-if="workouts.length > 0" class="pa-0">
          <v-col
            class="px-1"
            v-for="workout in workouts"
            :key="workout.id"
            cols="12"
            md="6"
          >
            <v-card
              rounded
              class="charcoal silver--text my-2 charcoalTileMenu"
              elevation="0"
            >
              <v-card-actions class="pt-0 px-0">
                <v-btn
                  height="70"
                  elevation="0"
                  block
                  text
                  class="charcoalTileMenu"
                  @click="start(workout)"
                >
                  <v-icon size="50" color="progressActive">
                    mdi-play-circle
                  </v-icon>
                  <span class="ml-1 mt-1 text-h6 progressActive--text">
                    {{
                      workout.is_incomplete == 'Yes'
                        ? 'Resume Workout'
                        : 'Start ' + workout.type
                    }}
                  </span>
                </v-btn>
              </v-card-actions>
              <v-sheet color="transparent" class="silver--text">
                <v-card-title :class="textSize + ' paper--text  px-1 py-0'">
                  <v-icon
                    v-if="workout.is_favorite == 'Yes'"
                    color="accent"
                    large
                    title="Remove From Favorites"
                    @click="removeFavorite(workout)"
                  >
                    mdi-heart
                  </v-icon>
                  <v-icon
                    v-else
                    color="accent"
                    title="Add To Favorites"
                    large
                    @click="addFavorite(workout)"
                  >
                    mdi-heart-outline
                  </v-icon>
                  <span class="pl-1">{{ trimName(workout) }}</span>
                  <v-spacer />
                </v-card-title>

                <template>
                  <v-card-actions
                    :class="textSizeXSmall + ' silver--text py-0 px-2'"
                  >
                    Used {{ workout.counter }} times, copied
                    {{ workout.copied }} times.
                    <v-spacer />
                    <span
                      v-if="workout.public == 'Yes'"
                      :class="textSizeXSmall + ' progressActive--text link'"
                      @click="manageVisibility(workout)"
                    >
                      PUBLIC
                    </span>
                    <span
                      v-else
                      :class="textSizeXSmall + ' orange--text link'"
                      @click="manageVisibility(workout)"
                    >
                      PRIVATE
                    </span>
                  </v-card-actions>
                  <v-card-actions
                    :class="textSizeXSmall + ' silver--text pt-0 px-2'"
                  >
                    Last Used:
                    {{
                      workout.is_unused == 'No'
                        ? prettyDate(workout.last_run)
                        : 'NA'
                    }}
                    <v-spacer />
                    <span
                      title="Workout Type"
                      :class="textSizeXSmall + ' silver--text'"
                    >
                      {{ workout.type }}
                      <span
                        class="text-uppercase"
                        v-if="workout.type == 'Hybrid'"
                      >
                        - {{ workout.xmode }}
                      </span>
                    </span>
                  </v-card-actions>
                </template>
                <v-card-actions class="pb-0 pt-0">
                  <v-divider class="charcoal lighten-2" />
                  <v-badge
                    color="progressActive charcoal--text"
                    inline
                    light
                    :content="badgeContent(workout)"
                  >
                  </v-badge>
                  <v-divider class="charcoal lighten-2" />
                </v-card-actions>
                <v-card-actions class="">
                  <v-spacer />
                  <v-icon large color="silver">
                    mdi-focus-field
                  </v-icon>
                  FOCUS :
                  {{ workout.muscle_group }}
                  <v-spacer />
                </v-card-actions>
                <v-card-text
                  :class="textSizeSmall + ' paper--text pt-0 pl-2'"
                  v-if="showDetails"
                >
                  {{ workout.description }}
                </v-card-text>
                <v-card-text class="pl-0 ">
                  <v-list dense subheader class="transparent">
                    <v-card-text
                      v-for="exercise in workout.exercises"
                      :key="workout.id + exercise.id + exercise.sort"
                      class="pa-0 mb-3"
                    >
                      <v-badge
                        class="pa-0"
                        color="progressActive charcoal--text"
                        left
                        inline
                        :content="exercise.sort"
                      >
                        <span
                          :class="textSizeSmall + ' ml-1 link paper--text'"
                          :title="'View Details for ' + exercise.name"
                          @click="showExercise(exercise.id)"
                        >
                          {{ exercise.name }}
                        </span>
                      </v-badge>

                      <v-card-actions class="pa-0 ml-8">
                        <span class=" silver--text">
                          {{ exerciseInfo(exercise) }}
                        </span>
                      </v-card-actions>
                      <v-card-actions class="pa-0 ml-5 mt-n1">
                        <BaseActionButton
                          v-if="
                            workout.type == 'Lifting' && loaded && showDetails
                          "
                          :label="repSchemeName(exercise.repschemeid)"
                          plain
                          color="silver"
                          small
                          :large="!isPhone"
                          icon="mdi-information-outline"
                          @clickedThis="selectRepScheme(exercise.repschemeid)"
                        />
                      </v-card-actions>
                    </v-card-text>
                  </v-list>
                </v-card-text>
                <v-card-actions class="px-0 charcoalTile">
                  <BaseActionButton
                    v-if="workout.adhoc == 'Yes'"
                    :large="!isPhone"
                    icon="mdi-pencil-box-multiple-outline"
                    :title="'Convert Workout: ' + workout.name"
                    label="Convert"
                    color="accent"
                    plain
                    @clickedThis="convertWorkout(workout)"
                  />
                  <BaseActionButton
                    v-else
                    :large="!isPhone"
                    icon="mdi-pencil-outline"
                    :title="'Edit Workout: ' + workout.name"
                    label="Edit"
                    text
                    color="silver"
                    @clickedThis="editWorkout(workout)"
                  />
                  <BaseActionButton
                    :large="!isPhone"
                    icon="mdi-pencil-box-multiple-outline"
                    :title="'Copy Workout: ' + workout.name"
                    label=""
                    text
                    color="silver"
                    @clickedThis="copyWorkout(workout)"
                  />
                  <v-spacer />
                  <BaseActionButton
                    :large="!isPhone"
                    :icon="
                      workout.packed == 'Yes'
                        ? 'mdi-package-variant-plus'
                        : 'mdi-package-variant'
                    "
                    :title="'Pack Workout: ' + workout.name"
                    :label="workout.packed == 'Yes' ? 'RePack' : 'Pack'"
                    text
                    color="paper"
                    @clickedThis="pack(workout)"
                  />
                  <BaseActionButton
                    :large="!isPhone"
                    icon="mdi-share"
                    :title="'Edit Workout: ' + workout.name"
                    label="Share"
                    text
                    color="paper"
                    @clickedThis="shareWorkout(workout)"
                  />
                </v-card-actions>
              </v-sheet>
            </v-card>
          </v-col>
        </v-row>
      </v-sheet>
    </template>
    <BaseLoading :loaded="loaded" />
    <LoadingDialog
      :show="!loaded && currentPage == 0"
      title="LOADING Workouts..."
      color="accent"
    />

    <v-dialog v-model="showConvertDialog" persistent width="700">
      <v-sheet color="charcoal" class="silver--text">
        <v-card-title class="title">
          Convert Workout?
        </v-card-title>
      </v-sheet>
      <v-form ref="convertWorkoutForm" name="convertWorkoutForm">
        <v-card>
          <v-card-text>
            This allows you to convert an existing unplanned (adhoc) workout to
            a saved, planned workout.
            <v-card-subtitle class="title progressActive--text">
              Current Workout Name
              <div
                class="body-1 charcoal--text"
                v-html="selectedWorkout.name"
              ></div>
            </v-card-subtitle>
            <v-row dense>
              <v-col cols="12">
                <v-text-field
                  v-model="selectedWorkout.newname"
                  ref="name"
                  :counter="50"
                  label="New Workout Name"
                  prepend-icon="mdi-rename-box"
                />
              </v-col>
            </v-row>
            <v-card-subtitle class="body-1 pt-5">
              Update the workout name above and then click the
              <span class="progressActive--text">Convert </span> button.
            </v-card-subtitle>
          </v-card-text>
          <v-card-actions>
            <BaseActionButton
              icon="mdi-close"
              label="Cancel"
              plain
              @clickedThis="showConvertDialog = false"
            />
            <v-spacer />
            <BaseActionButton
              label="Convert"
              plain
              icon="mdi-pencil-box-multiple-outline"
              @clickedThis="convertNow()"
            />
          </v-card-actions>
        </v-card>
      </v-form>
    </v-dialog>
    <v-dialog
      v-model="showRepSchemeDialog"
      scrollable
      :fullscreen="isPhone"
      overlay-opacity="0.90"
      @keydown.esc="cancelSearch"
    >
      <v-card
        class="d-flex flex-column charcoal noscroll-x"
        flat
        v-touch:swipe.left="cancelRepscheme"
      >
        <BaseCloseDialogHeader
          :heading="'Details: ' + selectedRepScheme.name"
          @cancel="showRepSchemeDialog = false"
        />
        <v-sheet color="charcoal" class="silver--text">
          <v-card-title class="title paper--text"> </v-card-title>
        </v-sheet>
        <v-card-subtitle :class="textSizeXSmall + ' silver--text pt-4 pb-1'">
          REPSCHEME DETAILS
        </v-card-subtitle>
        <template>
          <v-card-text>
            <v-divider class="pb-1" />
            <v-card-title class="progressActive--text pb-0 px-0">
              Info
            </v-card-title>
            <v-card-text :class="textSizeXSmall + ' silver--text pb-0'">
              {{ selectedRepScheme.display_name }}
              <br />
              Utility:{{ selectedRepScheme.utility }}<br />
              Scheme Type: {{ selectedRepScheme.type }}
            </v-card-text>
            <v-card-title class="progressActive--text px-0 pb-0">
              Sets: {{ selectedRepScheme.sets }}
            </v-card-title>
            <v-card-text
              class="pl-4 py-0 silver--text"
              v-for="set in selectedRepScheme.detailsObject"
              :key="set.setnum"
            >
              <v-icon class="progressActive--text pr-2">
                {{ iconNumber(set.setnum - 1) }}
              </v-icon>

              Reps: {{ set.reps }}, Rest: {{ set.rest }} seconds
              <v-spacer />
              <span class="ml-8">
                @
                <template v-if="set.load_type == '1RM'">
                  {{ set.load }}% {{ set.load_type }}
                </template>
                <template v-else>
                  {{ set.load_imperial }} lbs / {{ set.load_metric }} kg
                </template>
                + {{ set.load_increment }} lbs/kg
              </span>
            </v-card-text>
            <template v-if="selectedRepScheme.description != null">
              <v-card-title class="progressActive--text pb-0 px-0">
                Description
              </v-card-title>
              <v-card-text v-html="selectedRepScheme.description">
              </v-card-text>
            </template>
          </v-card-text>
        </template>
        <v-card-actions class="px-0">
          <v-spacer />
          <BaseActionButton
            icon="mdi-checkbox-outline"
            :large="false"
            title="Done"
            label="Done"
            plain
            color="paper"
            @clickedThis="showRepSchemeDialog = false"
          />
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="showCopyDialog"
      persistent
      overlay-color="charcoal"
      overlay-opacity=".95"
      :fullscreen="isPhone"
    >
      <v-card
        tile
        class="charcoal silver--text d-flex flex-column noscroll"
        v-touch:swipe.left="cancelCopy"
      >
        <BaseCloseDialogHeader
          heading="Duplicate Workout?"
          @cancel="showCopyDialog = false"
        />
        <v-card-text class="silver--text px-2 mt-2">
          This allows you to duplicate one of your workouts. You will then be
          able to tweak it and use it as you see fit.
          <v-card-text class="title silver--text px-0 pb-0">
            Current Workout Name
            <div class="body-1 charcoal--text" v-html="selectedWorkout.name" />
          </v-card-text>
          <v-text-field
            filled
            class="silver--text mt-n4"
            dark
            v-model="selectedWorkout.newname"
            ref="name"
            :counter="50"
            label="New Workout Name"
            prepend-inner-icon="mdi-rename-box"
            @focus="$event.target.select()"
          />
          <v-card-text class="body-1 pt-5 px-0">
            Update the workout name above and then click the
            <span class="paper--text">Duplicate </span> button.
          </v-card-text>
        </v-card-text>
        <v-spacer />
        <v-card-actions class="pa-0">
          <v-spacer />
          <BaseActionButton
            :disabled="isEmpty(selectedWorkout.newname)"
            label="Duplicate"
            plain
            title="Duplicate Workout"
            color="paper"
            icon="mdi-pencil-box-multiple-outline"
            @clickedThis="copyNow()"
          />
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      width="1024"
      v-model="showExerciseInfo"
      :fullscreen="isPhone"
      overlay-color="charcoal"
      overlay-opacity=".97"
    >
      <ExerciseInfo
        :exerciseid="selectedExercise"
        @keydown.esc="cancelInfo"
        @cancel="cancelInfo"
        @swipe_off="cancelInfo"
      />
    </v-dialog>
    <v-dialog
      v-model="showVisibilityDialog"
      persistent
      width="700"
      :fullscreen="isPhone"
      overlay-color="charcoal"
      overlay-opacity=".95"
    >
      <v-card class="d-flex flex-column charcoal" flat tile>
        <v-sheet color="transparent" class="charcoal--text">
          <v-card-title
            :class="
              selectedWorkout.public == 'Yes' ? 'orange--text' : 'success--text'
            "
          >
            <span class="paper--text pr-2">Make Workout</span>
            {{ selectedWorkout.public == 'Yes' ? 'PRIVATE' : 'PUBLIC' }} ?
          </v-card-title>
        </v-sheet>
        <v-spacer />
        <v-card-actions class="px-0">
          <BaseActionButton
            icon="mdi-close"
            label="No"
            plain
            color="silver"
            @clickedThis="cancelVisibility"
          />
          <v-spacer />
          <BaseActionButton
            label="Yes"
            plain
            color="progressActive"
            icon="mdi-checkbox-outline"
            @clickedThis="saveVisibility()"
          />
        </v-card-actions>
      </v-card>
    </v-dialog>
    <BaseUpgradeWarning
      :show="showUpgradeWarning"
      @cancel="showUpgradeWarning = false"
      limitation="Maximum five workout templates."
      remediation="edit an existing workout, delete a workout and then create a new one,"
    />
    <StartWorkout
      :show="showVariationDialog"
      @cancel="showVariationDialog = false"
      :workout="selectedWorkout"
    />
    <ShareWorkout
      :show="showShareWorkoutDialog"
      @cancel="cancelShare"
      :selectedWorkout="selectedWorkout"
    />
  </v-container>
</template>
<script>
import axios from 'axios'
import { appConfig, builder } from '@/store/helpers.js'
import util from '@/mixins/util.js'
const ExerciseInfo = () =>
  import(/* webpackPrefetch: true */ '@/components/dialogs/ExerciseInfo.vue')
const LoadingDialog = () =>
  import(/* webpackPrefetch: true */ '@/components/dialogs/Loading.vue')
const StartWorkout = () =>
  import(/* webpackPrefetch: true */ '@/components/dialogs/StartWorkout.vue')
const ShareWorkout = () =>
  import(/* webpackPrefetch: true */ '@/components/dialogs/ShareWorkout.vue')

import workoutSortByList from '@/json/workoutsortby.json'

export default {
  components: { ExerciseInfo, LoadingDialog, StartWorkout, ShareWorkout },
  mixins: [util],
  name: 'ListWorkouts',
  data: () => ({
    sortBy: 'date',
    workoutSortByList: workoutSortByList,
    name: '',
    oldname: '',
    description: '',
    filterLabel: 'All',
    workouts: [],
    loadedList: [],
    repSchemes: [],
    selectedRepScheme: {},
    selectedWorkout: {},
    selectedExercise: '',
    showDetails: false,
    loaded: false,
    filtering: false,
    favoritesOnly: false,
    showUpgradeWarning: false,
    search: null,
    deletable: {},
    searchTerm: '',
    num_workouts: 0,
    favoritesNum: 0,
    sortDesc: false,
    debouncedScroll: null,
    debounce: null,
    pagesize: 100,
    currentPage: 0,
    bottom: false,
    scrollPosition: 0,
    reloading: false,
    showSearchingDialog: false,
    showExerciseInfo: false,
    showConvertDialog: false,
    showRepSchemeDialog: false,
    showVariationDialog: false,
    showCopyDialog: false,
    showVisibilityDialog: false,
    showShareWorkoutDialog: false,
    previousParams: null,
    basic: null,
    lastLifts: null
  }),
  beforeMount() {
    this.loaded = false
    this.currentPage = 0
    this.$store.dispatch('session/loadSubscription', {})
    this.getWorkoutCount()
    this.loadUserRepmaxes()
    this.loadPage()
  },
  mounted() {
    window.addEventListener('scroll', this.handleScroll)
    window.addEventListener('message', this.receiveMessage)
  },
  beforeDestroy() {
    window.removeEventListener('scroll', this.handleScroll)
    window.removeEventListener('message', this.receiveMessage)
  },

  watch: {
    bottom(bottom) {
      if (bottom && !this.favoritesOnly && !this.filtering) {
        this.currentPage++
        this.loadPage()
      }
    },
    showSearchingDialog: function(value) {
      this.$store.dispatch('session/toggleModal', value)
    },
    showExerciseInfo: function(value) {
      this.$store.dispatch('session/toggleModal', value)
    },
    showConvertDialog: function(value) {
      this.$store.dispatch('session/toggleModal', value)
    },
    showRepSchemeDialog: function(value) {
      this.$store.dispatch('session/toggleModal', value)
    },
    showVariationDialog: function(value) {
      this.$store.dispatch('session/toggleModal', value)
    },
    showCopyDialog: function(value) {
      this.$store.dispatch('session/toggleModal', value)
    },
    showVisibilityDialog: function(value) {
      this.$store.dispatch('session/toggleModal', value)
    },
    showShareWorkoutDialog: function(value) {
      this.$store.dispatch('session/toggleModal', value)
    }
  },
  computed: {
    ...appConfig,
    ...builder,
    itemsCount() {
      return this.workouts.length
    },
    favoritesLabel() {
      let start = 'Favorites Only'
      let end = this.favoritesNum > 0 ? '(' + this.favoritesNum + ')' : ''
      return start + end
    }
  },
  methods: {
    cancelRepscheme() {
      this.showRepSchemeDialog = false
    },
    cancelCopy() {
      this.showCopyDialog = false
    },
    receiveMessage(event) {
      if (event.data.type == 'CLOSE_MODAL' && event.data.key == 'UoMc92xqvA') {
        if (this.showSearchingDialog) this.showSearchingDialog = false
        else if (this.showExerciseInfo) this.showExerciseInfo = false
        else if (this.showConvertDialog) this.showConvertDialog = false
        else if (this.showRepSchemeDialog) this.showRepSchemeDialog = false
        else if (this.showVariationDialog) this.showVariationDialog = false
        else if (this.showCopyDialog) this.showCopyDialog = false
        else if (this.showVisibilityDialog) this.showVisibilityDialog = false
        else if (this.showShareWorkoutDialog)
          this.showShareWorkoutDialog = false
      }
    },
    cancelSearch() {
      this.showSearchingDialog = false
      this.search = ''
    },
    refresh() {
      this.reloding = true
      this.loadPage()
    },
    cancelShare() {
      this.showShareWorkoutDialog = false
    },
    debouncedSearch() {
      clearTimeout(this.debounce)
      this.debounce = setTimeout(() => {
        this.showSearchingDialog = false
        this.loadPage()
      }, 100)
    },
    addMore() {
      if (!this.is_pro && this.itemsCount > 4) {
        this.showUpgradeWarning = true
      } else
        this.$router.push({
          path: '/workouts/add'
        })
    },
    setResults(value) {
      this.workouts = value
    },
    handleScroll(/*event*/) {
      this.isUserScrolling = window.scrollY > 0
      this.scrollPosition =
        window.pageYOffset ||
        window.scrollY ||
        document.body.scrollTop ||
        document.documentElement.scrollTop
      this.bottom = this.bottomVisible()
    },
    manageVisibility(workout) {
      this.selectedWorkout = workout
      this.showVisibilityDialog = true
    },
    cancelVisibility() {
      this.selectedWorkout = {}
      this.showVisibilityDialog = false
    },
    showExercise(exerciseid) {
      this.selectedExercise = exerciseid
      this.showExerciseInfo = true
    },
    cancelInfo() {
      this.selectedExercise = ''
      this.showExerciseInfo = false
    },

    viewCompleted() {
      this.$router.push({
        path: '/workouts/viewcompleted'
      })
    },
    editWorkout(workout) {
      this.$router.push({
        path: '/workouts/edit/' + workout.workoutid
      })
    },
    shareWorkout(workout) {
      this.selectedWorkout = workout
      this.showShareWorkoutDialog = true
    },
    convertWorkout(workout) {
      this.selectedWorkout = workout
      this.showConvertDialog = true
    },
    copyWorkout(workout) {
      if (!this.is_pro && this.itemsCount > 4) {
        this.showUpgradeWarning = true
      } else {
        this.selectedWorkout = workout
        this.selectedWorkout.newname = 'Copy Of ' + this.selectedWorkout.name
        this.showCopyDialog = true
      }
    },
    copyDefaultWorkouts() {
      return axios
        .put(this.baseURL + '/workout/copy/default', {
          now: this.now()
        })
        .then(response => {
          if (response.status == 200) {
            this.loadPage()
            this.toast('Default workouts were succesfully copied was copied!')
          } else {
            this.$store.dispatch(
              'notification/addErrors',
              response.data.errors,
              5000,
              true,
              {
                root: true
              }
            )
            return
          }
        })
    },
    loadBasic() {
      return axios
        .get(this.baseURL + '/stats/basic/' + this.type, {})
        .then(response => {
          if (response.status == 200) {
            this.basic = response.data.data
            this.basic.feet = Math.floor(this.basic.height / 12)
            this.basic.inches = this.basic.height % 12
            this.basic.weightMetric = this.basic.weight * this.factor
          } else {
            this.$store.dispatch(
              'notification/addErrors',
              response.data.errors,
              5000,
              true,
              {
                root: true
              }
            )
            return
          }
        })
    },
    loadUserRepmaxes() {
      return axios
        .get(this.baseURL + '/stats/strength/current/no', {})
        .then(response => {
          if (response.status == 200) {
            this.repmaxes = response.data.data
          } else {
            this.$store.dispatch(
              'notification/addErrors',
              response.data.errors,
              5000,
              true,
              {
                root: true
              }
            )
            return
          }
        })
    },
    async loadLastLifts(workoutinstanceid) {
      return axios
        .post(this.baseURL + '/workouts/lastlifts', {
          exerciseid: null,
          workoutinstanceid: workoutinstanceid
        })
        .then(response => {
          if (response.status == 200) {
            this.lastLifts = response.data.data
          } else {
            this.$store.dispatch(
              'notification/addErrors',
              response.data.errors,
              5000,
              true,
              {
                root: true
              }
            )
            return
          }
        })
    },
    async pack(workout) {
      this.loadBasic()
      return axios
        .post(this.baseURL + '/workout/pack', {
          workout: workout,
          now: this.now()
        })
        .then(response => {
          if (response.status == 200) {
            let packedWorkout = workout
            delete packedWorkout.is_orphaned
            delete packedWorkout.is_unused
            delete packedWorkout.default
            delete packedWorkout.adhoc
            delete packedWorkout.approved
            delete packedWorkout.display_name
            delete packedWorkout.active

            packedWorkout.sets = response.data.data
            packedWorkout.workoutinstanceid =
              packedWorkout.sets[0].workoutinstanceid

            return axios
              .post(this.baseURL + '/workouts/lastlifts', {
                exerciseid: null,
                workoutinstanceid: packedWorkout.workoutinstanceid
              })
              .then(response => {
                if (response.status == 200) {
                  this.lastLifts = response.data.data
                  packedWorkout.exercises.forEach(ex => {
                    delete ex.description
                  })
                  packedWorkout.userid = this.userid
                  packedWorkout.lastLifts = this.lastLifts

                  let available = localStorage.getItem('workouts_packed')
                  if (available != null) {
                    available = JSON.parse(available)
                    available = available.filter(
                      w =>
                        !(
                          w.workoutid == packedWorkout.workoutid &&
                          w.userid == this.userid
                        )
                    )
                    available.push(packedWorkout)
                    localStorage.setItem(
                      'workouts_packed',
                      JSON.stringify(available)
                    )
                  } else {
                    let workouts_packed = []
                    workouts_packed.push(packedWorkout)
                    localStorage.setItem(
                      'workouts_packed',
                      JSON.stringify(workouts_packed)
                    )
                  }
                  localStorage.setItem(
                    'repschemes',
                    JSON.stringify(this.repSchemes)
                  )
                  localStorage.setItem(
                    'repmaxes',
                    JSON.stringify(this.repmaxes)
                  )
                  localStorage.setItem('basic', JSON.stringify(this.basic))
                  this.toast(
                    'Workout ' +
                      workout.name +
                      ' was packed and is ready for offline use!'
                  )
                  workout.packed = 'Yes'
                  this.loadPage()
                }
              })
          } else {
            this.$store.dispatch(
              'notification/addErrors',
              response.data.errors,
              5000,
              true,
              {
                root: true
              }
            )
            return
          }
        })
    },
    copyNow() {
      return axios
        .post(this.baseURL + '/workout/copy', {
          workoutid: this.selectedWorkout.workoutid,
          description: this.selectedWorkout.description,
          newname: this.selectedWorkout.newname,
          now: this.now()
        })
        .then(response => {
          if (response.status == 200) {
            this.showCopyDialog = false
            this.planned = true
            this.toast(
              'Workout ' + this.selectedWorkout.newname + ' was copied!'
            )
            this.currentPage = 0
            this.reloading = true
            this.loadPage()
          } else {
            this.$store.dispatch(
              'notification/addErrors',
              response.data.errors,
              5000,
              true,
              {
                root: true
              }
            )
            return
          }
        })
    },
    saveVisibility() {
      this.selectedWorkout.public =
        this.selectedWorkout.public == 'Yes' ? 'No' : 'Yes'
      return axios
        .put(this.baseURL + '/workout/visibility', {
          workout: this.selectedWorkout
        })
        .then(response => {
          if (response.status == 200) {
            this.toast('Workout visibility was succesfully set..')
            this.cancelVisibility()
          } else {
            this.$store.dispatch(
              'notification/addErrors',
              response.data.errors,
              5000,
              true,
              {
                root: true
              }
            )
            return
          }
        })
    },
    addFavorite(workout) {
      return axios
        .post(this.baseURL + '/workouts/favorites', {
          workoutid: workout.workoutid
        })
        .then(response => {
          if (response.status == 200) {
            workout.is_favorite = 'Yes'
            this.toast(
              'Workout: ' + workout.name + ' succesfully added to favorites.'
            )
          } else {
            this.$store.dispatch(
              'notification/addErrors',
              response.data.errors,
              5000,
              true,
              {
                root: true
              }
            )
            return
          }
        })
    },
    removeFavorite(workout) {
      return axios
        .delete(this.baseURL + '/workouts/favorites/' + workout.workoutid, {})
        .then(response => {
          if (response.status == 200) {
            workout.is_favorite = 'No'
            this.toast(
              'Workout: ' +
                workout.name +
                ' succesfully removed from favorites.'
            )
          } else {
            this.$store.dispatch(
              'notification/addErrors',
              response.data.errors,
              5000,
              true,
              {
                root: true
              }
            )
            return
          }
        })
    },
    toggleFavorites() {
      this.favoritesOnly = !this.favoritesOnly
      if (this.favoritesOnly) {
        this.search = 'favorites'
      } else {
        this.search = ''
        this.workouts = this.loadedList
        this.favoritesNum = 0
      }
      this.loadPage()
    },
    convertNow() {
      return axios
        .put(this.baseURL + '/workout/convert', {
          workout: this.selectedWorkout
        })
        .then(response => {
          if (response.status == 200) {
            this.showConvertDialog = false
            this.currentPage = 0
            this.reloading = true
            this.loadPage()
          } else {
            this.$store.dispatch(
              'notification/addErrors',
              response.data.errors,
              5000,
              true,
              {
                root: true
              }
            )
            return
          }
        })
    },
    loadRepschemes() {
      return axios
        .get(this.baseURL + '/tools/repschemes/all', {})
        .then(response => {
          if (response.status == 200) {
            this.repSchemes = response.data.data
          } else {
            this.$store.dispatch(
              'notification/addErrors',
              response.data.errors,
              5000,
              true,
              {
                root: true
              }
            )
            return
          }
        })
    },
    repSchemeName(repschemeid) {
      let scheme = this.repSchemes.filter(item => {
        return item.id == repschemeid
      })
      return scheme[0].name
    },
    selectRepScheme(repschemeid) {
      this.showRepSchemeDialog = true
      let scheme = this.repSchemes.filter(item => {
        return item.id == repschemeid
      })
      this.selectedRepScheme = scheme[0]
      this.selectedRepScheme.detailsObject = JSON.parse(
        this.selectedRepScheme.details
      )
    },
    start(workout) {
      const { is_incomplete, type, xmode, workoutid } = workout

      if (is_incomplete === 'Yes') {
        localStorage.setItem('startWorkout', true)
        let path = `/workouts/start/${workoutid}`

        if (type === 'Circuit') {
          path = `/workouts/start/hiit/${workoutid}`
        } else if (type === 'Hybrid') {
          const workoutType = xmode === 'time' ? 'rft' : xmode
          path = `/workouts/start/${workoutType}/${workoutid}`
        }

        this.$router.push({ path })
      } else {
        this.showVariationDialog = true
        this.selectedWorkout = workout
      }
    },
    changeSort() {
      this.cancelSearch()
      this.loadPage()
    },

    async loadPage() {
      let searchTerm = this.search || 'All'
      let urlParams = new URLSearchParams({
        search: searchTerm,
        sortby: this.sortBy,
        reloading: this.reloading
      })

      let keepScrolling =
        !this.previousParams ||
        this.previousParams.toString() === urlParams.toString()

      if (!keepScrolling) {
        this.currentPage = 0
      }

      let url = `/workouts/scroll/simple/${this.currentPage}/${this.pagesize}`
      try {
        let response = await axios.get(
          this.baseURL + url,
          { params: urlParams },
          { timeout: this.reqTimeout }
        )

        if (response.status === 200) {
          this.reloading = false
          let newResponse = response.data.data
          for (let el of newResponse) {
            el.exercises = JSON.parse(el.exercises)
          }

          this.workouts = keepScrolling
            ? this.workouts.concat(newResponse)
            : newResponse
          this.loadedList = this.workouts.slice()
          if (this.favoritesOnly) this.favoritesNum = this.workouts.length

          this.loadRepschemes()
          this.previousParams = urlParams
          localStorage.setItem('workouts_list', JSON.stringify(this.workouts))
          this.loaded = true
        } else {
          this.$store.dispatch(
            'notification/addErrors',
            response.data.errors,
            5000,
            true,
            { root: true }
          )
        }
      } catch (error) {
        let localWorkouts = localStorage.getItem('workouts_list')
        if (localWorkouts) {
          this.workouts = JSON.parse(localWorkouts)
          this.loadedList = this.workouts.slice()
          if (this.favoritesOnly) this.favoritesNum = this.workouts.length

          this.loadRepschemes()
          this.previousParams = urlParams
          this.loaded = true
        }
      }
    },
    getWorkoutCount() {
      const urlParams = new URLSearchParams()
      urlParams.append('search', 'count')

      let url =
        '/workouts/scroll/simple/' + this.currentPage + '/' + this.pagesize

      return axios
        .get(this.baseURL + url, { params: urlParams })
        .then(response => {
          if (response.status == 200) {
            this.num_workouts = response.data.data['num_workouts']
          } else {
            this.$store.dispatch(
              'notification/addErrors',
              response.data.errors,
              5000,
              true,
              {
                root: true
              }
            )
            return
          }
        })
    }
  }
}
</script>
<style scoped>
.addBtn {
  position: fixed;
  right: 5px;
  top: 75px;
  z-index: 10;
}

.leftTools {
  position: fixed;
  left: -5px;
  opacity: 0.9;
  top: 75px;
}

.addBtnDesktop {
  position: fixed;
  right: 5px;
  top: 85px;
  z-index: 10;
}

.leftToolsDesktop {
  position: fixed;
  left: -5px;
  opacity: 0.9;
  top: 85px;
}

.fabsearch {
  border-radius: 0px 20px 20px 0px;
}
</style>
