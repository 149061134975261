<template>
  <v-container>
    <BaseScreenHeader
      title="Workout Calendar"
      width="90%"
      screenInfo="WRK-010"
    />

    <v-card-actions class=" charcoal paper--text ">
      <v-spacer />
      <v-btn icon color="paper" class="mr-2" @click="$refs.calendar.prev()">
        <v-icon x-large>mdi-chevron-left</v-icon>
      </v-btn>
      <span class="title" v-if="$refs.calendar">
        {{ $refs.calendar.title }}
      </span>
      <v-btn icon class="ml-2 " @click="$refs.calendar.next()">
        <v-icon color="paper" x-large>mdi-chevron-right</v-icon>
      </v-btn>
      <v-spacer />
    </v-card-actions>
    <v-sheet rounded class="transparent">
      <v-calendar
        class=" paper pa-2 rounded"
        color="success"
        ref="calendar"
        v-model="value"
        :weekdays="weekday"
        type="month"
        :events="workouts"
        event-start="starttime"
        event-end="endtime"
        event-color="transparent"
        :event-height="60"
        event-ripple
        :event-overlap-mode="mode"
        :event-overlap-threshold="30"
        @change="loadWorkouts"
      >
        <template v-slot:event="{ event, eventParsed }">
          <v-card-actions
            :class="workoutTypeColor(event) + ' pb-0 px-1'"
            @click="showWorkoutDetails(event, eventParsed)"
          >
            <v-icon small v-if="event.repmax_count > 0" color="lightHeader">
              mdi-star
            </v-icon>
            <span :class="textSizeXSmall + ' lightHeader--text'">
              {{ event.name }}
            </span>
            <v-spacer />
            <v-icon v-if="!isPhone" color="lightHeader">
              {{ iconNumber(event.exercises.length - 1) }}
            </v-icon>
          </v-card-actions>
          <v-card-actions
            :class="workoutTypeColor(event) + ' pt-0 px-1'"
            @click="showWorkoutDetails(event, eventParsed)"
          >
            <template v-if="!isPhone">
              {{ Math.ceil(event.volume_imperial / 1000) }} k lbs /
              {{ Math.ceil(event.volume_metric / 1000) }} k kg
            </template>
            <v-spacer />
            <span class="lightHeader--text'">
              {{ eventParsed.start.time }}
              {{ eventParsed.start.hour > 12 ? 'PM' : 'AM' }}
            </span>
          </v-card-actions>
        </template>
      </v-calendar>
    </v-sheet>

    <v-dialog
      v-model="showWorkoutDialog"
      persistent
      :fullscreen="isPhone"
      width="700"
      overlay-opacity=".95"
      @keydown.esc="cancel"
    >
      <v-card
        v-if="selectedWorkout != null"
        class="d-flex flex-column noscroll"
        tile
      >
        <BaseCloseDialogHeader
          heading="Details"
          @cancel="showWorkoutDialog = false"
        />

        <v-card-text class="px-0">
          <v-card-subtitle class="title accent--text">
            Workout Name
            <div
              :class="textSize + '  primary--text'"
              v-html="selectedWorkout.name"
            />
          </v-card-subtitle>
          <v-card-subtitle class="title accent--text pb-0">
            Description
          </v-card-subtitle>
          <v-card-text :class="textSizeSmall + ' pt-0'">
            {{ selectedWorkout.description }}
          </v-card-text>
          <v-card-text
            v-if="selectedWorkout != null"
            :class="textSize + ' accent--text py-0'"
          >
            <v-icon large class="accent--text">
              {{ iconNumber(selectedWorkout.exercises.length - 1) }}
            </v-icon>
            exercises
          </v-card-text>
        </v-card-text>

        <v-spacer />
        <v-card-actions class="px-0">
          <BaseActionButton
            icon="mdi-close"
            label="Cancel"
            color="info"
            plain
            @clickedThis="showWorkoutDialog = false"
          />
          <v-spacer />
          <BaseActionButton
            label="Repeat"
            plain
            icon="mdi-pencil-box-multiple-outline"
            @clickedThis="startWorkout(selectedWorkout)"
          />
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import axios from 'axios'
import { appConfig } from '@/store/helpers.js'
import util from '@/mixins/util.js'
export default {
  mixins: [util],
  data: () => ({
    workouts: [],
    search: '',
    tagsize: 'large',
    tagIcon: 'mdi-information-variant',
    loaded: false,
    selectedWorkout: null,
    selectedEvent: {},
    showWorkoutDialog: false,
    type: 'month',
    mode: 'stack',
    weekday: [0, 1, 2, 3, 4, 5, 6],
    value: '',
    events: []
  }),

  beforeMount() {
    this.loadWorkouts()
  },
  computed: {
    ...appConfig
  },
  methods: {
    changeColor() {},
    showWorkoutDetails(workout, eventParsed) {
      this.selectedWorkout = workout
      this.selectedEvent = eventParsed
      this.showWorkoutDialog = true
    },
    trimName(workout) {
      let len = workout.name.length
      let val = workout.name
      if (workout.adhoc == 'Yes') val = workout.name.substring(0, len - 3)
      return val
    },
    formatedDate(date) {
      return date == null ? 'NA' : this.formatDate(date)
    },
    startWorkout(workout) {
      let path = '/workouts/start/' + workout.workoutid
      if (workout.type == 'Circuit')
        path = '/workouts/start/hiit/' + workout.workoutid
      else if (workout.type == 'Hybrid')
        path = '/workouts/start/wod/' + workout.workoutid
      this.$router.push({
        path: path
      })
    },
    viewWorkout(workout) {
      this.$router.push({
        path: '/workouts/view/' + workout.workoutinstanceid
      })
    },
    loadWorkouts() {
      {
        return axios
          .get(this.baseURL + '/workouts/history/all', {})
          .then(response => {
            if (response.status == 200) {
              this.workouts = response.data.data
              this.workouts.forEach(workout => {
                workout.exercises = JSON.parse(workout.exercises)
              })

              this.loaded = true
            } else {
              this.$store.dispatch(
                'notification/addErrors',
                response.data.errors,
                5000,
                true,
                {
                  root: true
                }
              )
              return
            }
          })
      }
    }
  }
}
</script>
