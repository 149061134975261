<template>
  <span>
    <v-dialog
      :fullscreen="isPhone"
      width="900"
      overlay-opacity=".95"
      v-model="showReorder"
      persistent
    >
      <ReorderList
        :items="exercisesList"
        title="Re-order Exercises"
        name="exercises"
        @clickedDone="doneReorder"
        @clickedCancel="showReorder = false"
      />
    </v-dialog>
    <BaseScreenHeader title="Edit Workout" screenInfo="WRK-003" />
    <v-card-actions class="pt-0 pr-0 pl-2">
      <BaseActionButton
        label="Delete"
        large
        :title="'Delete Workout: ' + workout.name"
        text
        color="orange"
        class="ml-n2"
        icon=""
        @clickedThis="setDeletable(workout)"
      />
      <v-spacer />
      <BaseLinkButton
        icon=""
        label="Manage Variations"
        color="silver"
        text
        class="pl-0"
        :to="'/workouts/manage/variations/' + workout.workoutid"
      />
    </v-card-actions>
    <v-sheet class="py-0 px-2 transparent">
      <v-sheet rounded class="pa-2 pb-0 transparent charcoalTile mb-4">
        <v-card-actions class="pa-0 my-n2">
          <v-checkbox
            dark
            ref="public"
            false-value="No"
            true-value="Yes"
            label="Public?"
            color="progressActive"
            v-model="workout.public"
          />
          <v-spacer />
          <BaseTag
            :label="typeLabel"
            color="transparent paper--text"
            tagsize="medium"
            class="mb-1"
          />
        </v-card-actions>
        <v-sheet class="py-1 transparent paper--text">
          <v-card-title class="pl-0 py-1">
            Name and Description
          </v-card-title>
        </v-sheet>
        <v-row dense>
          <v-col cols="12" md="6">
            <v-text-field
              dark
              filled
              v-model="workout.name"
              ref="name"
              :error-messages="nameErrors"
              :counter="50"
              label="Workout Name"
              prepend-inner-icon="mdi-dumbbell"
              @input="$v.workout.name.$touch()"
              @blur="$v.workout.name.$touch()"
            />
          </v-col>
          <v-col cols="12" md="6">
            <v-textarea
              dark
              filled
              v-model="workout.description"
              ref="description"
              :error-messages="descriptionErrors"
              :counter="400"
              :rows="3"
              label="Description"
              prepend-inner-icon="mdi-text"
              @input="$v.workout.description.$touch()"
              @blur="$v.workout.description.$touch()"
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <template v-if="workout.type == 'Circuit'">
              <v-row dense>
                <v-col cols="12">
                  <v-sheet rounded class="py-2 transparent paper--text">
                    <v-card-title class="pa-0">
                      Additional Details
                    </v-card-title>
                  </v-sheet>
                </v-col>
              </v-row>
              <v-card-text class="silver--text caption px-0 pb-0">
                Enter
                <span
                  class="progressActive--text"
                  title="The time taken to activelly perform the exercise"
                  >active time
                </span>
                and
                <span
                  class="progressActive--text"
                  title="Rest time between sets"
                  >rest time,
                </span>
                (in seconds),
                <span
                  class="progressActive--text"
                  title="Rest time between exercises"
                  >exercise rest time
                </span>
                (in seconds) if different than rest time and total time (in
                minutes).
                <v-spacer />
                <p>
                  If you enter total time, we will calculate the number of
                  rounds. If you enter the number of rounds, we will calculate
                  total time.
                </p>
              </v-card-text>
              <v-card-actions class="pa-0">
                <v-switch
                  dark
                  v-model="tabata"
                  label="Tabata"
                  color="progressActive"
                  @change="tabataSwitch"
                />
                <v-spacer />
                <v-switch
                  dark
                  color="progressActive"
                  v-model="byTime"
                  label="By Time"
                />
              </v-card-actions>
              <v-row class="px-2">
                <v-col cols="12" md="2">
                  <v-text-field
                    dark
                    filled
                    v-model="workout.active_time"
                    ref="active"
                    :error-messages="activeErrors"
                    :counter="5"
                    label="Active Time (seconds)"
                    prepend-inner-icon="mdi-clock-in"
                    @input="activeTimeChanged"
                    @blur="$v.workout.active_time.$touch()"
                  />
                </v-col>
                <v-col cols="12" md="3">
                  <v-text-field
                    dark
                    filled
                    v-model="workout.rest_time_set"
                    ref="rest"
                    :error-messages="restTimeSetErrors"
                    :counter="5"
                    label="Rest Time (between sets, in seconds)"
                    prepend-inner-icon="mdi-clock-out"
                    @input="restTimeSetChanged"
                    @blur="$v.workout.rest_time_set.$touch()"
                  />
                </v-col>
                <v-col cols="12" md="3">
                  <v-text-field
                    dark
                    filled
                    v-model="workout.rest_time_exercise"
                    ref="restExercise"
                    :error-messages="restExerciseErrors"
                    :counter="5"
                    label="Rest Time (between exercises, in seconds)"
                    prepend-inner-icon="mdi-clock-out"
                    @input="restTimeExerciseChanged"
                    @blur="$v.workout.rest_time_exercise.$touch()"
                  />
                </v-col>
                <v-spacer />
                <v-col cols="12" md="2">
                  <v-text-field
                    dark
                    filled
                    v-model="workout.rounds"
                    ref="rounds"
                    :counter="2"
                    :error-messages="roundsErrors"
                    label="Rounds"
                    type="number"
                    prepend-inner-icon="mdi-circle-double"
                    @input="roundsChanged"
                    @blur="$v.workout.rounds.$touch()"
                  />
                </v-col>
              </v-row>
            </template>
            <template v-if="workout.type == 'Hybrid'">
              <v-row dense v-if="workout.xmode == 'emom'">
                <v-spacer />
                <v-col cols="6" md="3">
                  <v-text-field
                    dark
                    filled
                    class="enlarged-input-small"
                    color="success"
                    v-model="workout.minutes"
                    ref="activeMinutes"
                    :error-messages="activeErrorsMinutes"
                    :counter="6"
                    label="Active (minutes)"
                    prepend-inner-icon="mdi-clock-in"
                    @input="activeTimeMinutesChanged"
                  />
                </v-col>
                <v-col cols="6" md="3">
                  <v-text-field
                    dark
                    filled
                    class="enlarged-input-small"
                    color="success"
                    v-model="workout.seconds"
                    ref="activeSeconds"
                    :error-messages="activeErrorsSeconds"
                    :counter="6"
                    label="(seconds)"
                    prepend-inner-icon="mdi-clock-in"
                    @input="activeTimeSecondsChanged"
                  />
                </v-col>
                <v-col cols="6" md="3">
                  <v-text-field
                    dark
                    filled
                    class="enlarged-input-small"
                    color="success"
                    v-model="workout.rest_time_exercise"
                    ref="rest"
                    :error-messages="restTimeExerciseErrors"
                    :counter="6"
                    label="Rest (seconds)"
                    prepend-inner-icon="mdi-clock-out"
                    @input="restTimeExerciseChanged"
                  />
                </v-col>
                <v-col cols="6" md="3">
                  <v-text-field
                    dark
                    filled
                    class="enlarged-input-small"
                    color="success"
                    v-model="workout.rounds"
                    ref="rounds"
                    :counter="2"
                    :error-messages="roundsErrors"
                    label="Rounds"
                    type="number"
                    prepend-inner-icon="mdi-circle-double"
                    @input="roundsChanged"
                  />
                </v-col>
              </v-row>
              <v-row dense justify="center" v-if="workout.xmode == 'time'">
                <v-col cols="6">
                  <v-text-field
                    dark
                    filled
                    class="enlarged-input-small"
                    color="success"
                    v-model="workout.max_time"
                    ref="maxTime"
                    :error-messages="maxErrors"
                    :counter="6"
                    type="number"
                    label="Maximum Time (minutes)"
                    prepend-inner-icon="mdi-clock-in"
                    @input="maxTimeChanged"
                  />
                </v-col>
                <v-col cols="6">
                  <v-text-field
                    dark
                    filled
                    class="enlarged-input-small"
                    color="success"
                    v-model="workout.rounds"
                    ref="rounds"
                    :counter="2"
                    :error-messages="roundsErrors"
                    label="Rounds"
                    type="number"
                    prepend-inner-icon="mdi-circle-double"
                    @input="roundsChanged"
                  />
                </v-col>
              </v-row>
              <v-row dense justify="center" v-if="workout.xmode == 'amrap'">
                <v-spacer />
                <v-col cols="5" md="2">
                  <v-text-field
                    dark
                    filled
                    class="enlarged-input-small"
                    color="success"
                    v-model="workout.total_time"
                    ref="totalTime"
                    :error-messages="totalTimeErrors"
                    :counter="6"
                    type="number"
                    label="Total Time (minutes)"
                    prepend-inner-icon="mdi-clock-in"
                    @input="totalTimeChanged"
                  />
                </v-col>
              </v-row>
            </template>
          </v-col>
        </v-row>
        <v-card-actions class="pt-0 px-0">
          <v-spacer />
          <BaseActionButton
            v-if="exercises.length > 0"
            text
            color="paper"
            :disabled="$v.$anyError || exercises == 0"
            @clickedThis="saveWorkout(true)"
          />
        </v-card-actions>
      </v-sheet>
      <v-sheet
        v-if="exercises.length > 0"
        class="pa-2 transparent charcoalTile mb-4"
      >
        <v-card-subtitle :class="textSizeXSmall + ' silver--text px-0 py-0'">
          Click on each selected exercises below to adjust the number of sets,
          reps, and load. You can remove an exercise by clicking on the little
          <span class="silver--text">x </span> in a white circle.
        </v-card-subtitle>
        <v-card-actions class="px-0">
          <span :class="textSizeSmall + ' py-0 pr-2 silver--text'">
            Exercises
          </span>
          <v-badge
            :content="exercises.length"
            :value="exercises.length"
            color="transparent paper--text"
            overlap
          >
            <v-icon large color="silver">
              mdi-checkbox-multiple-marked-circle
            </v-icon>
          </v-badge>
          <v-spacer />
          <v-btn
            text
            v-if="exercises.length > 0"
            class="mr-n2 progressActive--text"
            @click="showReorder = true"
          >
            Re-order
          </v-btn>
        </v-card-actions>
        <v-card-text v-if="loaded" class="pa-0">
          <ExerciseChip
            :repSchemes="repSchemes"
            :type="workout.type"
            @remove="removeExercise"
            @done="saveExerciseUpdates"
          />
        </v-card-text>
      </v-sheet>

      <ExercisesList :type="workout.type" />
    </v-sheet>

    <v-dialog
      v-model="deleteWorkoutFlag"
      persistent
      width="700"
      overlay-color="charcoal"
      overlay-opacity=".95"
      :fullscreen="isPhone"
    >
      <v-card tile flat class="d-flex flex-column charcoal noscroll-x">
        <BaseCloseDialogHeader
          heading="Delete Workout?"
          @cancel="cancelDelete"
        />
        <v-card-text :class="textSize + ' silver--text px-2 pb-0'">
          {{ deletable.name }}
        </v-card-text>
        <v-card-subtitle
          style="min-height: 200px"
          class="silver--text px-2 body-1 pb-4"
        >
          Are you sure you want to delete this workout?
        </v-card-subtitle>
        <v-spacer />
        <v-card-actions class="px-0">
          <BaseActionButton
            icon="mdi-close"
            color="silver"
            label="Cancel"
            :large="!isPhone"
            plain
            @clickedThis="cancelDelete"
          />
          <v-spacer />
          <BaseActionButton
            icon="mdi-delete-circle-outline"
            label="Yes, Delete"
            :large="!isPhone"
            plain
            color="paper"
            @clickedThis="deleteWorkout"
          />
        </v-card-actions>
      </v-card>
    </v-dialog>
  </span>
</template>
<script>
import { validationMixin } from 'vuelidate'
import { required, between } from 'vuelidate/lib/validators'
import { appConfig, builder } from '@/store/helpers.js'
import axios from 'axios'
import { mapActions } from 'vuex'
import util from '@/mixins/util.js'
const ExerciseChip = () =>
  import(/* webpackPrefetch: true */ '@/components/pageutil/ExerciseChip.vue')
const ReorderList = () =>
  import(/* webpackPrefetch: true */ '@/components/pageutil/ReorderList.vue')

const ExercisesList = () =>
  import(/* webpackPrefetch: true */ '@/components/pageutil/ExercisesList.vue')
export default {
  components: {
    ExerciseChip,
    ReorderList,
    ExercisesList
  },
  name: 'EditWorkout',
  mixins: [util, validationMixin],
  data: () => ({
    deleteWorkoutFlag: false,
    deletable: {},
    showReorder: false,
    tagsize: 'small',
    itemsPerPageArray: [20, 50, 100],
    byTime: true,
    search: '',
    active: 0,
    rest: 0,
    restExercise: 0,
    total: 0,
    rounds: 1,
    filter: {},
    sortDesc: false,
    page: 1,
    loadNumber: 1,
    itemsPerPage: 20,
    sortBy: 'name',
    workout: {},
    tabata: false,
    loaded: false,
    exerciseid: null,
    tabataInitial: {
      active_time: 20,
      active_time_total: 160,
      rest_time_exercise: 10,
      rest_time_set: 10,
      total_time: 4,
      rounds: 8
    },
    switchNum: 0,
    selectedForEdit: {},
    allExercises: [],
    exercises: [],
    initialExercises: [],
    newExercises: [],
    deletedExercises: [],
    repSchemes: [],
    mechanicsType: '',
    utilityType: '',
    exercisesType: '',
    selectedScheme: {},
    repschemeid: 'hzWVL',
    name: '',
    oldname: '',
    description: '',
    glossary: {},
    showTooltip: false,
    showDetails: false,
    infobox: '',
    info: {
      PR: 'Recommended weight as % of your 1RM',
      SETS: 'Recommended number of sets',
      REPS: 'Recommended number of reps per set'
    }
  }),
  props: {
    workoutid: {
      type: String
    }
  },
  beforeMount() {
    this.loadRepschemes()
    this.loadGlosary()
    this.loadWorkout(this.workoutid)
  },
  mounted() {
    this.scrollToTop()
  },
  beforeDestroy() {
    this.exercisesClear()
  },

  validations: {
    workout: {
      name: {
        required
      },
      description: {
        required
      },
      rounds: {
        required,
        between: between(1, 99)
      },
      active_time: {
        between: between(1, 600)
      },
      active_time_total: {
        between: between(1, 6000)
      },
      active_time_seconds: {
        between: between(0, 60)
      },
      active_time_minutes: {
        between: between(0, 99)
      },
      rest_time_exercise: {
        between: between(0, 600)
      },
      rest_time_set: {
        between: between(0, 600)
      },
      total_time: {
        between: between(0, 60)
      },
      max_time: {
        between: between(0, 120)
      }
    }
  },
  computed: {
    ...appConfig,
    ...builder,
    typeLabel() {
      let xmode =
        this.workout.xmode != null
          ? ', ' + this.capitalize(this.workout.xmode)
          : ''
      return this.workout.type + xmode
    },
    xmodeText() {
      if (this.workout.xmode == 'emom') return 'Every Minute On The Minute'
      return this.workout.xmode == 'time'
        ? 'For Time'
        : ' As Many Reps As Possible'
    },
    nameErrors() {
      const errors = []
      if (!this.$v.workout.name.$dirty) return errors
      !this.$v.workout.name.required && errors.push('Workout name is required.')
      return errors
    },
    descriptionErrors() {
      const errors = []
      if (!this.$v.workout.description.$dirty) return errors
      !this.$v.workout.description.required &&
        errors.push('Description is required.')
      return errors
    },
    activeErrors() {
      const errors = []
      if (!this.$v.workout.active_time.$dirty) return errors
      !this.$v.workout.active_time.between &&
        errors.push(
          'The number of active seconds is invalid. Expected (1 to 600)'
        )
      return errors
    },
    activeErrorsMinutes() {
      const errors = []
      if (!this.$v.workout.active_time_minutes.$dirty) return errors
      !this.$v.workout.active_time_minutes.between &&
        errors.push(
          'The number of active minutes is invalid. Expected (1 to 99)'
        )
      return errors
    },
    activeErrorsSeconds() {
      const errors = []
      if (!this.$v.workout.active_time_seconds.$dirty) return errors
      !this.$v.workout.active_time_seconds.between &&
        errors.push(
          'The number of active seconds is invalid. Expected (1 to 600)'
        )
      return errors
    },
    restTimeExerciseErrors() {
      const errors = []
      if (!this.$v.workout.rest_time_exercise.$dirty) return errors
      !this.$v.workout.rest_time_exercise.between &&
        errors.push(
          'The number of resting seconds between sets is invalid. Expected (1 to 600)'
        )
      return errors
    },
    roundsErrors() {
      const errors = []
      if (!this.$v.workout.rounds.$dirty) return errors
      !this.$v.workout.rounds.required &&
        errors.push('The number of rounds  is required.')
      !this.$v.workout.rounds.between &&
        errors.push('The number of rounds is invalid.')
      return errors
    },
    maxErrors() {
      const errors = []
      if (!this.$v.workout.max_time.$dirty) return errors
      !this.$v.workout.max_time.between && errors.push('Max time is invalid.')
      return errors
    },
    restTimeSetErrors() {
      const errors = []
      if (!this.$v.workout.rest_time_set.$dirty) return errors
      !this.$v.workout.rest_time_set.between &&
        errors.push(
          'The number of resting seconds between sets is invalid. Expected (1 to 600)'
        )
      return errors
    },
    restExerciseErrors() {
      const errors = []
      if (!this.$v.workout.rest_time_exercise.$dirty) return errors
      !this.$v.workout.rest_time_exercise.between &&
        errors.push(
          'The number of resting seconds between exercises is invalid. Expected (1 to 600)'
        )
      return errors
    },
    totalTimeErrors() {
      const errors = []
      if (!this.$v.workout.total_time.$dirty) return errors
      !this.$v.workout.total_time.between &&
        errors.push('The number of total minutes is invalid.')
      return errors
    }
  },
  methods: {
    cancelDelete() {
      this.deleteWorkoutFlag = false
      this.deletable = {}
    },
    setDeletable(workout) {
      this.deletable = workout
      this.deleteWorkoutFlag = true
    },
    activeTimeMinutesChanged() {
      this.$v.workout.active_time_minutes.$touch()
      this.calculateWodTimes()
    },
    activeTimeSecondsChanged() {
      this.$v.workout.active_time_seconds.$touch()
      this.calculateWodTimes()
    },
    activeTimeChanged() {
      this.$v.workout.active_time.$touch()
      this.calculateWodTimes()
    },
    roundsChanged() {
      if (this.workout.xmode == 'emom') this.calculateWodTimes()
      this.$v.workout.rounds.$touch()
    },
    totalTimeChanged() {
      this.$v.workout.total_time.$touch()
    },
    maxTimeChanged() {
      this.$v.workout.max_time.$touch()
    },
    restTimeSetChanged() {
      this.$v.workout.rest_time_set.$touch()
      if (this.workout.xmode == 'emom') this.calculateWodTimes()
    },
    restTimeExerciseChanged() {
      this.$v.workout.rest_time_exercise.$touch()
      if (this.workout.xmode == 'emom') this.calculateWodTimes()
    },
    doneReorder() {
      this.showReorder = false
      this.saveWorkout()
    },
    tabataSwitch() {
      if (this.tabata) {
        this.workout.active_time = this.tabataInitial.active_time
        this.workout.active_time_total = this.tabataInitial.active_time_total
        this.workout.total_time = this.tabataInitial.total_time
        this.workout.rest_time_exercise = this.tabataInitial.rest_time_exercise
        this.workout.rest_time_set = this.tabataInitial.rest_time_set
        this.workout.rounds = this.tabataInitial.rounds
      }
    },
    calculateTimes() {
      let roundSeconds =
        Number.parseInt(this.workout.active_time) +
        Number.parseInt(this.workout.rest_time_set)
      let totalSeconds = 0

      totalSeconds = this.workout.rounds * roundSeconds
      // this.workout.total_time = Math.ceil(totalSeconds / 60)
      this.workout.rounds = Math.ceil(totalSeconds / roundSeconds)
    },
    calculateWodTimes() {
      this.workout.active_time =
        parseInt(this.workout.minutes * 60) + parseInt(this.workout.seconds)
      this.workout.active_time_total =
        parseInt(this.workout.active_time) * parseInt(this.workout.rounds)

      this.workout.total_time =
        parseInt(this.workout.active_time_total) +
        parseInt(this.workout.rest_time_exercise) *
          parseInt(this.workout.rounds)
    },
    saveExerciseOrder() {
      this.showReorder = false
    },
    saveExerciseUpdates() {
      this.saveWorkout()
    },
    toggleDetails() {
      this.showDetails = !this.showDetails
    },
    removeExercise(exercise) {
      this.exerciseRemove(exercise)
      this.exercises = this.exercisesList.slice(0)
    },
    saveWorkout(redirect = false) {
      this.exercises = this.exercisesList.slice(0)
      return axios
        .put(this.baseURL + '/workout', {
          workoutid: this.workoutid,
          name: this.workout.name,
          description: this.workout.description,
          rounds: this.workout.rounds,
          active_time: this.workout.active_time,
          total_time: this.workout.total_time,
          max_time: this.workout.max_time,
          rest_time_set: this.workout.rest_time_set,
          rest_time_exercise: this.workout.rest_time_exercise,
          public: this.workout.public,
          exercises: this.exercises
        })
        .then(response => {
          if (response.status == 200) {
            if (redirect) {
              this.$router.push('/myworkouts')
              this.exercisesClear()
            } else {
              this.loadWorkout(this.workoutid)
              this.toast('Workout saved successfully.')
            }
          } else {
            this.$store.dispatch(
              'notification/addErrors',
              response.data.errors,
              5000,
              true,
              {
                root: true
              }
            )
            return
          }
        })
    },
    loadRepschemes() {
      return axios
        .get(this.baseURL + '/tools/repschemes/all', {})
        .then(response => {
          if (response.status == 200) {
            this.repSchemes = response.data.data
            let scheme = this.repSchemes.filter(item => {
              return item.id == this.repschemeid
            })
            this.selectedScheme = scheme[0]
            this.loaded = true
          } else {
            this.$store.dispatch(
              'notification/addErrors',
              response.data.errors,
              5000,
              true,
              {
                root: true
              }
            )
            return
          }
        })
    },
    loadGlosary() {
      return axios
        .get(this.baseURL + '/util/glossary/all', {})
        .then(response => {
          if (response.status == 200) {
            this.glossary = response.data.data

            this.forces = this.glossary.filter(function(item) {
              return item.category == 'Force'
            })
            this.mechanics = this.glossary.filter(function(item) {
              return item.category == 'Mechanics'
            })
            this.utility = this.glossary.filter(function(item) {
              return item.category == 'Utility'
            })
            this.dificultyList = this.glossary.filter(function(item) {
              return item.category == 'Dificulty'
            })
          } else {
            this.$store.dispatch(
              'notification/addErrors',
              response.data.errors,
              5000,
              true,
              {
                root: true
              }
            )
            return
          }
        })
    },
    loadWorkout(workoutid) {
      return axios
        .get(this.baseURL + '/workout/' + workoutid, {})
        .then(response => {
          if (response.status == 200) {
            this.workout = response.data.data
            this.workout.minutes = Math.floor(this.workout.active_time / 60)
            this.workout.seconds =
              this.workout.active_time - this.workout.minutes * 60

            this.exercises = JSON.parse(this.workout.exercises)
            this.initialExercises = this.exercises.slice()

            this.exercisesClear()
            let t = this
            this.exercises.forEach(function(element) {
              element.workoutType = t.workout.type
              t.exerciseAdd(element)
            })
          } else {
            this.$store.dispatch(
              'notification/addErrors',
              response.data.errors,
              5000,
              true,
              {
                root: true
              }
            )
            return
          }
        })
    },
    deleteWorkout() {
      let workoutid = this.deletable.workoutid
      return axios
        .delete(this.baseURL + '/workout/' + workoutid)
        .then(response => {
          if (response.status == 200) {
            this.deleteWorkoutFlag = false
            this.deletable = ''
            this.$router.push('/myworkouts')
          } else {
            this.$store.dispatch(
              'notification/addErrors',
              response.data.errors,
              5000,
              true,
              {
                root: true
              }
            )
            return
          }
        })
    },
    ...mapActions('builder', [
      'exerciseAdd',
      'exerciseRemove',
      'exerciseRemoveLast',
      'exercisesClear'
    ])
  }
}
</script>
