<template>
  <v-container>
    <BaseScreenHeader title="Workout Log" width="90%" screenInfo="WRK-004" />

    <BaseFreeTier v-if="loaded && loadedList.length > 4" class="pl-4" />
    <v-card-actions class="pa-0 mt-3">
      <v-spacer />
      <BaseActionButton
        label="Calendar"
        title="Shows Workouts Calendar"
        icon="mdi-calendar-star"
        color="silver"
        large
        text
        @clickedThis="$router.push('/workouts/calendar')"
      />
    </v-card-actions>
    <v-sheet class="charcoal">
      <BaseSearch
        dark
        :items="loadedList"
        :label="loadedList.length + ' Workouts'"
        @search_results="setResults"
      />
      <v-sheet class="transparent mt-4">
        <v-timeline v-if="loadedList.length > 0" align-top :dense="isPhone">
          <v-timeline-item
            color="progressActive"
            class="charcoal"
            fill-dot
            v-for="(workout, index) in workouts"
            :key="index"
            :class="isPhone ? 'ml-n8' : ''"
          >
            <template v-slot:icon>
              <v-icon color="charcoal">
                mdi-weight-lifter
              </v-icon>
            </template>
            <template v-slot:opposite>
              <span class="title silver--text">
                {{ prettyDateTime(workout.endtime) }}
              </span>
            </template>
            <v-card :elevation="1" class="charcoal" :style="charcoalTile">
              <v-card-actions :class="textSizeSmall">
                <v-divider class="charcoal lighten-1" />
                <v-sheet
                  :color="workoutTypeColor(workout)"
                  class="rounded pa-2 mx-2"
                  :style="charcoalTileMenu"
                >
                  {{ trimName(workout) }}
                </v-sheet>
                <v-divider class="charcoal lighten-1" />
              </v-card-actions>
              <v-card-actions :class="textSizeXSmall + ' silver--text pa-0'">
                <v-spacer />
                <span :class="textSize + ' success--text mx-1'">
                  {{ workout.duration }}
                </span>
                minutes
                <v-spacer />
              </v-card-actions>
              <v-card-actions class="body-2 silver--text pr-2">
                <template v-if="isPhone">
                  {{ prettyDate(workout.endtime) }}
                </template>
                <v-spacer />
                {{ workout.type }}
              </v-card-actions>

              <v-card-title class="body-1 success--text px-2 py-0">
                <v-divider class="success mr-1" />
                Rounds
                <v-icon color="success" class="ml-1">
                  {{ iconNumber(workout.roundnum - 1) }}
                </v-icon>
                <v-spacer />
                <span class="success--text"> Exercises</span>
                <v-icon
                  title="Exercises in this workout"
                  class="pl-1"
                  color="success"
                >
                  {{
                    iconNumber(
                      workout.exercises ? workout.exercises.length - 1 : 0
                    )
                  }}
                </v-icon>
                <v-divider class="success ml-1" />
              </v-card-title>
              <v-row
                class="silver--text my-2"
                justify="center"
                align="center"
                v-if="workout.effort > 0"
              >
                <span :class="textSize">
                  Effort
                  <span :class="effortColor(workout.effort)">
                    {{ workout.effort }}%
                  </span></span
                >
              </v-row>
              <template v-if="workout.volume_imperial > 0">
                <v-card-actions :class="textSize + ' silver--text pa-0'">
                  <v-divider class="charcoal lighten-1" />
                  <span class="mx-2">Volume</span>
                  <v-divider class="charcoal lighten-1" />
                </v-card-actions>
                <v-card-actions :class="textSizeXSmall + ' pa-0'">
                  <v-spacer />
                  <BaseTag
                    v-if="workout.volume_imperial > 0"
                    :label="
                      parseFloat(workout.volume_imperial).toLocaleString() +
                        ' lbs / ' +
                        parseFloat(workout.volume_metric).toLocaleString() +
                        ' Kg'
                    "
                    :tagsize="tagsize"
                    :icon="tagIcon"
                    class="transparent progressActive--text my-n2"
                    color="transparent"
                  />
                  <v-spacer />
                </v-card-actions>
              </template>
              <template v-if="workout.type == 'Circuit'">
                <v-card-actions :class="textSizeSmall + ' silver--text pb-0'">
                  <v-divider class="transparent" />
                  Duration
                  <v-divider class="transparent" />
                </v-card-actions>
                <v-card-actions :class="textSizeXSmall">
                  <v-divider />
                  <BaseTag
                    :label="workout.duration + '  minutes '"
                    text-color="silver"
                    :tagsize="tagsize"
                    :icon="tagIcon"
                    class="my-0"
                  />
                  <v-divider />
                </v-card-actions>
              </template>
              <v-card-subtitle
                :class="textSizeXSmall + ' silver--text px-2 mt-0 pb-0'"
              >
                {{ workout.description }}
              </v-card-subtitle>
              <v-card-text class="px-0">
                <v-list dense class="mx-0 transparent" flat>
                  <v-list-item
                    class="mx-0 px-0"
                    v-for="(exercise, idx) in workout.exercises"
                    :key="idx"
                    @click="showExercise(exercise.exerciseid)"
                  >
                    <v-list-item-icon class=" ml-2 mr-1">
                      <v-icon color="progressActive">
                        {{ iconNumber(exercise.sort - 1) }}
                      </v-icon>
                    </v-list-item-icon>
                    <span :class="textSizeXSmall + ' silver--text'">{{
                      exercise.name
                    }}</span>
                  </v-list-item>
                </v-list>
              </v-card-text>

              <v-card-actions class="px-0">
                <BaseActionButton
                  label=""
                  title="Delete Workout Instance"
                  color="silver"
                  :large="false"
                  icon="mdi-trash-can"
                  text
                  class="ml-n2"
                  @clickedThis="selectWorkout(workout)"
                />
                <v-spacer />
                <BaseActionButton
                  label="View"
                  title="View Workout Details"
                  color="silver"
                  :large="false"
                  icon=""
                  text
                  @clickedThis="viewWorkout(workout)"
                />
                <BaseActionButton
                  class="success--text"
                  label="Repeat"
                  title="Repeat This Workout"
                  :large="false"
                  text
                  icon=""
                  @clickedThis="startWorkout(workout)"
                />
              </v-card-actions>
            </v-card>
          </v-timeline-item>
        </v-timeline>
      </v-sheet>
    </v-sheet>
    <BaseLoading :loaded="loaded" />
    <v-dialog
      v-model="showDeleteDialog"
      :fullscreen="isPhone"
      :max-width="dialogWidth"
      persistent
      overlay-opacity=".95"
      @keydown.esc="cancel"
    >
      <v-card flat tile class="d-flex flex-column charcoal noscroll-x">
        <BaseCloseDialogHeader
          heading="Delete Workout Instance?"
          @cancel="showDeleteDialog = false"
        />
        <v-card-text class="px-2">
          <v-card-text :class="textSizeXSmall + ' silver--text pt-5 px-0'">
            Are you sure you want to delete this workout instance of
            <v-spacer />
            <span class="mx-1 paper--text"
              >"
              {{ selectedWorkout.name }}
              " </span
            >?
          </v-card-text>
        </v-card-text>
        <v-spacer />
        <v-card-actions class="px-0">
          <v-spacer />
          <BaseActionButton
            label="Delete"
            text
            icon="mdi-trash-can-outline"
            @clickedThis="deleteWorkout"
            color="paper"
            dark
          />
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      :width="dialogWidth"
      v-model="showExerciseInfo"
      :fullscreen="isPhone"
      overlay-opacity=".97"
    >
      <ExerciseInfo
        :exerciseid="selectedExercise"
        @keydown.esc="cancelInfo"
        @cancel="cancelInfo"
      />
    </v-dialog>
  </v-container>
</template>

<script>
import axios from 'axios'
import { appConfig } from '@/store/helpers.js'
import util from '@/mixins/util.js'
const ExerciseInfo = () =>
  import(/* webpackPrefetch: true */ '@/components/dialogs/ExerciseInfo.vue')

import debounce from 'lodash/debounce'
export default {
  components: { ExerciseInfo },
  mixins: [util],
  data: () => ({
    workouts: [],
    loadedList: [],
    selectedWorkout: {},
    selectedExercise: {},
    search: '',
    tagsize: 'large',
    tagIcon: 'mdi-information-variant',
    loaded: false,
    refresh: false,
    showDetails: false,
    showDeleteDialog: false,
    showExerciseInfo: false,
    sortDesc: false,
    debouncedScroll: null,
    debounce: null,
    pagesize: 50,
    currentPage: 0,
    totalCount: 100, //fetch from API
    bottom: false
  }),
  beforeMount() {
    this.loadPage()
  },
  mounted() {
    this.handleDebouncedScroll = debounce(this.handleScroll, 100)
    window.addEventListener('scroll', this.handleDebouncedScroll)
    window.addEventListener('message', this.receiveMessage)
  },
  beforeDestroy() {
    window.removeEventListener('scroll', this.handleDebouncedScroll)
    window.removeEventListener('message', this.receiveMessage)
  },

  watch: {
    showExerciseInfo: function(value) {
      this.$store.dispatch('session/toggleModal', value)
    },
    bottom(bottom) {
      if (bottom) {
        this.currentPage++
        this.loadPage()
      }
    }
  },
  computed: {
    itemsCount() {
      return this.workouts.length
    },
    numberOfPages() {
      return Math.ceil(this.workouts.length / this.itemsPerPage)
    },
    ...appConfig
  },
  methods: {
    receiveMessage(event) {
      if (event.data.type == 'CLOSE_MODAL' && event.data.key == 'UoMc92xqvA') {
        if (this.showExerciseInfo) this.cancelInfo()
      }
    },
    effortColor(effort) {
      if (effort <= 65) return 'orange--text'
      else if (effort > 65 && effort < 80) return 'silver--text'
      else return 'progressActive--text'
    },
    cancelInfo() {
      this.selectedExercise = ''
      this.showExerciseInfo = false
    },
    showExercise(id) {
      this.selectedExercise = id
      this.showExerciseInfo = true
    },
    setResults(value) {
      this.workouts = value
    },
    handleScroll(/*event*/) {
      this.isUserScrolling = window.scrollY > 0
      this.bottom = this.bottomVisible()
    },
    selectWorkout(workout) {
      this.selectedWorkout = workout
      this.showDeleteDialog = true
    },
    trimName(workout) {
      let len = workout.name.length
      let val = workout.name
      if (workout.adhoc == 'Yes') val = workout.name.substring(0, len - 3)
      return val
    },
    formatedDate(date) {
      return date == null ? 'NA' : this.formatDate(date)
    },
    startWorkout(workout) {
      let path = '/workouts/start/' + workout.workoutid
      if (workout.type == 'Circuit')
        path = '/workouts/start/hiit/' + workout.workoutid
      else if (workout.type == 'Hybrid')
        path = '/workouts/start/wod/' + workout.workoutid
      this.$router.push({
        path: path
      })
    },
    viewWorkout(workout) {
      this.$router.push({
        path: '/workouts/view/' + workout.workoutinstanceid
      })
    },
    deleteWorkout() {
      return axios
        .post(this.baseURL + '/workout/delete', {
          workoutinstanceid: this.selectedWorkout.workoutinstanceid
        })
        .then(response => {
          if (response.status == 200) {
            this.currentPage = 0
            this.refresh = true
            this.loadPage()
            this.showDeleteDialog = false
          } else {
            this.$store.dispatch(
              'notification/addErrors',
              response.data.errors,
              5000,
              true,
              {
                root: true
              }
            )
            return
          }
        })
    },
    loadPage() {
      {
        this.loaded = false
        return axios
          .get(
            this.baseURL +
              '/workouts/history/scroll/' +
              this.currentPage +
              '/' +
              this.pagesize,
            {}
          )
          .then(response => {
            if (response.status == 200) {
              let newResponse = response.data.data
              newResponse.forEach(workout => {
                workout.exercises = JSON.parse(workout.exercises)
              })
              if (this.refresh) this.workouts = newResponse
              else this.workouts = this.workouts.concat(newResponse)

              this.loadedList = this.workouts.slice()
              this.loaded = true
            } else {
              this.$store.dispatch(
                'notification/addErrors',
                response.data.errors,
                5000,
                true,
                {
                  root: true
                }
              )
              return
            }
          })
      }
    }
  }
}
</script>
