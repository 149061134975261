<template>
  <v-container>
    <BaseScreenHeader title="Workout Details" screenInfo="WRK-005" />
    <v-sheet color="transparent" class="silver--text">
      <v-card-title class="subheading">
        {{ workout.name }} <v-spacer />
      </v-card-title>
    </v-sheet>
    <v-card-actions class=" transparent pb-0 pt-2">
      <v-spacer />
      <span :class="textSizeSmall + ' silver--text text--upercase'">
        Workout Type:
        <span class="progressActive--text">{{ workout.type }}</span>
      </span>
    </v-card-actions>
    <v-card-actions class="transparent silver--text">
      <div :class="textSizeSmall + ' py-0 pb-1 '">
        {{ workout.description }}
      </div>
    </v-card-actions>
    <v-card-actions v-if="workout.volume_imperial > 0">
      <v-spacer />
      <v-simple-table class="transparent charcoalTile silver--text" dense flat>
        <thead>
          <tr>
            <th class="text-left silver--text">Total Volume(lbs)</th>
            <th class="text-left silver--text">Total Volume (kg)</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>
              {{ parseFloat(workout.volume_imperial).toLocaleString() }}
              lbs
            </td>
            <td>{{ parseFloat(workout.volume_metric).toLocaleString() }} kg</td>
          </tr>
        </tbody>
      </v-simple-table>
    </v-card-actions>
    <v-divider />
    <v-card-text class="title progressActive--text pb-0">
      Muscles Used
    </v-card-text>
    <v-card-text class="pt-0">
      <span v-for="p in musclesUsed" :key="p.muscleid">
        <v-icon class="progressActive--text">
          {{ iconNumber(p.occurences - 1) }}
        </v-icon>
        <router-link
          class="silverLink pl-1 pr-2"
          :to="'/muscledetails/' + p.code"
        >
          {{ p.muscle_name }}
        </router-link>
      </span>
    </v-card-text>
    <v-divider />
    <v-card-subtitle :class="textSize + ' progressActive--text pt-4 pb-2'">
      Exercises
    </v-card-subtitle>
    <div
      v-for="workoutInstance in workoutInstances"
      :key="workoutInstance.id"
      class="px-2"
    >
      <template v-if="workout.type == 'Lifting'">
        <v-card-actions
          v-if="workoutInstance.setnum == 1"
          class="pt-0 pb-0  paper--text title"
        >
          {{ workoutInstance.exercise_name }}
        </v-card-actions>
        <v-sheet rounded class="transparent charcoalTile pa-2 mx-2 mb-2">
          <v-card-actions class="silver--text">
            <span :class="textSizeSmall + ' pb-0 progressActive--text'">
              {{ 'Set # ' + workoutInstance.setnum }}
            </span>
            <v-spacer />
            <span :class="textSizeXSmall">
              {{ workoutInstance.reps }} Reps @
              {{ workoutInstance.imperial }} lbs /
              {{ workoutInstance.metric }} kg
            </span>
          </v-card-actions>
          <v-card-actions>
            <span :class="textSizeXSmall + ' py-0 silver--text'">
              Volume:
            </span>
            <v-spacer />
            <span class="silver--text">
              {{ workoutInstance.volume_imperial }} lbs /
              {{ workoutInstance.volume_metric }} kg
            </span>
          </v-card-actions>
        </v-sheet>
      </template>
      <template v-else>
        <v-divider :class="workoutInstance.setnum == 1 ? 'accent' : 'silver'" />
        <v-card-actions class="pt-0 pb-0  title">
          {{ workoutInstance.exercise_name }}
        </v-card-actions>
        <v-card-actions>
          <span :class="textSizeSmall + ' pb-0 progressActive--text'">
            {{ 'Set # ' + workoutInstance.setnum }}
          </span>
          <v-spacer />
          <span> {{ workoutInstance.reps }} Reps</span>
        </v-card-actions>
      </template>
    </div>
  </v-container>
</template>
<script>
import axios from 'axios'
import util from '@/mixins/util.js'
import { appConfig } from '@/store/helpers.js'
export default {
  mixins: [util],
  data: () => ({
    workoutInstances: [],
    musclesUsed: [],
    workout: {},
    workoutinstanceid: ''
  }),
  beforeMount() {
    this.workoutinstanceid = this.$route.params.workoutinstanceid
    this.loadWorkoutDetails()
  },
  mounted() {},
  validations: {},
  computed: {
    ...appConfig
  },
  methods: {
    iconNumber(index) {
      let idx = index + 1
      let result = ''
      if (idx < 11) {
        result = 'mdi-numeric-' + idx + '-circle-outline'
      } else if (idx < 20) {
        result =
          'mdi-numeric-1-circle-outline' +
          'mdi-numeric-' +
          idx.charAt(1) +
          '-circle-outline'
      } else {
        result = 'mdi-dots-horizontal-circle-outline'
      }
      return result
    },
    loadMusclesUsed() {
      {
        return axios
          .get(this.baseURL + '/workout/muscles/' + this.workout.workoutid, {})
          .then(response => {
            if (response.status == 200) {
              this.musclesUsed = response.data.data
            } else {
              this.$store.dispatch(
                'notification/addErrors',
                response.data.errors,
                5000,
                true,
                {
                  root: true
                }
              )
              return
            }
          })
      }
    },
    loadWorkoutDetails() {
      {
        return axios
          .get(this.baseURL + '/workout/details/' + this.workoutinstanceid, {})
          .then(response => {
            if (response.status == 200) {
              this.workoutInstances = response.data.data
              this.loadWorkout(this.workoutinstanceid)
            } else {
              this.$store.dispatch(
                'notification/addErrors',
                response.data.errors,
                5000,
                true,
                {
                  root: true
                }
              )
              return
            }
          })
      }
    },
    loadWorkout(workoutinstanceid) {
      {
        return axios
          .get(
            this.$store.state.config.baseURL +
              '/workout/instance/' +
              workoutinstanceid,
            {}
          )
          .then(response => {
            if (response.status == 200) {
              this.workout = response.data.data
              this.loadMusclesUsed()
            } else {
              this.$store.dispatch(
                'notification/addErrors',
                response.data.errors,
                5000,
                true,
                {
                  root: true
                }
              )
              return
            }
          })
      }
    }
  }
}
</script>
