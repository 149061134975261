<template>
  <v-card class=" mx-2 mt-10 charcoal--text d-flex flex-column">
    <v-card-text :class="textSize + ' pl-2 pt-2 pb-3 charcoal--text'">
      Email Confirmation
    </v-card-text>
    <template v-if="success">
      <v-card-text class="px-2 pb-2">
        Thank you, your email was succesfully verified.
        <v-spacer />
        <span v-if="!loggedIn" class="link" @click="$router.push('/login')">
          Please Login.
        </span>
      </v-card-text>

      <v-card-actions :class="textSizeXSmall + ' mt-5 px-2 silver--text'">
        <v-spacer />
        <BaseActionButton
          v-if="!loggedIn"
          label="Login"
          text
          dark
          class="charcoal paper--text"
          icon="mdi-login-variant"
          @clickedThis="$router.push('/login')"
        />
        <BaseActionButton
          v-else
          label="Home"
          text
          dark
          class="charcoal paper--text"
          icon=""
          @clickedThis="$router.push('/')"
        />
      </v-card-actions>
    </template>
    <template v-else>
      <v-card flat class="px-2">
        <template v-if="!codeSent">
          <v-card-text class="caption silver--text pa-0 ">
            There was an error verifying your email.
            <v-spacer />
            Your verification code may have expired.
          </v-card-text>
          <v-card-text :class="textSizeSmall + ' pt-2 px-0'">
            You can request a fresh code below.
          </v-card-text>
          <v-text-field
            :error-messages="emailErrors"
            label="Email"
            v-model="email"
            ref="email"
            type="email"
            required
            prepend-icon="mdi-email"
            :counter="250"
            @input="$v.email.$touch()"
            @blur="$v.email.$touch()"
          />
          <v-card-actions class="px-0">
            <v-spacer />
            <v-btn
              @click="resendCode"
              right
              color="charcoal"
              class="mr-n2"
              plain
            >
              Re-Send Code
            </v-btn>
          </v-card-actions>
        </template>
        <template v-else>
          <v-card-text :class="textSizeXSmall + ' pt-0 px-0'">
            New code was sent.
          </v-card-text>
          <v-card-actions>
            <v-spacer />
            <BaseActionButton
              label="Home"
              text
              dark
              class="charcoal paper--text"
              icon=""
              @clickedThis="$router.push('/')"
            />
          </v-card-actions>
        </template>
      </v-card>
    </template>
  </v-card>
</template>
<script>
import { appConfig } from '@/store/helpers.js'
import util from '@/mixins/util.js'
import { email } from 'vuelidate/lib/validators'
import axios from 'axios'
export default {
  mixins: [util],
  name: 'ConfirmEmail',
  components: {},
  data() {
    return {
      success: false,
      token: null,
      codeSent: false,
      error: '',
      email: '',
      sitekey: '6LcGA6saAAAAAEJ6y4eVkseDEz-uTdDVFhQ0ToUp'
    }
  },
  validations: {
    email: { email }
  },
  computed: {
    emailErrors() {
      const errors = []
      if (!this.$v.email.$dirty) return errors
      !this.$v.email.email && errors.push('Invalid e-mail')
      !this.$v.email.required && errors.push('E-mail is required')
      return errors
    },
    ...appConfig
  },
  beforeMount() {
    this.token = this.$route.params.token
    if (this.loggedIn) this.email = this.userInfo.email
    this.validateEmail()
  },
  methods: {
    resendCode() {
      const email = {
        email: this.email
      }
      return axios
        .put(
          this.$store.state.config.baseURL + '/users/email/resendcode',
          email
        )
        .then(response => {
          if (response.status == 200) {
            this.toast(
              'A new verification code was sent to your original email address. Check your email.'
            )
            this.codeSent = true
          } else {
            this.$store.dispatch(
              'notification/addErrors',
              response.data.errors,
              5000,
              {
                root: true
              }
            )
            return
          }
        })
    },
    validateEmail() {
      return axios
        .get(this.baseURL + '/accounts/confirm/' + this.token, {})
        .then(response => {
          if (response.status == 200) {
            this.success = true
            this.toast('Your email was succesfully confirmed.')
          } else {
            this.$store.dispatch(
              'notification/addErrors',
              response.data.errors,
              5000,
              {
                root: true
              }
            )
            return
          }
        })
    }
  }
}
</script>
