<template>
  <v-container>
    <v-card flat class="mx-auto charcoal">
      <BaseScreenHeader
        title="Activity Log"
        screenInfo="ALG-002"
        :showBack="false"
        :refresh="true"
        @refresh="refreshPage"
      />

      <BaseFreeTier v-if="loaded && loadedList.length > 5" />
      <v-card-actions class="pa-0">
        <v-switch
          dark
          v-model="showStats"
          label="Show Stats"
          color="progressActive"
        />
        <v-spacer />
        <BaseActionButton
          label=""
          text
          :class="textSizeSmall + ' charcoal paper--text mr-n5'"
          large
          title="Add New Activity"
          icon="mdi-plus-circle-outline"
          @clickedThis="showActivitiesDialog = true"
        />
      </v-card-actions>
      <v-sheet class="charcoal" flat>
        <template>
          <BaseLoading v-if="!loaded" />
          <v-sheet v-if="loaded && showStats" class="charcoal mt-2">
            <v-row dense>
              <v-col cols="12" md="6">
                <v-card
                  flat
                  rounded
                  class="charcoal paper--text ma-1"
                  :style="charcoalTile"
                >
                  <v-card-title :class="textSize + ' paper--text py-0'">
                    <v-spacer />
                    <span class="mx-2">THIS WEEK</span>
                    <v-spacer />
                  </v-card-title>
                  <template v-if="loaded && !isEmpty(thisWeek)">
                    <v-card-title :class="textSize + ' silver--text py-0'">
                      <v-divider />
                      <span class="mx-2">
                        Total
                      </span>
                      <v-divider />
                    </v-card-title>
                    <v-row
                      dense
                      class="px-2 progressActive--text"
                      align="center"
                    >
                      <v-col cols="12" align="center">
                        {{ Math.ceil(thisWeek.move_minutes) }} minutes,
                        {{ Math.ceil(thisWeek.calories) }} kcalories
                      </v-col>
                      <v-col cols="12" v-if="thisWeek.miles > 0" align="center">
                        {{ thisWeek.miles }} miles or
                        {{ thisWeek.kilometers }} km
                      </v-col>
                    </v-row>
                    <v-card-title :class="textSize + ' silver--text pb-0'">
                      <v-divider />
                      <span class="mx-2">
                        Average (per activity)
                      </span>
                      <v-divider />
                    </v-card-title>
                    <v-row
                      dense
                      class="px-2 progressActive--text"
                      align="center"
                    >
                      <v-col cols="12" align="center">
                        {{ Math.ceil(thisWeek.move_minutes_avg) }} minutes,
                        {{ Math.ceil(thisWeek.calories_avg) }} kcalories
                      </v-col>
                      <v-col
                        cols="12"
                        v-if="thisWeek.miles_avg > 0"
                        align="center"
                      >
                        {{ thisWeek.miles_avg }} miles or
                        {{ thisWeek.kilometers_avg }} km
                      </v-col>
                    </v-row>
                  </template>
                  <template v-else>
                    <v-col
                      cols="12"
                      :class="textSizeSmall + ' silver--text ml-1'"
                    >
                      No data for this week.
                    </v-col>
                  </template>
                </v-card>
              </v-col>
              <v-col cols="12" md="6">
                <v-card
                  rounded
                  class="charcoal paper--text ma-1"
                  :style="charcoalTile"
                >
                  <v-card-title :class="textSize + ' paper--text py-0'">
                    <v-spacer />
                    <span class="mx-2">THIS MONTH</span>
                    <v-spacer />
                  </v-card-title>
                  <template v-if="loaded && !isEmpty(thisMonth)">
                    <v-card-title :class="textSize + ' silver--text py-0'">
                      <v-divider />
                      <span class="mx-2">
                        Total
                      </span>
                      <v-divider />
                    </v-card-title>
                    <v-row
                      dense
                      class="px-2 progressActive--text"
                      align="center"
                    >
                      <v-col cols="12" align="center">
                        {{ Math.ceil(thisMonth.move_minutes) }} minutes,
                        {{ Math.ceil(thisMonth.calories) }} kcalories
                      </v-col>
                      <v-col
                        cols="12"
                        v-if="thisMonth.miles > 0"
                        align="center"
                      >
                        {{ thisMonth.miles }} miles or
                        {{ thisMonth.kilometers }} km
                      </v-col>
                    </v-row>
                    <v-card-title :class="textSize + ' silver--text pb-0'">
                      <v-divider />
                      <span class="mx-2">
                        Average (per activity)
                      </span>
                      <v-divider />
                    </v-card-title>
                    <v-row
                      dense
                      class="px-2 progressActive--text"
                      align="center"
                    >
                      <v-col cols="12" align="center">
                        {{ Math.ceil(thisMonth.move_minutes_avg) }} minutes,
                        {{ Math.ceil(thisMonth.calories_avg) }} kcalories
                      </v-col>
                      <v-col
                        cols="12"
                        v-if="thisMonth.miles_avg > 0"
                        align="center"
                      >
                        {{ thisMonth.miles_avg }} miles or
                        {{ thisMonth.kilometers_avg }} km
                      </v-col>
                    </v-row>
                  </template>
                  <template v-else>
                    <v-col
                      cols="12"
                      :class="textSizeSmall + ' silver--text ml-1'"
                    >
                      No data for this week.
                    </v-col>
                  </template>
                </v-card>
              </v-col>
            </v-row>
          </v-sheet>
          <v-sheet v-if="loadedList.length > 0" class="transparent py-2">
            <BaseSearch
              dark
              :items="loadedList"
              what="text"
              :label="loadedList.length + ' Activities'"
              @search_results="setResults"
            />
          </v-sheet>
          <v-card-text v-else :class="textSizeSmall + ' silver--text px-0'">
            You have no activities logged. Please add your first activity using
            the
            <span class="paper--text link" @click="showActivitiesDialog = true">
              "+Add"
            </span>
            button above.
          </v-card-text>
          <v-timeline
            class=""
            v-if="activityList.length > 0"
            align-top
            :dense="isPhone"
          >
            <v-timeline-item
              color="progressActive"
              fill-dot
              :left="index % 2 == 0"
              :right="index % 2 != 0"
              v-for="(item, index) in activityList"
              :key="item.id"
              :class="isPhone ? 'ml-n8' : ''"
            >
              <template v-slot:icon>
                <v-icon color="charcoal">
                  {{ item.icon }}
                </v-icon>
              </template>
              <template v-slot:opposite>
                <span class="caption silver--text">
                  {{ item.date }}
                </span>
              </template>
              <v-card
                class="charcoal py-2 rounded-lg rounded-t-0 link mt-n2"
                :style="charcoalTile"
                rounded
                @click="selectActivity(item)"
              >
                <v-card-actions class="silver--text py-0 px-1">
                  <span title="When">
                    {{ item.date }}
                  </span>
                  <v-spacer />
                  <v-icon color="silver">mdi-clock</v-icon>
                  {{ item.duration }}
                </v-card-actions>
                <v-card-actions :class="textSizeXSmall + ' py-0 px-1'">
                  <span title="Category" :class="textSize + ' paper--text'">
                    {{ item.type }}, {{ item.category }}
                  </span>
                  <v-spacer />
                </v-card-actions>
                <v-card-actions
                  :class="textSizeXSmall + ' px-1 py-0 silver--text'"
                >
                  {{ item.text }} {{ item.details ? item.details : '' }}
                </v-card-actions>
                <MyZoneDetails :item="item" />
                <v-card-actions
                  class="pb-0 px-0"
                  v-if="item.calories_total > 0"
                >
                  <v-spacer />
                  <span
                    v-if="item.meps > 0"
                    title="MEPS"
                    :class="textSize + ' paper--text'"
                  >
                    {{ item.meps }} MEPS
                  </span>
                </v-card-actions>
                <v-card-actions
                  class="py-0 px-0"
                  v-if="item.calories_total > 0"
                >
                  <v-spacer />
                  <span
                    title="Calories Burned"
                    :class="textSizeLarge + ' purpleAccent--text'"
                  >
                    {{ Math.ceil(item.calories_total) }} kCal
                  </span>
                </v-card-actions>
                <v-card-text
                  v-if="item.distance_imperial > 0"
                  :class="textSizeXSmall + ' pa-0 px-1'"
                >
                  <span title="Distance" class="paper--text">
                    {{ item.distance_imperial }} miles or
                    {{ item.distance_metric }} Km
                  </span>
                  <v-spacer />
                  <span title="Pace" class="silver--text">
                    {{ item.pace_imperial }} mins/mile or
                    {{ item.pace_metric }} mins/Km
                  </span>
                  <v-spacer />
                  <span title="Pace" class="silver--text">
                    {{ item.speed_imperial }} mph or
                    {{ item.speed_metric }} Km/h
                  </span>
                </v-card-text>
                <template v-if="item.calories_per_minute > 0">
                  <v-card-actions :class="textSizeXSmall + ' px-1 py-0'">
                    <span title="Category" class="success--text">
                      {{ item.duration }} minutes @{{
                        item.calories_per_minute
                      }}
                      kCal/minute
                    </span>
                    <v-spacer />
                  </v-card-actions>
                </template>
              </v-card>
            </v-timeline-item>
          </v-timeline>
        </template>
      </v-sheet>
    </v-card>
    <v-dialog
      v-model="showRowInfo"
      :fullscreen="isPhone"
      width="900"
      overlay-opacity="0.95"
    >
      <v-card class="d-flex flex-column noscroll charcoal" tile>
        <BaseCloseDialogHeader
          heading="Delete Activity?"
          @cancel="showRowInfo = false"
        />

        <v-card-text
          dense
          :class="textSize + ' progressActive--text px-2  py-1'"
        >
          {{ capitalize(selectedActivity.text) }}
        </v-card-text>
        <v-card-subtitle :class="textSizeXSmall + ' silver--text px-2 pt-2'">
          Entered on: {{ selectedActivity.when }}
        </v-card-subtitle>
        <v-sheet class="mx-2 px-2 pb-10" :style="charcoalTile">
          <v-card-actions :class="textSize + ' px-0 pt-0 progressActive--text'">
            <v-divider class="charcoal lighten-1" />
            <span class="mx-2">
              {{ selectedActivity.type }}
            </span>
            <v-divider class="charcoal lighten-1" />
          </v-card-actions>
          <v-card-actions class="silver--text py-0 px-0">
            Calories per Minute
            <v-spacer />
            <span class="progressActive--text">
              {{ selectedActivity.calories_per_minute }}
            </span>
          </v-card-actions>
          <v-card-actions class="silver--text py-0 px-0">
            Total Calories
            <v-spacer />
            <span class="progressActive--text">
              {{ selectedActivity.calories_total }}
            </span>
          </v-card-actions>
          <v-card-actions class="silver--text py-0 px-0">
            METS
            <v-spacer />
            <span class="progressActive--text">
              {{ selectedActivity.met }}
            </span>
          </v-card-actions>
          <v-card-actions class="silver--text py-0 px-0">
            Duration
            <v-spacer />
            <span class="progressActive--text">{{
              selectedActivity.duration
            }}</span>
          </v-card-actions>
          <v-card-actions
            class="silver--text py-0 px-0"
            v-if="selectedActivity.distance_metric > 0"
          >
            Distance
            <v-spacer />
            <span class="progressActive--text"
              >{{ selectedActivity.distance_imperial }} miles,
              {{ selectedActivity.distance_metric }} Km</span
            >
          </v-card-actions>
          <v-spacer />
        </v-sheet>
        <v-card-subtitle :class="textSizeXSmall + ' silver--text px-2 pt-2'">
          Review the information above. Click the
          <span class="progressActive--text">Delete</span> button to remove the
          activity from the log.
        </v-card-subtitle>
        <v-spacer />

        <v-card-actions class="px-0">
          <BaseActionButton
            plain
            :large="!isPhone"
            icon="mdi-close"
            label="Cancel"
            color="silver"
            @clickedThis="showRowInfo = false"
          />
          <v-spacer />
          <BaseActionButton
            plain
            :large="!isPhone"
            label="Delete"
            dark
            class="charcoal paper--text"
            icon="mdi-trash-can-outline"
            @clickedThis="deleteActivity"
          />
        </v-card-actions>
      </v-card>
    </v-dialog>
    <Activities
      :show="showActivitiesDialog"
      @cancel="showActivitiesDialog = false"
      @saved="saved"
    />
  </v-container>
</template>
<script>
import axios from 'axios'
import { appConfig } from '@/store/helpers.js'
import util from '@/mixins/util.js'
const Activities = () =>
  import(/* webpackPrefetch: true */ '@/components/dialogs/Activities.vue')
const MyZoneDetails = () =>
  import(/* webpackPrefetch: true */ '@/components/blocks/MyZoneDetails.vue')
import debounce from 'lodash/debounce'
export default {
  components: { Activities, MyZoneDetails },
  name: 'ActivityLog',
  mixins: [util],
  props: {
    show: {
      type: Boolean
    }
  },
  data() {
    return {
      textFieldProps: {
        appendIcon: 'mdi-calendar'
      },
      dateProps: {
        headerColor: 'charcoal',
        color: 'progressActive'
      },
      timeProps: {
        scrollable: true,
        headerColor: 'charcoal',
        color: 'progressActive',
        useSeconds: false,
        ampmInTitle: true
      },
      loaded: false,
      showStats: false,
      refresh: false,
      saveWeight: false,
      selectedActivity: {},
      metric: false,
      showActivitiesDialog: false,
      showRowInfo: false,
      isUserScrolling: false,
      activity: '',
      added: '',
      search: '',
      activityList: [],
      loadedList: [],
      thisWeek: {},
      thisMonth: {},
      datetime: null,
      selectedDateTime: null,
      debounce: null,
      pagesize: 50,
      currentPage: 0,
      totalCount: 100, //fetch from API
      bottom: false
    }
  },
  beforeMount() {
    this.loadWeekly()
    this.loadMonthly()
    this.loadPage()
  },
  mounted() {
    this.handleDebouncedScroll = debounce(this.handleScroll, 100)
    window.addEventListener('scroll', this.handleDebouncedScroll)
  },
  beforeDestroy() {
    // I switched the example from `destroyed` to `beforeDestroy`
    // to exercise your mind a bit. This lifecycle method works too.
    window.removeEventListener('scroll', this.handleDebouncedScroll)
  },

  watch: {
    bottom(bottom) {
      if (bottom && this.loaded) {
        this.currentPage++
        this.loadPage()
      }
    }
  },
  computed: {
    itemsCount() {
      return this.activityList.length
    },
    distanceLabel() {
      if (this.metric) return 'Distance (km)'
      else return 'Distance (miles)'
    },
    valueErrors() {
      const errors = []
      if (!this.$v.value.$dirty) return errors
      !(this.imperial > 0 || this.metric > 0) &&
        errors.push('Value is required.')
      return errors
    },
    ...appConfig
  },
  methods: {
    refreshPage() {
      this.loaded = false
      this.refresh = true
      this.loadWeekly()
      this.loadMonthly()
      this.loadPage()
    },
    handleScroll(/*event*/) {
      this.isUserScrolling = window.scrollY > 0
      this.bottom = this.bottomVisible()
    },
    setResults(value) {
      this.activityList = value
    },
    saved() {
      this.refresh = true
      this.currentPage = 0
      this.loadPage()
      this.showActivitiesDialog = false
    },
    addActivity() {
      this.showAddDialog = true
    },
    cancel() {
      this.showAddDialog = false
    },
    selectActivity(value) {
      this.showRowInfo = true
      this.selectedActivity = value
    },
    deleteActivity() {
      return axios
        .delete(this.baseURL + '/activity/' + this.selectedActivity.id)
        .then(response => {
          if (response.status == 200) {
            this.selectedActivity = {}
            this.showRowInfo = false
            this.refresh = true
            this.currentPage = 0
            this.loadPage()
            this.toast('Activity deleted succesfully.')
          } else {
            this.$store.dispatch(
              'notification/addErrors',
              response.data.errors,
              5000,
              true,
              {
                root: true
              }
            )
            return
          }
        })
    },
    loadPage() {
      this.loaded = false
      if (this.refresh) this.currentPage = 0
      return axios
        .get(
          this.baseURL +
            '/activities/scroll/' +
            this.currentPage +
            '/' +
            this.pagesize,
          {}
        )
        .then(response => {
          if (response.status == 200) {
            if (this.refresh) {
              this.activityList = response.data.data
            } else
              this.activityList = this.activityList.concat(response.data.data)

            this.loadedList = [...this.activityList]
            this.loaded = true
            this.refresh = false
          } else {
            this.$store.dispatch(
              'notification/addErrors',
              response.data.errors,
              5000,
              true,
              {
                root: true
              }
            )
            return
          }
        })
    },
    loadWeekly() {
      return axios
        .get(this.baseURL + '/stats/activities/thisweek:all', {})
        .then(response => {
          if (response.status == 200) {
            this.thisWeek = response.data.data[0]
          } else {
            this.$store.dispatch(
              'notification/addErrors',
              response.data.errors,
              5000,
              true,
              {
                root: true
              }
            )
            return
          }
        })
    },
    loadMonthly() {
      return axios
        .get(this.baseURL + '/stats/activities/thismonth:all', {})
        .then(response => {
          if (response.status == 200) {
            this.thisMonth = response.data.data[0]
          } else {
            this.$store.dispatch(
              'notification/addErrors',
              response.data.errors,
              5000,
              true,
              {
                root: true
              }
            )
            return
          }
        })
    }
  }
}
</script>
