<template>
  <v-container>
    <BaseScreenHeader title="Start HIIT Workout" screenInfo="WRK-004" />

    <BaseLoading label="Loading Workout" v-if="!loaded" />
    <template v-else>
      <v-card-actions
        :class="textSize + ' paper--text text-uppercase px-0 pb-0'"
      >
        <v-divider />
        <span class="mx-2">{{ workout.name }}</span>
        <v-divider />
      </v-card-actions>
      <v-card-actions :class="textSizeXSmall + ' silver--text text-center'">
        <span>{{ workout.description }}</span>
        <v-spacer />
      </v-card-actions>
      <v-sheet class="transparent charcoalTile pa-2 mx-2 " rounded>
        <v-card-actions :class="textSizeXSmall + ' silver--text py-0'">
          <v-divider class="transparent" />
          <span :class="textSizeLarge + ' progressActive--text mx-1'">
            {{ Math.floor(workout.total_time) }}
          </span>
          minutes
          <span :class="textSizeLarge + ' progressActive--text mx-1'">
            {{ workout.rounds }}
          </span>
          <span>rounds</span>
          <span :class="textSizeLarge + ' progressActive--text'">
            {{ exercises.length }}
          </span>
          <span>exercises</span>
          <v-divider class="transparent" />
        </v-card-actions>
      </v-sheet>
      <v-sheet rounded class="silver--text pa-2 transparent text-right">
        <span :class="textSize"> {{ workout.roundTime }} Seconds / Round </span>
        <v-progress-linear
          class="mt-1"
          height="25"
          rounded
          background-color="charcoal lighten-1"
          color="success"
          :value="workout.percentage"
        >
          <template v-slot:default="{ value }">
            <span class="paper--text">
              {{ parseInt(workout.active_time) }} seconds active. {{ value }}%
            </span>
          </template>
        </v-progress-linear>
        <v-progress-linear
          class="mt-2"
          height="25"
          rounded
          background-color="charcoal lighten-1"
          color="gold"
          :value="100 - workout.percentage"
        >
          <template v-slot:default="{ value }">
            <span class="paper--text">
              {{ parseInt(workout.rest_time_set) }} seconds rest. {{ value }}%
            </span>
          </template>
        </v-progress-linear>
      </v-sheet>
    </template>
    <v-spacer />
    <v-card-actions>
      <v-spacer />
      <v-btn
        fab
        x-large
        elevation="2"
        label="Start"
        title="Start Workout"
        @click="startWorkout"
        class=" paper charcoal--text link"
      >
        <v-icon x-large>
          mdi-play-box
        </v-icon>
      </v-btn>
      <v-spacer />
    </v-card-actions>
    <v-row
      v-for="exercise in exercises"
      :key="exercise.id"
      class="py-2 ml-2"
      dense
    >
      <span class="silver--text">
        <BaseIconNumber color="progressActive" :value="exercise.sort" />
        <span :class="textSizeSmall">
          {{ exercise.name }}
        </span>
      </span>
    </v-row>
    <v-dialog
      :z-index="zIndex"
      :value="dialog"
      fullscreen
      persistent
      :key="switchNum"
    >
      <v-card
        class="d-flex flex-column mt-0 px-0 noscroll-x"
        tile
        color="charcoal"
      >
        <BaseCloseDialogHeader
          heading="Circuit Workout (HIIT/Tabata)"
          @cancel="cancel"
        />
        <template v-if="loaded">
          <v-card-actions class="py-0 mt-n2">
            <v-img
              :src="imageLogo"
              contain
              title="Logo"
              :height="isPhone ? 40 : 60"
            />
          </v-card-actions>
          <v-sheet rounded class="pa-2 pt-0 mt-n10 charcoal">
            <video-background
              :src="videoLoop"
              style="max-height: 80px; height: 50vh;opacity:0.25"
              overlay="linear-gradient(45deg,#2a4ae430,#fb949e6b)"
            >
            </video-background>
          </v-sheet>
          <v-card-title :class="textSize + '  px-2 py-0'">
            <v-divider class="charcoal lighten-1" />
            <span class="mx-2 silver--text">{{ workout.name }}</span>
            <v-divider class="charcoal lighten-1" />
          </v-card-title>
          <v-sheet color="charcoal" class="silver--text">
            <v-card-actions>
              <template v-if="!completed">
                <v-card-text
                  v-if="!prestart"
                  :class="textSize + ' text-center py-0'"
                >
                  ROUND {{ round() }} of
                  {{ workout.rounds }}
                </v-card-text>
                <v-card-text v-else :class="textSize + ' text-center py-0'">
                  GET READY!
                </v-card-text>
              </template>
              <template v-else>
                <v-card-text :class="textSize + ' text-center py-0'">
                  FINISHED!
                </v-card-text>
              </template>
            </v-card-actions>
          </v-sheet>
          <v-progress-linear
            color="progressActive"
            v-model="progress"
            height="2"
          >
          </v-progress-linear>
          <v-card-text
            v-if="
              currentRound > 0 && currentRound <= workout.rounds && !completed
            "
            :class="textSize + ' paper--text text-center  text-uppercase py-3'"
          >
            {{ rounds[currentRound - 1].exercise_name }}
          </v-card-text>
          <v-card-text
            :class="textSize + ' paper--text text-center text-uppercase py-4'"
            v-else
          >
            <template v-if="!completed">
              Next: {{ rounds[0].exercise_name }}
            </template>
            <template v-else>
              <v-card-text class="title text-uppercase text-center py-2">
                Number of Reps: {{ repsTotal }}
              </v-card-text>
            </template>
          </v-card-text>
          <v-divider class="charcoal lighten-1 mb-5" />
        </template>
        <v-card-text v-if="!completed" class="clock text-center">
          <v-progress-circular
            v-if="prestart"
            :rotate="-90"
            :size="circleSize"
            :width="circleWidth"
            :value="100 - value * 20"
            color="paper"
          >
            <span :class="textSizeXXLarge">{{ value > 0 ? value : 'GO' }}</span>
          </v-progress-circular>
          <v-progress-circular
            v-if="!prestart"
            :rotate="-90"
            :size="circleSize"
            :width="circleWidth"
            :value="
              mode == 'active'
                ? 100 - value * (100 / workout.active_time)
                : 100 - value * (100 / workout.rest_time_set)
            "
            :color="getColor()"
          >
            <span :class="textSizeXXLarge">{{ value }}</span>
          </v-progress-circular>
        </v-card-text>
        <template v-else>
          <v-sheet
            rounded
            :class="textSize + ' charcoal charcoalTile silver--text pa-2 mx-2'"
          >
            <div class="caption py-2">
              Enter either the average number of reps per round or the total
              number of reps for the whole workout.
            </div>
            <v-row>
              <v-col cols="10" md="5" class="pl-5">
                <v-text-field
                  dark
                  :class="isPhone ? 'enlarged-input-small' : 'enlarged-input'"
                  filled
                  color="success"
                  type="number"
                  pattern="[0-9]*"
                  inputmode="decimal"
                  v-model="repsAverage"
                  ref="repsAverage"
                  label="Reps (average)"
                  prepend-icon="mdi-dots-horizontal"
                  @input="calculateReps('avg')"
                  @focus="$event.target.select()"
                />
              </v-col>
              <span :class="textSize + '  px-3'">or</span>
              <v-col cols="10" md="5" class="pl-5">
                <v-text-field
                  dark
                  :class="isPhone ? 'enlarged-input-small' : 'enlarged-input'"
                  filled
                  color="success"
                  type="number"
                  pattern="[0-9]*"
                  inputmode="decimal"
                  v-model="repsTotal"
                  ref="repsTotal"
                  label="Reps(total)"
                  prepend-icon="mdi-dots-vertical"
                  @input="calculateReps('total')"
                  @focus="$event.target.select()"
                />
              </v-col>
            </v-row>
          </v-sheet>
          <v-card-actions class="py-0">
            <v-switch
              dark
              color="accent"
              ref="autoSave"
              label="Add to Activity Log?"
              v-model="autoSave"
            />
            <v-spacer />
            <BaseActionButton
              :disabled="!repsTotal > 0"
              plain
              dark
              class="ml-n2 charcoal paper--text"
              @clickedThis="save"
            />
          </v-card-actions>
        </template>
        <v-card-actions class=" pa-0" v-if="!completed">
          <v-spacer />
          <template v-if="mode == 'active'">
            <span :class="textSizeLarge + ' silver--text'">WORK</span>
          </template>
          <template v-if="mode == 'rest'">
            <span :class="textSizeLarge + ' silver--text'">REST</span>
          </template>
          <template v-if="mode == ''">
            <span :class="textSizeLarge + ' silver--text'">GET READY</span>
          </template>
          <v-spacer />
        </v-card-actions>

        <v-spacer />
        <v-list class="transparent">
          <v-list-item
            v-for="(exercise, index) in exercises"
            :key="exercise.id"
          >
            <BaseIconNumber :color="iconColor(exercise)" :value="index + 1" />
            <span class="paper--text ml-2"> {{ exercise.name }}</span>
          </v-list-item>
        </v-list>
      </v-card>
    </v-dialog>
  </v-container>
</template>
<script>
import axios from 'axios'
import { appConfig } from '@/store/helpers.js'
import util from '@/mixins/util.js'
import sounds from '@/json/sounds.json'
import moment from 'moment/src/moment'
export default {
  mixins: [util],
  data: () => ({
    sounds: sounds,
    interval: {},
    mode: '',
    loaded: false,
    autoSave: false,
    completed: false,
    value: 5,
    countdown: 5,
    repsTotal: 0,
    repsAverage: 0,
    repsRemaining: 0,
    circleSize: 370,
    circleWidth: 10,
    currentRound: 0,
    dialog: false,
    prestart: true,
    zIndex: 0,
    workout: [],
    exercises: [],
    rounds: [],
    starttime: '',
    startTimeMoment: null,
    endtime: '',
    tic: 1000,
    switchNum: 0,
    sound: false,
    progress: 0,
    activityid: 38, //circuit training, moderate
    calories: 4.3,
    audio: null,
    beep: null,
    go: null,
    work: null,
    rest: null,
    videoLoop: null,
    imageLogo: null
  }),
  props: {
    workoutid: {
      type: String
    }
  },
  beforeMount() {
    this.videoLoop = this.isPhone
      ? require(`@/assets/LoopSmall.mp4`)
      : require(`@/assets/Loop.mp4`)
    this.loadWorkout(this.workoutid)
    this.audio = new Audio(this.sounds['5secs'])
    this.beep = new Audio(this.sounds['ready'])
    this.go = new Audio(this.sounds['go'])
    this.rest = new Audio(this.sounds['rest'])
    this.work = new Audio(this.sounds['work'])
    this.audio.preload = 'auto'
    this.beep.preload = 'auto'
    this.go.preload = 'auto'
    this.rest.preload = 'auto'
    this.work.preload = 'auto'
    this.imageLogo = require('@/assets/Logo100.png')
  },
  beforeUpdate() {
    this.circleSize = this.isPhone ? 220 : 370
    this.circleWidth = this.isPhone ? 5 : 10
  },
  beforeDestroy() {
    clearInterval(this.interval)
    this.screenOff()
  },
  computed: {
    perRound() {
      return (
        parseInt(this.workout.active_time) +
        parseInt(this.workout.rest_time_exercise)
      )
    },

    ...appConfig
  },
  watch: {
    dialog: function(value) {
      if (value) {
        this.screenOn()
      } else this.screenOff()
    }
  },
  methods: {
    calculateProgress() {
      this.progress = Math.ceil((this.round() / this.workout.rounds) * 100)
    },
    elapsedSeconds() {
      return moment().diff(this.startTimeMoment, 'seconds')
    },
    round() {
      return Math.ceil(this.elapsedSeconds() / this.perRound)
    },
    roundColor(exercise) {
      if (!this.currentRound > 0) return 'silver--text'
      let val = 'silver--text'
      if (this.rounds[this.currentRound - 1].exercise_id == exercise.id)
        val = 'progressActive--text'
      return val
    },
    iconColor(exercise) {
      if (!this.currentRound > 0) return 'silver'
      let val = 'silver'
      if (this.rounds[this.currentRound - 1].exercise_id == exercise.id)
        val = 'progressActive'
      return val
    },
    calculateReps(what) {
      if (what == 'total') {
        this.repsAverage = Math.floor(
          parseInt(this.repsTotal / this.workout.rounds)
        )
        this.repsRemaining = parseInt(this.repsTotal % this.workout.rounds)
      } else {
        this.repsTotal = parseInt(this.repsAverage * this.workout.rounds)
        this.repsRemaining = 0
      }
      let avg = this.repsAverage
      let remaining = this.repsRemaining
      this.rounds.forEach(function(element) {
        if (element.round == 1) {
          element.reps = parseInt(avg) + parseInt(remaining)
        } else element.reps = avg
      })
    },
    reset() {
      this.repsTotal = 0
      this.repsAverage = 0
      this.repsRemaining = 0
      this.currentRound = 0
      this.prestart = true
      this.value = 0
      this.countdown = 5
      this.mode = ''
      clearInterval(this.interval)
      this.sound = false
      this.switchNum++
    },
    done() {
      this.reset()
      this.dialog = false
    },
    cancel() {
      this.reset()
      this.dialog = false
    },
    getColor() {
      if (this.mode == 'active') return 'progressActive'
      else return 'paper'
    },
    playStart() {
      if (this.isNativeApp() && !this.isAndroid()) this.playStartNative()
      else this.go.play()
    },
    playEnd() {
      if (this.isNativeApp() && !this.isAndroid()) this.playEndNative()
      else this.audio.play()
    },
    playDing() {
      if (this.isNativeApp() && !this.isAndroid()) this.playDingNative()
      else this.beep.play()
    },
    playRest() {
      if (this.isNativeApp() && !this.isAndroid()) this.playRestNative()
      else this.rest.play()
    },
    playActive() {
      this.work.play()
    },
    playSound(what) {
      if (this.sound) {
        var audio = new Audio(what)
        audio.play()
      }
    },
    startWorkout() {
      this.screenOn()
      this.dialog = true
      this.sound = true
      this.value = this.countdown

      if (this.prestart) {
        this.interval = setInterval(() => {
          if (this.value == this.countdown - 3) {
            this.playStart()
          }
          if (this.value == 0) {
            this.starttime = this.now()
            this.startTimeMoment = moment()
            this.sound = false
            this.prestart = false
            clearInterval(this.interval)
            this.startActive()
            return true
          }
          this.value -= 1
        }, this.tic)
      }
    },
    startActive() {
      let activeStart = moment()
      this.mode = 'active'
      let activeDuration = Math.ceil(this.workout.active_time)
      let active = activeDuration
      this.value = active
      this.currentRound++

      this.playActive()
      this.interval = setInterval(() => {
        this.calculateProgress()
        if (active == 0) {
          clearInterval(this.interval)
          this.startRest()
          return true
        } else {
          active = activeDuration - moment().diff(activeStart, 'seconds')
        }
        this.value = active
      }, this.tic)
    },
    startRest() {
      let restStart = moment()
      this.mode = 'rest'
      this.sound = false
      let restDuration = Math.ceil(this.workout.rest_time_set)
      let rest = restDuration
      this.value = restDuration
      this.playDing()
      if (this.mode == 'rest') {
        this.interval = setInterval(() => {
          this.calculateProgress()
          if (rest == 4) {
            this.playEnd()
          }
          if (rest == restDuration) this.playRest()
          if (rest == 0) {
            clearInterval(this.interval)
            if (this.currentRound < this.workout.rounds) {
              this.startActive()
            } else {
              this.completed = this.currentRound == this.workout.rounds
              if (this.completed) {
                this.endtime = this.now()
                this.screenOff()
              }
              return true
            }
          } else {
            rest = restDuration - moment().diff(restStart, 'seconds')
          }
          this.value = rest
        }, this.tic)
      }
    },
    loadWorkout(workoutid) {
      {
        return axios
          .get(this.baseURL + '/workout/' + workoutid, {})
          .then(response => {
            if (response.status == 200) {
              this.workout = response.data.data
              this.workout.roundTime =
                parseInt(this.workout.active_time) +
                parseInt(this.workout.rest_time_exercise)
              this.workout.percentage = Math.round(
                (parseInt(this.workout.active_time) / this.workout.roundTime) *
                  100
              )
              this.exercises = JSON.parse(this.workout.exercises)
              let cycle = this.exercises.length
              let total = cycle * this.workout.rounds

              for (let i = 0; i < total; ++i) {
                let index = parseInt(i % cycle)
                this.rounds[i] = {
                  round: i + 1,
                  exercise_index: index,
                  exercise_name: this.exercises[index].name,
                  exercise_id: this.exercises[index].id,
                  reps: 0
                }
              }
              this.exercises.forEach(function(element) {
                element.total_metric = 0
                element.total_imperial = 0
                element.set = []
                for (let i = 1; i <= element.sets; i++) {
                  element.set.push({ num: i, metric: 0, imperial: 0, reps: 0 })
                }
              })
              this.loaded = true
            } else {
              this.$store.dispatch(
                'notification/addErrors',
                response.data.errors,
                5000,
                true,
                {
                  root: true
                }
              )
              return
            }
          })
      }
    },
    save() {
      return axios
        .post(this.baseURL + '/workout/hiit', {
          active: Math.ceil(this.workout.active_time),
          restSet: Math.ceil(this.workout.rest_time_set),
          restExercise: Math.ceil(this.workout.rest_time_exercise),
          totalActive: Math.ceil(this.workout.active_time_total),
          total: Math.ceil(this.workout.total_time),
          starttime: this.starttime,
          endtime: this.endtime,
          rounds: this.rounds,
          totalRounds: this.workout.rounds,
          workoutid: this.workout.workoutid
        })
        .then(response => {
          if (response.status == 200) {
            if (this.autoSave) this.saveActivity()
            else {
              this.done()
              this.$router.push({
                path: '/'
              })
              this.toast('Workout was saved..')
            }
          } else {
            this.$store.dispatch(
              'notification/addErrors',
              response.data.errors,
              5000,
              true,
              {
                root: true
              }
            )
            return
          }
        })
    },
    saveActivity() {
      let duration = this.twoDigits(this.workout.active_time_total / 60)
      let totalCalories = this.twoDigits(this.calories * duration)
      return axios
        .post(this.baseURL + '/activities', {
          duration: duration,
          calories: this.calories,
          totalCalories: totalCalories,
          start_time: this.starttime,
          distance_imperial: 0,
          distance_metric: 0,
          activityid: this.activityid
        })
        .then(response => {
          if (response.status == 200) {
            this.done()
            this.$router.push({
              path: '/myworkouts'
            })
          } else {
            this.$store.dispatch(
              'notification/addErrors',
              response.data.errors,
              5000,
              true,
              {
                root: true
              }
            )
            return
          }
        })
    }
  }
}
</script>
