<template>
  <v-container>
    <BaseScreenHeader title="Start AMRAP Workout" screenInfo="WRK-007" />

    <BaseLoading
      v-if="!loaded"
      class="my-2"
      label="Loading Workout Information"
    />
    <template v-else>
      <v-card-actions
        :class="textSize + ' paper--text text-uppercase px-0 pb-0'"
      >
        <v-divider class="transparent" />
        <span class="pl-2">{{ workout.name }}</span>
        <v-divider class="transparent" />
      </v-card-actions>
      <v-card-actions :class="textSizeXSmall + ' paper--text pt-0 pl-2'">
        <v-divider class="charcoal lighten-1" />
        <span class="mx-2">
          As Many Rounds As Possible (AMRAP)
        </span>
        <v-divider class="charcoal lighten-1" />
      </v-card-actions>
      <v-sheet class="charcoal silver--text text-center py-2">
        <v-card-actions
          v-if="workout.description != 'NA'"
          :class="textSizeXSmall + ' py-0 pl-2'"
        >
          <span>{{ workout.description }}</span>
        </v-card-actions>
      </v-sheet>
      <v-sheet rounded class="rounded-lg charcoal charcoalTileMenu py-2">
        <v-card-actions :class="textSizeXSmall + ' silver--text py-0'">
          <v-divider class="transparent" />
          <span
            v-if="workout"
            :class="textSizeXLarge + ' progressActive--text mx-1'"
          >
            {{ Math.floor(workout.total_time) }}
          </span>
          minutes
          <span :class="textSizeXLarge + ' progressActive--text mx-1'">
            {{ exercises.length }}
          </span>
          <span>exercises</span>
          <v-divider class="transparent" />
        </v-card-actions>

        <v-spacer />
        <v-row dense justify="center" class="py-4" align="center">
          <v-btn
            fab
            x-large
            elevation="2"
            label="Start"
            title="Start Workout"
            @click="startWorkout"
            class="paper charcoal--text link"
          >
            <v-icon x-large>
              mdi-play-box
            </v-icon>
          </v-btn>
        </v-row>

        <v-row
          v-for="exercise in exercises"
          :key="exercise.id"
          class="py-2 ml-2"
          dense
        >
          <span class="silver--text">
            <BaseIconNumber
              :value="exercise.sort"
              color="progressActive"
              class="pl-1 mt-n1"
            />
            <span :class="textSizeSmall">
              {{ exercise.name }}
            </span>
          </span>
        </v-row>
      </v-sheet>
    </template>

    <v-dialog
      :z-index="zIndex"
      :value="dialog"
      fullscreen
      persistent
      :key="switchNum"
    >
      <v-card class="d-flex flex-column noscroll-x" tile color="charcoal">
        <BaseCloseDialogHeader heading="AMRAP" @cancel="cancel" />

        <v-card-actions class="py-0 mt-n2">
          <v-img
            :src="imageLogo"
            contain
            title="Logo"
            :height="isPhone ? 40 : 60"
          />
        </v-card-actions>
        <v-sheet rounded class="pa-2 pt-0 mt-n10 charcoal">
          <video-background
            :src="videoLoop"
            style="max-height: 80px; height: 50vh;opacity:0.25"
            overlay="linear-gradient(45deg,#2a4ae430,#fb949e6b)"
          >
          </video-background>
        </v-sheet>
        <template v-if="loaded">
          <v-card-actions class="pa-0">
            <v-divider class="charcoal lighten-1" />
            <span v-if="loaded" class="mx-2 silver--text">
              {{ workout.name }}
            </span>
            <v-divider class="charcoal lighten-1" />
          </v-card-actions>

          <v-sheet class="charcoal silver--text">
            <template v-if="!completed">
              <v-card-text v-if="!prestart" :class="textSize + ' text-center'">
                TOTAL ROUNDS ({{ currentRound }})
                <v-row justify="center" class="py-2">
                  <BaseIconCounter
                    :rounds="workout.rounds"
                    :current="currentRound"
                    :dark="true"
                  />
                </v-row>
              </v-card-text>
              <v-card-text v-else :class="textSize + ' text-center'">
                GET READY!
              </v-card-text>
            </template>
            <template v-else>
              <v-card-text :class="textSize + ' text-center'">
                FINISHED!
              </v-card-text>
            </template>
          </v-sheet>
          <v-progress-linear
            color="progressActive"
            v-model="progress"
            height="2"
          >
          </v-progress-linear>
          <v-card-actions class="mt-n2 pa-0 pr-2">
            <v-spacer />
            <v-switch
              dark
              color="progressActive"
              label="Add to Activity Log?"
              v-model="autoSave"
            />
          </v-card-actions>
        </template>
        <v-card-text v-if="!completed" class="clock mt-n2 py-0 text-center">
          <v-progress-circular
            v-if="prestart"
            :rotate="-90"
            :size="circleSize"
            :width="circleWidth"
            :value="100 - value * 20"
            color="paper"
          >
            <span :class="textSizeXLarge">
              {{ value > 0 ? value : 'GO' }}
            </span>
          </v-progress-circular>
          <v-progress-circular
            v-if="!prestart"
            :rotate="-90"
            :size="circleSize"
            :width="circleWidth"
            color="progressActive"
            value="100"
            :class="textSizeXLarge"
          >
            <span class="progressActive--text">
              {{ valueMinutes }} : {{ valueSeconds }}
            </span>
          </v-progress-circular>
        </v-card-text>
        <v-card-actions class="pt-1" v-if="!completed">
          <v-spacer />
          <template v-if="mode == 'active'">
            <span :class="textSize + ' silver--text'">WORK</span>
          </template>
          <template v-else>
            <span :class="textSize + ' silver--text'">GET READY</span>
          </template>
          <v-spacer />
        </v-card-actions>
        <v-spacer />
        <v-card-actions class="pl-0 pr-1 elevation-5">
          <template>
            <BaseActionButton
              :disabled="prestart"
              v-if="!completed"
              dark
              text
              label="Next Round"
              title="Next Round"
              icon="mdi-chevron-double-right"
              color="paper"
              @clickedThis="nextRound"
            />
            <v-spacer />
            <BaseActionButton
              dark
              text
              :disabled="!totalReps > 0 || prestart"
              label="Finalize"
              icon="mdi-arrow-down-box"
              color="progressActive"
              @clickedThis="save"
            />
          </template>
        </v-card-actions>
        <v-card
          tile
          v-if="completed || currentSet >= 1"
          class="charcoal charcoalTile pa-2 mx-2"
          rounded
          d-flex
          flex-column
        >
          <v-row dense v-for="(set, index) in sets" :key="index">
            <v-col
              v-if="
                completed ||
                  currentRound == parseInt(index / exercises.length + 1)
              "
              cols="12"
            >
              <v-card-actions class="px-0 py-0  ">
                <v-badge
                  color="charcoal--text text--lighten-3"
                  offset-y="10"
                  offset-x="15"
                  :content="'Set ' + (index + 1)"
                >
                  <BaseIconNumber color="paper" :value="currentRound" />
                </v-badge>
                <span class="paper--text ml-1">
                  {{ set.exercise_name }}
                </span>
              </v-card-actions>
              <v-row dense>
                <v-col cols="3">
                  <v-text-field
                    dark
                    :class="isPhone ? 'enlarged-input-small' : 'enlarged-input'"
                    filled
                    color="success"
                    type="number"
                    pattern="[0-9]*"
                    inputmode="decimal"
                    label="Reps"
                    v-model="set.reps"
                    @input="calculateVolume(set)"
                    @focus="$event.target.select()"
                  />
                </v-col>
                <v-spacer />
                <template v-if="set.resistance == 'Yes'">
                  <v-col cols="4">
                    <v-text-field
                      dark
                      :class="
                        isPhone ? 'enlarged-input-small' : 'enlarged-input'
                      "
                      filled
                      color="success"
                      type="number"
                      pattern="[0-9]*"
                      inputmode="decimal"
                      label="Lbs"
                      v-model="set.imperial"
                      @input="calculateMetric(set)"
                      @focus="$event.target.select()"
                    />
                  </v-col>
                  <v-col cols="4">
                    <v-text-field
                      dark
                      :class="
                        isPhone ? 'enlarged-input-small' : 'enlarged-input'
                      "
                      filled
                      color="success"
                      type="number"
                      pattern="[0-9]*"
                      inputmode="decimal"
                      label="Kg"
                      v-model="set.metric"
                      @input="calculateImperial(set)"
                      @focus="$event.target.select()"
                    />
                  </v-col>
                </template>
              </v-row>
              <v-card-actions class="pt-0 px-0 mt-n7">
                <v-spacer />
                <span
                  v-if="set.resistance == 'Yes'"
                  :class="textSizeXSmall + ' silver--text'"
                >
                  Volume:
                  {{ set.volume_imperial }} lbs /
                  {{ set.volume_metric }} kg</span
                >
              </v-card-actions>
            </v-col>
          </v-row>
          <v-spacer />
          <v-card-actions class="charcoal px-0 elevation-1">
            <BaseActionButton
              dark
              v-if="!completed"
              text
              label="Next Round"
              icon="mdi-chevron-double-right"
              color="paper"
              class="ml-n2"
              @clickedThis="nextRound"
            />
            <v-spacer />
            <BaseActionButton
              dark
              v-if="completed"
              text
              label="Finalize Workout"
              icon="mdi-arrow-down-box"
              color="progressActive"
              @clickedThis="save"
            />
          </v-card-actions>
        </v-card>
      </v-card>
    </v-dialog>
  </v-container>
</template>
<script>
import axios from 'axios'
import { appConfig } from '@/store/helpers.js'
import util from '@/mixins/util.js'
import sounds from '@/json/sounds.json'
import moment from 'moment/src/moment'
export default {
  mixins: [util],
  data: () => ({
    sounds: sounds,
    interval: {},
    mode: '',
    loaded: false,
    autoSave: true,
    completed: false,
    value: 5,
    minutes: 0,
    countdown: 5,
    circleSize: 150,
    circleWidth: 5,
    valueMinutes: '00',
    valueSeconds: '00',
    elapsedMinutes: 0,
    elapsedSeconds: 0,
    elapsedMinutesStr: '00',
    elapsedSecondsStr: '00',
    currentSet: 0,
    currentRound: 1,
    totalReps: 0,
    dialog: false,
    prestart: true,
    zIndex: 0,
    workout: null,
    exercises: [],
    sets: [],
    starttime: '',
    startTimeMoment: null,
    endtime: '',
    snackbar: false,
    snackText: '',
    timeout: 2000,
    tick: 1000,
    switchNum: 0,
    sound: false,
    metric: false,
    activityid: 38, //circuit training, moderate
    calories: 4.3,
    audio: null,
    beep: null,
    go: null,
    videoLoop: null,
    imageLogo: null
  }),
  props: {
    workoutid: {
      type: String
    }
  },
  beforeMount() {
    this.videoLoop = this.isPhone
      ? require(`@/assets/LoopSmall.mp4`)
      : require(`@/assets/Loop.mp4`)
    this.loadWorkout(this.workoutid)
    this.audio = new Audio(this.sounds['5secs'])
    this.beep = new Audio(this.sounds['ready'])
    this.go = new Audio(this.sounds['go'])
    this.audio.preload = 'auto'
    this.beep.preload = 'auto'
    this.go.preload = 'auto'
    this.imageLogo = require('@/assets/Logo100.png')
  },
  beforeUpdate() {
    this.circleSize = this.isPhone ? 200 : 350
    this.circleWidth = this.isPhone ? 5 : 20
  },
  beforeDestroy() {
    clearInterval(this.interval)
    this.screenOff()
  },
  watch: {
    dialog: function(value) {
      if (value) {
        this.screenOn()
      } else this.screenOff()
    }
  },
  computed: {
    progress() {
      let progress = 100 * ((this.currentRound - 1) / this.workout.rounds)
      return Math.ceil(progress)
    },
    lastFinishedRound() {
      return this.completed ? this.currentSet : this.currentSet - 1
    },
    ...appConfig
  },
  methods: {
    nextRound() {
      this.workout.rounds++
      this.currentRound++
      let cycle = this.exercises.length
      let setnum = this.sets.length
      for (let i = 0; i < cycle; i++) {
        this.sets.push(this.sets[setnum - cycle + i])
      }
      this.calculateTotalVolume()
    },
    elapsed() {
      this.elapsedSeconds = moment().diff(this.startTimeMoment, 'seconds')
      this.elapsedMinutes = moment().diff(this.startTimeMoment, 'minutes')

      this.minutes =
        this.elapsedMinutes + this.twoDigits((this.elapsedSeconds % 60) / 60)

      let justSeconds = this.elapsedSeconds % 60

      this.elapsedSecondsStr = Number.parseInt(Math.floor(justSeconds))
        .toString()
        .padStart(2, '0')

      this.elapsedMinutesStr = Number.parseInt(Math.floor(this.elapsedMinutes))
        .toString()
        .padStart(2, '0')

      this.valueMinutes = Number.parseInt(Math.floor(this.value / 60))
        .toString()
        .padStart(2, '0')
      this.valueSeconds = Number.parseInt(Math.ceil(this.value % 60))
        .toString()
        .padStart(2, '0')
    },
    calculateTotalVolume() {
      let total = 0
      let totalImp = 0
      let totalReps = 0
      this.sets.forEach(el => {
        total = parseFloat(el.volume_metric) + total
        totalImp = parseFloat(el.volume_imperial) + totalImp
        totalReps = parseInt(el.reps) + totalReps
      })
      this.totalMetric = this.twoDigits(total)
      this.totalImperial = this.twoDigits(totalImp)
      this.totalReps = totalReps
    },
    calculateVolume(set) {
      set.volume_metric = set.reps * set.metric
      set.volume_metric = this.twoDigits(set.volume_metric)
      set.volume_imperial = set.reps * set.imperial
      set.volume_imperial = this.twoDigits(set.volume_imperial)
      this.calculateTotalVolume()
      this.$forceUpdate()
    },
    calculateImperial(set) {
      set.imperial = set.metric * (1 / this.factor)
      set.imperial = this.roundFive(this.twoDigits(set.imperial))
      this.calculateVolume(set)
    },
    calculateMetric(set) {
      set.metric = set.imperial * this.factor
      set.metric = this.roundFive(this.twoDigits(set.metric))
      this.calculateVolume(set)
    },
    reset() {
      this.totalReps = 0
      this.repsAverage = 0
      this.repsRemaining = 0
      this.currentSet = 0
      this.currentRound = 1
      this.prestart = true
      this.value = 0
      this.minutes = 0
      this.valueMinutes = '00'
      this.valueSeconds = '00'
      this.elapsedMinutes = 0
      this.elapsedSeconds = 0
      this.elapsedMinutesStr = '00'
      this.elapsedSecondsStr = '00'
      this.countdown = 5
      this.mode = null
      clearInterval(this.interval)
      this.sound = false
      this.switchNum++
      this.screenOff()
    },
    done() {
      this.reset()
      this.dialog = false
    },
    cancel() {
      this.reset()
      this.dialog = false
    },
    playStart() {
      if (this.isNativeApp() && !this.isAndroid()) this.playStartNative()
      else this.go.play()
    },
    playEnd() {
      if (this.isNativeApp() && !this.isAndroid()) this.playEndNative()
      else this.audio.play()
    },
    playDing() {
      if (this.isNativeApp() && !this.isAndroid()) this.playDingNative()
      else this.beep.play()
    },
    startWorkout() {
      this.dialog = true
      this.completed = false
      this.value = this.countdown
      this.starttime = this.now()

      if (this.prestart) {
        this.interval = setInterval(() => {
          if (this.value == this.countdown - 3) {
            this.playStart()
          }
          if (this.value == 0) {
            this.starttime = this.now()
            this.startTimeMoment = moment()
            this.prestart = false
            clearInterval(this.interval)
            this.startActive()
            return true
          }
          this.value -= 1
        }, this.tick)
      }
    },
    startActive() {
      this.mode = 'active'
      this.screenOn()
      let activeStart = moment()
      let active = this.workout.total_time * 60
      let activeDuration = active

      this.value = active
      this.currentSet++

      this.interval = setInterval(() => {
        if (this.elapsedSeconds % 30 == 0) {
          this.playDing()
        }
        if (active == this.soundCutoff - 1) {
          this.playEnd()
        }
        active = activeDuration - moment().diff(activeStart, 'seconds')
        this.elapsed()

        this.value = active
        if (this.value <= 0) {
          this.completed = true
          this.screenOff()
          this.mode = null
          return true
        }
      }, this.tick)
    },

    loadWorkout(workoutid) {
      {
        return axios
          .get(this.baseURL + '/workout/' + workoutid, {})
          .then(response => {
            if (response.status == 200) {
              this.workout = response.data.data
              this.exercises = JSON.parse(this.workout.exercises)
              let cycle = this.exercises.length
              let totalrounds = this.workout.rounds
              let sets = totalrounds * cycle

              for (let i = 0; i < sets; ++i) {
                let index = parseInt(i % cycle)
                this.sets[i] = {
                  round: parseInt(i / cycle) + 1,
                  exercise_index: index,
                  exercise_name: this.exercises[index].name,
                  resistance: this.exercises[index].resistance,
                  exercise_id: this.exercises[index].id,
                  reps: this.exercises[index].reps,
                  imperial: 0,
                  metric: 0,
                  volume_imperial: 0,
                  volume_metric: 0
                }
              }
              this.initializeValues()
              this.loaded = true
            } else {
              this.$store.dispatch(
                'notification/addErrors',
                response.data.errors,
                5000,
                true,
                {
                  root: true
                }
              )
              return
            }
          })
      }
    },
    initializeValues() {
      let total = 0
      let totalImp = 0
      let totalReps = 0
      let cycle = this.exercises.length
      let index = 0
      let i = 0

      this.sets.forEach(set => {
        index = parseInt(i % cycle)
        if (this.useMetric) {
          set.metric =
            this.sex == 'Male'
              ? this.exercises[index].weight
              : this.exercises[index].weightf
          set.imperial = set.metric * (1 / this.factor)
          set.imperial = this.roundFive(this.twoDigits(set.imperial))
        } else {
          set.imperial =
            this.sex == 'Male'
              ? this.exercises[index].weight
              : this.exercises[index].weightf
          set.metric = set.imperial * this.factor
          set.metric = this.roundFive(this.twoDigits(set.metric))
        }
        set.volume_metric = set.reps * set.metric
        set.volume_metric = this.twoDigits(set.volume_metric)
        set.volume_imperial = set.reps * set.imperial
        set.volume_imperial = this.twoDigits(set.volume_imperial)
        total = parseFloat(set.volume_metric) + total
        totalImp = parseFloat(set.volume_imperial) + totalImp
        totalReps = parseInt(set.reps) + totalReps
        ++i
      })

      this.calculateTotalVolume()
      let minutesInt = parseInt(this.workout.active_time_total / 60)
      let minutesFrac = this.twoDigits(
        (this.workout.active_time_total % 60) / 60
      )
      this.minutes = minutesInt + minutesFrac
    },
    save() {
      return axios
        .post(this.baseURL + '/workout/savewod', {
          active: 0,
          rest_set: 0,
          rest_exercise: 0,
          total_active: this.minutes,
          total: this.minutes,
          total_imperial: this.totalImperial,
          total_metric: this.totalMetric,
          total_rounds: this.workout.rounds,
          start_time: this.starttime,
          now: this.now(),
          rounds: this.sets,
          exercises: this.exercises,
          workoutid: this.workout.workoutid
        })
        .then(response => {
          if (response.status == 200) {
            if (this.autoSave) this.saveActivity()
            this.done()
            this.$router.push({
              path: '/'
            })
            this.toast('Workout was saved..')
          } else {
            this.$store.dispatch(
              'notification/addErrors',
              response.data.errors,
              5000,
              true,
              {
                root: true
              }
            )
            return
          }
        })
    },
    saveActivity() {
      let duration = this.minutes
      let totalCalories = this.twoDigits(this.calories * duration)
      return axios
        .post(this.baseURL + '/activities', {
          duration: duration,
          calories: this.calories,
          totalCalories: totalCalories,
          start_time: this.starttime,
          distance_imperial: 0,
          distance_metric: 0,
          activityid: this.activityid
        })
        .then(response => {
          if (response.status == 200) {
            this.done()
            this.$router.push({
              path: '/myworkouts'
            })
          } else {
            this.$store.dispatch(
              'notification/addErrors',
              response.data.errors,
              5000,
              true,
              {
                root: true
              }
            )
            return
          }
        })
    }
  }
}
</script>
