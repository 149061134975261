<template>
  <v-container>
    <v-dialog
      :fullscreen="isPhone"
      width="900"
      overlay-opacity=".95"
      v-model="showReorder"
      persistent
    >
      <ReorderList
        :items="exercisesList"
        title="Re-order Exercises"
        name="exercises"
        @clickedDone="sort()"
        @clickedCancel="sort()"
      />
    </v-dialog>
    <v-dialog
      v-model="showSuccess"
      :fullscreen="isPhone"
      persistent
      width="800"
      overlay-opacity=".95"
    >
      <v-card
        min-height="400"
        align="stretch"
        elevation="20"
        class="d-flex flex-column"
        height="100%"
      >
        <v-sheet color="paper" class="charcoal--text">
          <v-card-title class="title">
            Workout Added Succesfully!
          </v-card-title>
        </v-sheet>
        <v-card-subtitle class="body-1 px-2 py-4">
          Workout "{{ oldname }}"" added succesfully. What do you want to do
          now?
        </v-card-subtitle>
        <v-spacer />
        <v-card-actions class="px-0 lightHeader">
          <BaseActionButton
            text
            color="info"
            icon="mdi-eye"
            title="View Workouts"
            :label="isPhone ? 'View' : 'View Workouts'"
            @clickedThis="goToWorkouts"
          />
          <BaseActionButton
            text
            title="Add More"
            icon="mdi-plus-circle-outline"
            label="Add More"
            @clickedThis="addMore"
          />
          <v-spacer />
          <BaseActionButton
            text
            color="success"
            icon="mdi-play-box-outline"
            title="Start Workout"
            :label="isPhone ? 'Start' : 'Start Workout'"
            @clickedThis="startWorkout"
          />
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-form ref="WorkoutForm" name="WorkoutForm">
      <BaseScreenHeader title="Workout Builder" screenInfo="WRK-001" />

      <v-card-actions class="pa-0">
        <h5 class="silver--text">Workout Type</h5>
        <v-spacer />
      </v-card-actions>
      <v-sheet class="transparent charcoalTile">
        <v-card-actions class="pa-0 mb-2">
          <v-spacer />
          <BaseActionButton
            @clickedThis="changeWorkoutType('Workout')"
            dark
            :color="type == 'Workout' ? ' progressActive' : ' paper'"
            label="Lifting or Circuit"
            title="Lifting or Circuit"
            :large="false"
            plain
            icon=""
            class="ml-n2"
          />
          <BaseActionButton
            @clickedThis="changeWorkoutType('WOD')"
            :color="type == 'WOD' ? 'progressActive' : 'paper'"
            label="Hybrid"
            text
            :large="false"
            title="Hybrid, Cross-Training or WOD Style Workout"
            icon=""
          />
          <v-spacer />
        </v-card-actions>
        <v-card-text
          :class="textSizeXSmall + ' silver--text px-0 text-center pt-0'"
        >
          {{ workoutTypeDescription }}
        </v-card-text>
      </v-sheet>
      <v-card-text class="pa-0">
        <v-sheet color="transparent silver--text">
          <v-card-actions :class="textSizeSmall">
            Workout Details
            <v-spacer />
            <BaseActionButton
              class="charcoal paper--text"
              :large="!isPhone"
              dark
              :disabled="$v.$anyError || exercisesListSize == 0"
              @clickedThis="saveWorkout"
            />
          </v-card-actions>
        </v-sheet>
        <v-row>
          <template>
            <v-col cols="12" md="6">
              <v-text-field
                dark
                filled
                v-model="name"
                ref="name"
                :error-messages="nameErrors"
                :counter="50"
                label="Name"
                prepend-inner-icon="mdi-dumbbell"
                @input="$v.name.$touch()"
                @blur="$v.name.$touch()"
              />
            </v-col>
            <v-col cols="12" md="6">
              <v-textarea
                dark
                filled
                v-model="description"
                ref="description"
                :counter="200"
                :rows="3"
                label="Description"
                prepend-inner-icon="mdi-text"
                @input="$v.description.$touch()"
                @blur="$v.description.$touch()"
              />
            </v-col>
          </template>

          <v-col cols="12">
            <v-sheet rounded color="transparent paper--text">
              <v-card-actions :class="textSizeSmall">
                Additional Details
              </v-card-actions>
            </v-sheet>
          </v-col>
          <v-sheet rounded color="transparent charcoalTile mx-2 paper--text">
            <v-card-text class="text-center pt-0">
              <v-row dense>
                <v-layout row justify-center>
                  <div class="silver--text caption my-5">
                    {{ xmodeText }}
                  </div>
                </v-layout>
                <v-col cols="12">
                  <BaseActionButton
                    :color="xmode == 'emom' ? 'progressActive' : 'paper'"
                    label="EMOM"
                    :large="!isPhone"
                    title="Every Minute on The Minute (EMOM)"
                    icon="mdi-clock-in"
                    plain
                    class="px-0"
                    @clickedThis="emomMode"
                  />
                  <BaseActionButton
                    :color="xmode == 'time' ? 'progressActive' : 'paper'"
                    label="RFT"
                    :large="!isPhone"
                    title="Rounds For Time (RFT)"
                    icon="mdi-clock-check-outline"
                    plain
                    class="px-0 ml-2"
                    @clickedThis="timeMode"
                  />
                  <BaseActionButton
                    :color="xmode == 'amrap' ? 'progressActive' : 'paper'"
                    label="AMRAP"
                    :large="!isPhone"
                    title="As Many Reps As Possible (AMRAP)"
                    icon="mdi-update"
                    plain
                    class="px-0 ml-2"
                    @clickedThis="amrapMode"
                  />
                </v-col>
              </v-row>
            </v-card-text>
            <v-row dense v-if="xmode == 'emom'">
              <v-spacer />
              <v-col cols="6" md="3">
                <v-text-field
                  dark
                  filled
                  class="enlarged-input-small"
                  v-model="workout.minutes"
                  ref="activeMinutes"
                  :error-messages="activeErrorsMinutes"
                  :counter="5"
                  label="Active (minutes)"
                  prepend-inner-icon="mdi-clock-in"
                  @input="minutesChanged"
                />
              </v-col>
              <v-col cols="6" md="3">
                <v-text-field
                  dark
                  filled
                  class="enlarged-input-small"
                  v-model="workout.seconds"
                  ref="activeSeconds"
                  :error-messages="activeErrorsSeconds"
                  :counter="5"
                  label="(seconds)"
                  prepend-inner-icon="mdi-clock-in"
                  @input="secondsChanged"
                />
              </v-col>
              <v-col cols="6" md="3">
                <v-text-field
                  dark
                  filled
                  class="enlarged-input-small"
                  v-model="workout.rest_time_exercise"
                  ref="rest"
                  :error-messages="rest_time_exerciseErrors"
                  :counter="5"
                  label="Rest (seconds)"
                  prepend-inner-icon="mdi-clock-out"
                  @input="restTimeChanged"
                />
              </v-col>
              <v-col cols="6" md="3">
                <v-text-field
                  dark
                  filled
                  class="enlarged-input-small"
                  v-model="workout.rounds"
                  ref="rounds"
                  :counter="2"
                  :error-messages="wodRoundsErrors"
                  label="Rounds"
                  type="number"
                  prepend-inner-icon="mdi-circle-double"
                  @input="roundsChanged"
                />
              </v-col>
            </v-row>
            <v-row dense justify="center" v-if="xmode == 'amrap'">
              <v-col cols="6">
                <v-text-field
                  dark
                  filled
                  class="enlarged-input-small"
                  v-model="workout.minutes"
                  ref="activeMinutes"
                  :error-messages="activeErrorsMinutes"
                  :counter="2"
                  label="Total Time (minutes)"
                  prepend-inner-icon="mdi-clock-in"
                  @input="minutesChanged"
                />
              </v-col>
            </v-row>
            <v-row dense justify="center" v-if="xmode == 'time'">
              <v-col cols="6">
                <v-text-field
                  dark
                  filled
                  class="enlarged-input-small"
                  v-model="workout.rounds"
                  ref="rounds"
                  :counter="2"
                  :error-messages="wodRoundsErrors"
                  label="Rounds"
                  type="number"
                  prepend-inner-icon="mdi-circle-double"
                  @input="roundsChanged"
                />
              </v-col>
            </v-row>
          </v-sheet>
          <template v-if="exercisesList.length > 0">
            <v-col cols="12">
              <v-card-subtitle
                :class="textSizeXSmall + ' silver--text pb-0 px-0'"
              >
                Click on each exercise below to adjust the number of sets, reps,
                and load. You can remove an exercise by clicking on the little
                <span class="progressActive--text">x </span> in a white circle.
              </v-card-subtitle>
            </v-col>
            <v-col cols="12">
              <span :class="textSizeSmall + ' py-2  px-0 silver--text'">
                Selected Exercises
              </span>
              <v-badge
                :content="exercisesList.length"
                :value="exercisesList.length"
                color="transparent silver--text"
                overlap
              >
                <v-icon color="silver" large>
                  mdi-checkbox-multiple-marked-circle
                </v-icon>
              </v-badge>
              <v-btn
                text
                v-if="exercisesList.length > 0"
                class="ml-4 progressActive--text"
                @click="showReorder = true"
              >
                Re-order
              </v-btn>
            </v-col>
          </template>
          <v-card-text class="pt-0">
            <ExerciseChip
              :type="type"
              :repSchemes="repSchemes"
              @remove="removeExercise"
              @done="saveExerciseUpdates"
            />
          </v-card-text>
        </v-row>
        <ExercisesList :type="type" class="pt-4" />
      </v-card-text>
    </v-form>
  </v-container>
</template>
<script>
import { validationMixin } from 'vuelidate'
import { required, between } from 'vuelidate/lib/validators'
import { appConfig, builder } from '@/store/helpers.js'
import axios from 'axios'
import { mapActions } from 'vuex'
import util from '@/mixins/util.js'
const ExerciseChip = () =>
  import(/* webpackPrefetch: true */ '@/components/pageutil/ExerciseChip.vue')
const ReorderList = () =>
  import(/* webpackPrefetch: true */ '@/components/pageutil/ReorderList.vue')
const ExercisesList = () =>
  import(/* webpackPrefetch: true */ '@/components/pageutil/ExercisesList.vue')
export default {
  components: {
    ExerciseChip,
    ReorderList,
    ExercisesList
  },
  name: 'AddWODWorkout',
  mixins: [validationMixin, util],
  data: () => ({
    byTime: true,
    search: '',
    active: 0,
    rest: 0,
    restExercise: 0,
    total: 0,
    selectedScheme: {},
    workout: {},
    initial: {
      minutes: 1,
      seconds: 0,
      active_time: 60,
      active_time_total: 60,
      rest_time_exercise: 10,
      rest_time_set: 10,
      total_time: 70,
      rounds: 1
    },
    repschemeid: 'hzWVL',
    type: 'WOD',
    xmode: 'emom',
    rounds: 1,
    filter: {},
    sortDesc: false,
    loaded: false,
    switchNum: 0,
    reOrderIndex: 0,
    sortBy: 'name',
    typeText: 'WOD',
    workoutTypeDescription:
      'Cross-training type of workout, similar with a Crossfit WOD.',
    keys: ['Name'],
    exercises: [],
    deletedExercises: [],
    repSchemes: [],
    selectedForEdit: {},
    mechanicsType: '',
    utilityType: '',
    exercisesType: '',
    name: '',
    oldname: '',
    description: 'NA',
    glossary: {},
    showSuccess: false,
    showAddExercise: false,
    showReorder: false
  }),
  beforeMount() {
    this.workout = this.initial
    this.loadRepschemes()
    this.loadGlosary()
  },
  mounted() {
    this.type = 'WOD'
  },
  validations: {
    name: {
      required
    },
    description: {
      required
    },
    rounds: {
      required,
      between: between(1, 99)
    },
    active: {
      between: between(1, 600)
    },
    rest: {
      between: between(1, 600)
    },
    restExercise: {
      between: between(1, 600)
    },
    total: {
      between: between(0, 60)
    },

    workout: {
      rounds: {
        required,
        between: between(1, 99)
      },
      active_time_total: {
        between: between(1, 600)
      },
      active_time_seconds: {
        between: between(0, 60)
      },
      active_time_minutes: {
        between: between(0, 99)
      },
      rest_time_exercise: {
        between: between(0, 600)
      },
      rest_time_set: {
        between: between(0, 600)
      },
      total_time: {
        between: between(0, 60)
      }
    }
  },
  computed: {
    ...appConfig,
    ...builder,
    xmodeText() {
      if (this.xmode == 'emom') return 'Every Minute On The Minute (EMOM)'
      return this.xmode == 'time'
        ? 'Rounds For Time (RFT)'
        : 'As Many Rounds As Possible (AMRAP)'
    },
    nameErrors() {
      const errors = []
      if (!this.$v.name.$dirty) return errors
      !this.$v.name.required && errors.push('Workout name is required.')
      return errors
    },
    activeErrors() {
      const errors = []
      if (!this.$v.active.$dirty) return errors
      !this.$v.active.between &&
        errors.push(
          'The number of active seconds is invalid. Expected (1 to 600)'
        )
      return errors
    },
    activeErrorsMinutes() {
      const errors = []
      if (!this.$v.workout.active_time_minutes.$dirty) return errors
      !this.$v.workout.active_time_minutes.between &&
        errors.push(
          'The number of active minutes is invalid. Expected (1 to 99)'
        )
      return errors
    },
    activeErrorsSeconds() {
      const errors = []
      if (!this.$v.workout.active_time_seconds.$dirty) return errors
      !this.$v.workout.active_time_seconds.between &&
        errors.push(
          'The number of active seconds is invalid. Expected (1 to 600)'
        )
      return errors
    },
    rest_time_exerciseErrors() {
      const errors = []
      if (!this.$v.workout.rest_time_exercise.$dirty) return errors
      !this.$v.workout.rest_time_exercise.between &&
        errors.push(
          'The number of resting seconds between sets is invalid. Expected (1 to 600)'
        )
      return errors
    },
    wodRoundsErrors() {
      const errors = []
      if (!this.$v.workout.rounds.$dirty) return errors
      !this.$v.workout.rounds.required &&
        errors.push('The number of rounds  is required.')
      !this.$v.workout.rounds.between &&
        errors.push('The number of rounds is invalid.')
      return errors
    },
    itemsCount() {
      return this.exercises.length
    }
  },
  methods: {
    goToWorkouts() {
      this.$router.push('/myworkouts')
      this.showSuccess = false
    },
    roundsChanged() {
      this.$v.workout.rounds.$touch()
      this.calculateWodTimes()
    },
    restTimeChanged() {
      this.$v.workout.rest_time_exercise.$touch()
      this.calculateWodTimes()
    },
    secondsChanged() {
      this.$v.workout.active_time_seconds.$touch()
      this.calculateTimes()
    },
    minutesChanged() {
      this.$v.workout.active_time_minutes.$touch()
      this.calculateWODTimes()
    },
    emomMode() {
      this.reset()
      this.xmode = 'emom'
    },
    timeMode() {
      this.reset()
      this.xmode = 'time'
    },
    amrapMode() {
      this.reset()
      this.xmode = 'amrap'
    },
    goToWorkout() {
      this.$router.push('/myworkouts')
      this.showSuccess = false
    },
    viewExerciseDetails(exerciseid) {
      this.$router.push({
        name: 'viewexercise',
        params: { exerciseid: exerciseid }
      })
    },
    reset() {
      this.xmode = 'emom'
      this.workout = { ...this.total_time }
      this.workout.minutes = 0
      this.workout.seconds = 0
      this.workout = this.initial
      this.rounds = 0
      this.$v.$reset()
      this.switchNum++
    },
    chipDetails(sel) {
      let weightm = this.isEmpty(sel.weight) ? 'NA' : sel.weight
      let weightf = this.isEmpty(sel.weightf) ? 'NA' : sel.weightf
      let ret = 'Reps: ' + sel.reps + ' @ ' + weightm + ' / ' + weightf
      return ret
    },
    setRepScheme(exercise) {
      exercise.repschemeid = 1
    },
    setReps() {
      let scheme = this.repSchemes.filter(item => {
        return item.id == this.repschemeid
      })
      this.selectedScheme = scheme[0]
    },
    calculateTimes() {
      let roundSeconds =
        Number.parseInt(this.active) + Number.parseInt(this.rest)
      let totalSeconds = 0

      if (this.byTime) {
        totalSeconds = this.total * 60
        this.rounds = Math.ceil(totalSeconds / roundSeconds)
      } else {
        totalSeconds = this.rounds * roundSeconds
        this.total = Math.ceil(totalSeconds / 60)

        this.rounds = Math.ceil(totalSeconds / roundSeconds)
      }
    },
    calculateWodTimes() {
      this.workout.active_time =
        parseInt(this.workout.minutes * 60) + parseInt(this.workout.seconds)
      this.workout.active_time_total =
        this.workout.active_time * this.workout.rounds

      if (this.xmode != 'amrap') {
        this.total = this.workout.minutes * 60
        this.active = this.total
        this.rounds = 1
        this.rest = 0
        this.restExercise = 0
      }
      if (this.xmode == 'time') {
        this.total = 0
        this.active = 0
        this.rest = 0
        this.restExercise = 0
      }
    },
    changeWorkoutType(type) {
      this.type = type
      if (type == 'WOD') {
        return
      } else {
        this.$router.push({
          path: '/workouts/add'
        })
      }
    },
    startWorkout() {
      this.$router.push({
        path: '/workouts/start/' + this.workoutid
      })
    },
    sort() {
      this.exercisesSort()
      this.showReorder = false
      this.$forceUpdate()
      this.reOrderIndex++
    },
    saveExerciseUpdates() {
      const asArray = []
      asArray.push(this.selectedForEdit)
      this.exercises = this.exercises.map(
        obj => asArray.find(o => o.id === obj.id) || obj
      )
    },
    removeExercise(exercise) {
      this.exerciseRemove(exercise)
    },
    selectExercise(exercise) {
      let ex = {
        ...exercise
      }
      Object.assign(ex, exercise)
      ex.workoutType = this.type
      ex.sort = this.exercisesListSize + 1
      ex.sets = 1
      this.exerciseAdd(ex)
    },
    addMore() {
      this.showSuccess = false
      this.$v.$reset()
    },
    setexercises(name) {
      this.exercisesType = name
    },
    saveWorkout() {
      window.scrollTo(0, 0)
      return axios
        .post(this.baseURL + '/workouts', {
          workout: this.workout,
          name: this.name,
          description: this.description,
          repschemeid: this.repschemeid,
          exercises: this.exercisesList,
          now: this.now(),
          adhoc: 'No',
          active: this.active,
          activeTotal: this.active * this.rounds,
          rest: this.rest,
          restExercise: this.restExercise,
          total: this.total,
          workoutType: this.type,
          xmode: this.xmode
        })
        .then(response => {
          if (response.status == 200) {
            this.workoutid = response.data.data['workoutid']
            this.oldname = this.name
            this.showSuccess = true
            this.$refs.WorkoutForm.reset()
            this.name = ''
            this.description = ''
            this.rounds = ''
            this.exercisesClear()
            this.$store.dispatch(
              'notification/addNotification',
              'Workout added succesfully.',
              2000,
              true,
              {
                root: true
              }
            )
          } else {
            this.$store.dispatch(
              'notification/addErrors',
              response.data.errors,
              5000,
              true,
              {
                root: true
              }
            )
            return
          }
        })
    },
    loadGlosary() {
      return axios
        .get(this.baseURL + '/util/glossary/all', {})
        .then(response => {
          if (response.status == 200) {
            this.glossary = response.data.data

            this.forces = this.glossary.filter(function(item) {
              return item.category == 'Force'
            })
            this.mechanics = this.glossary.filter(function(item) {
              return item.category == 'Mechanics'
            })
            this.utility = this.glossary.filter(function(item) {
              return item.category == 'Utility'
            })
            this.dificultyList = this.glossary.filter(function(item) {
              return item.category == 'Dificulty'
            })
          } else {
            this.$store.dispatch(
              'notification/addErrors',
              response.data.errors,
              5000,
              true,
              {
                root: true
              }
            )
            return
          }
        })
    },
    loadRepschemes() {
      return axios
        .get(this.baseURL + '/tools/repschemes/all', {})
        .then(response => {
          if (response.status == 200) {
            this.repSchemes = response.data.data
            let scheme = this.repSchemes.filter(item => {
              return item.id == this.repschemeid
            })
            this.selectedScheme = scheme[0]
          } else {
            this.$store.dispatch(
              'notification/addErrors',
              response.data.errors,
              5000,
              true,
              {
                root: true
              }
            )
            return
          }
        })
    },
    ...mapActions('builder', [
      'exerciseAdd',
      'exerciseRemove',
      'exerciseRemoveLast',
      'exercisesClear',
      'exercisesSort'
    ])
  }
}
</script>
