<template>
  <v-container>
    <BaseScreenHeader title="Start EMOM Workout" screenInfo="WRK-005" />

    <BaseLoading
      v-if="!loaded"
      class="my-2"
      label="Loading Workout Information"
    />
    <template v-else>
      <v-card-actions
        :class="textSize + ' paper--text text-uppercase px-0 pb-0'"
      >
        <v-divider class="transparent" />
        <span class="pl-2">{{ workout.name }}</span>
        <v-divider class="transparent" />
      </v-card-actions>
      <v-card-actions :class="textSizeXSmall + ' paper--text pt-0 pl-2'">
        <v-divider class="charcoal lighten-1" />
        <span class="mx-2">
          Every Minute On The Minute (EMOM)
        </span>
        <v-divider class="charcoal lighten-1" />
      </v-card-actions>
      <v-sheet class="charcoal silver--text text-center py-2">
        <v-card-actions
          v-if="workout.description != 'NA'"
          :class="textSizeXSmall + ' py-0 pl-2'"
        >
          <v-spacer>
            <span>{{ workout.description }}</span>
          </v-spacer>
        </v-card-actions>
      </v-sheet>
      <v-sheet
        rounded
        class="rounded-lg charcoal charcoalTileMenu silver--text py-2"
      >
        <v-card-actions :class="textSizeXSmall + ' silver--text py-0'">
          <v-divider class="transparent" />
          <span :class="textSizeXLarge + ' progressActive--text mx-1'">
            {{ workout.total_time / 60 }}
          </span>
          minutes
          <span :class="textSizeXLarge + ' progressActive--text mr-1'">
            {{ workout.rounds }}
          </span>
          <span>rounds</span>
          <span :class="textSizeXLarge + ' progressActive--text mx-1'">
            {{ exercises.length }}
          </span>
          <span>exercises</span>
          <v-divider class="transparent" />
        </v-card-actions>
        <v-sheet rounded class="silver--text pa-2 transparent text-right">
          <span :class="textSize">
            {{ workout.roundTime }} Seconds / Round
          </span>
          <v-progress-linear
            class="mt-1"
            height="25"
            rounded
            background-color="charcoal lighten-1"
            color="success"
            :value="workout.percentage"
          >
            <template v-slot:default="{ value }">
              <span class="paper--text">
                {{ parseInt(workout.active_time) }} seconds active. {{ value }}%
              </span>
            </template>
          </v-progress-linear>
          <v-progress-linear
            class="mt-2"
            height="25"
            rounded
            background-color="charcoal lighten-1"
            color="gold"
            :value="100 - workout.percentage"
          >
            <template v-slot:default="{ value }">
              <span class="paper--text">
                {{ parseInt(workout.rest_time_set) }} seconds rest. {{ value }}%
              </span>
            </template>
          </v-progress-linear>
        </v-sheet>

        <v-row dense justify="center" class="pt-4" align="center">
          <v-btn
            fab
            x-large
            elevation="2"
            label="Start"
            title="Start Workout"
            @click="startWorkout"
            class="paper charcoal--text link"
          >
            <v-icon x-large>
              mdi-play-box
            </v-icon>
          </v-btn>
        </v-row>
        <v-row
          v-for="exercise in exercises"
          :key="exercise.id"
          class="py-2 ml-2"
          dense
        >
          <span class="silver--text">
            <BaseIconNumber color="progressActive" :value="exercise.sort" />
            <span :class="textSizeSmall">
              {{ exercise.name }}
            </span>
          </span>
        </v-row>
      </v-sheet>
    </template>
    <v-dialog
      :z-index="zIndex"
      :value="dialog"
      fullscreen
      persistent
      scollable
      :key="switchNum"
    >
      <v-card class="d-flex flex-column noscroll" tile color="charcoal">
        <BaseCloseDialogHeader heading="EMOM" @cancel="cancel" />
        <v-card-actions class="py-0 mt-n2">
          <v-img
            :src="imageLogo"
            contain
            title="Logo"
            :height="isPhone ? 40 : 60"
          />
        </v-card-actions>
        <v-sheet rounded class="pa-2 pt-0 mt-n10 charcoal">
          <video-background
            :src="videoLoop"
            style="max-height: 80px; height: 50vh;opacity:0.25"
            overlay="linear-gradient(45deg,#2a4ae430,#fb949e6b)"
          >
          </video-background>
        </v-sheet>
        <template v-if="loaded">
          <v-sheet color="charcoal" class="mt-n8 progressActive--text">
            <v-card-actions class="pa-0 mt-n5">
              <v-divider class="charcoal lighten-1" />
              <span v-if="loaded" class="mx-2 py-1 silver--text">
                {{ workout.name }}
              </span>
              <v-divider class="charcoal lighten-1" />
            </v-card-actions>
            <template v-if="!completed">
              <v-card-text
                v-if="!prestart"
                :class="textSize + ' pt-0 mt-n2 text-center'"
              >
                ROUND: {{ currentRound }} of {{ workout.rounds }}
                <v-row justify="center" class="py-1">
                  <BaseIconCounter
                    :rounds="workout.rounds"
                    :current="currentRound"
                    :dark="true"
                  />
                </v-row>
              </v-card-text>
              <v-card-text v-else :class="textSize + ' mt-n4 text-center'">
                GET READY!
              </v-card-text>
            </template>
            <template v-else>
              <v-card-text :class="textSize + ' text-center'">
                FINISHED!
              </v-card-text>
            </template>
          </v-sheet>
          <v-progress-linear
            color="progressActive"
            v-model="progress"
            height="2"
          >
          </v-progress-linear>
          <v-card-actions class="pa-0 pr-2 my-n2">
            <v-spacer />
            <v-switch
              dark
              small
              color="progressActive"
              label="Add to Activity Log?"
              v-model="autoSave"
            />
          </v-card-actions>
          <v-card-actions
            v-if="
              currentRound > 0 && currentRound <= workout.rounds && !completed
            "
            :class="
              textSizeSmall + ' silver--text text-center text-uppercase py-0'
            "
          >
            <v-divider />
            {{ rounds[currentRound - 1].exercise_name }}
            <v-divider />
          </v-card-actions>
          <v-card-actions
            :class="
              textSizeSmall + ' silver--text text-center text-uppercase py-0'
            "
            v-else
          >
            <v-divider />
            <template v-if="!completed">
              Next: {{ rounds[0].exercise_name }}
            </template>
            <v-divider />
          </v-card-actions>
          <template v-if="completed">
            <v-card-actions class="paper--text">
              <v-spacer />
              <span
                :class="
                  textSizeSmall + '  text-uppercase text-center mx-2 py-0'
                "
              >
                Reps Completed: {{ totalReps }}
              </span>
              <v-spacer />
            </v-card-actions>
            <v-card-actions
              :class="
                textSizeSmall +
                  '  silver--text text-uppercase text-center mx-2 pt-0'
              "
            >
              <v-spacer />
              <span>
                Volume: {{ prettyNumber(totalImperial) }} lbs /
                {{ prettyNumber(totalMetric) }} kg
              </span>
              <v-spacer />
            </v-card-actions>
          </template>
        </template>
        <v-card-text v-if="!completed" class="clock pt-2 pb-1 text-center">
          <v-progress-circular
            v-if="prestart"
            :rotate="-90"
            :size="circleSize"
            :width="circleWidth"
            :value="100 - value * 20"
            color="paper"
          >
            <span :class="textSizeXLarge">
              {{ value > 0 ? value : 'GO' }}
            </span>
          </v-progress-circular>
          <v-progress-circular
            v-if="!prestart"
            :rotate="-90"
            :size="circleSize"
            :width="circleWidth"
            :value="mode == 'active' ? percentageActive : percentageRest"
            :color="getColor()"
          >
            <v-row dense>
              <v-col cols="12" class="pa-0 ma-0 mt-" justify="center">
                <v-card-text :class="textSizeXLarge + ' mt-7'">
                  {{ value }}
                </v-card-text>
              </v-col>
              <v-col cols="12" class="pa-0 ma-0 mt-n10">
                <template v-if="mode == 'active'">
                  <span :class="textSize + ' silver--text'">WORK</span>
                </template>
                <template v-if="mode == 'rest'">
                  <span :class="textSize + ' silver--text'">REST</span>
                </template>
                <template v-if="mode == ''">
                  <span :class="textSize + ' silver--text'">GET READY</span>
                </template>
              </v-col>
            </v-row>
          </v-progress-circular>
        </v-card-text>
        <v-sheet rounded class="pa-2 mt-2 charcoal charcoalTile">
          <v-list v-if="!completed" class="transparent pb-0">
            <v-list-item
              class="py-0 mt-n"
              v-for="(exercise, index) in exercises"
              :key="exercise.id"
            >
              <v-badge
                color="charcoal--text text--lighten-3"
                offset-y="10"
                offset-x="15"
                :content="setBadge(exercise)"
              >
                <BaseIconNumber
                  :color="iconColor(exercise)"
                  :value="index + 1"
                />
              </v-badge>
              <span :class="roundColor(exercise) + ' ml-1'">
                {{ exercise.name }}
              </span>
            </v-list-item>
          </v-list>
          <v-spacer />
          <v-card-actions v-if="!prestart" class=" pl-0 pr-1 py-1">
            <v-spacer />
            <BaseActionButton
              dark
              text
              label="Done"
              icon="mdi-checkbox-multiple-marked-outline"
              v-if="!completed"
              class=" paper--text"
              @clickedThis="done"
            />
            <BaseActionButton
              v-else
              dark
              text
              :disabled="!totalReps > 0"
              label="Finalize Workout"
              icon="mdi-arrow-down-box"
              color="success"
              @clickedThis="save"
            />
          </v-card-actions>
        </v-sheet>

        <v-card
          v-if="completed || currentRound >= 1"
          class="charcoal charcoalTile pa-2 mt-2"
          tile
        >
          <v-row dense v-for="(set, index) in rounds" :key="index">
            <v-col
              v-if="
                completed ||
                  currentRound == index + 1 ||
                  currentRound == index + 2
              "
              cols="12"
            >
              <v-card-actions class="px-0 py-0">
                <BaseIconNumber
                  class="pr-1"
                  :color="
                    currentRound == index + 1
                      ? 'paper'
                      : set.completed
                      ? 'progressActive'
                      : 'silver'
                  "
                  :value="set.round"
                />
                <span class="silver--text                  ">{{
                  set.exercise_name
                }}</span>
              </v-card-actions>
              <v-row dense>
                <v-col cols="3">
                  <v-text-field
                    dark
                    :class="isPhone ? 'enlarged-input-small' : 'enlarged-input'"
                    filled
                    color="success"
                    type="number"
                    pattern="[0-9]*"
                    inputmode="decimal"
                    label="Reps"
                    v-model="set.reps"
                    @input="calculateVolume(set)"
                    @focus="$event.target.select()"
                  />
                </v-col>
                <v-spacer />
                <template v-if="set.resistance == 'Yes'">
                  <v-col cols="4">
                    <v-text-field
                      dark
                      :class="
                        isPhone ? 'enlarged-input-small' : 'enlarged-input'
                      "
                      filled
                      color="success"
                      type="number"
                      pattern="[0-9]*"
                      inputmode="decimal"
                      label="Lbs"
                      v-model="set.imperial"
                      @input="calculateMetric(set)"
                      @focus="$event.target.select()"
                    />
                  </v-col>
                  <v-col cols="4">
                    <v-text-field
                      dark
                      :class="
                        isPhone ? 'enlarged-input-small' : 'enlarged-input'
                      "
                      filled
                      color="success"
                      type="number"
                      pattern="[0-9]*"
                      inputmode="decimal"
                      label="Kg"
                      v-model="set.metric"
                      @input="calculateImperial(set)"
                      @focus="$event.target.select()"
                    />
                  </v-col>
                </template>
                <v-col align="left" cols="1" class="mt-3">
                  <v-icon
                    :class="isPhone ? 'ml-n2' : 'ml-n2'"
                    large
                    :color="
                      currentRound == index + 1
                        ? 'paper'
                        : set.completed
                        ? 'progressActive'
                        : 'silver'
                    "
                    @click="set.completed = true"
                  >
                    mdi-check-circle
                  </v-icon>
                </v-col>
              </v-row>
              <v-card-actions class="pt-0 px-0 mt-n7">
                <v-spacer />
                <span
                  v-if="set.resistance == 'Yes'"
                  :class="textSizeXSmall + ' silver--text'"
                >
                  Volume:
                  {{ set.volume_imperial }} lbs /
                  {{ set.volume_metric }} kg</span
                >
              </v-card-actions>
            </v-col>
          </v-row>
        </v-card>
      </v-card>
    </v-dialog>
  </v-container>
</template>
<script>
import axios from 'axios'
import { appConfig } from '@/store/helpers.js'
import util from '@/mixins/util.js'
import sounds from '@/json/sounds.json'
import moment from 'moment/src/moment'

export default {
  mixins: [util],
  data: () => ({
    sounds: sounds,
    interval: {},
    mode: '',
    loaded: false,
    autoSave: true,
    completed: false,
    value: 5,
    countdown: 5,
    circleSize: 370,
    circleWidth: 10,
    currentRound: 0,
    totalReps: 0,
    totalImperial: 0,
    totalMetric: 0,
    dialog: false,
    prestart: true,
    zIndex: 0,
    workout: [],
    exercises: [],
    rounds: [],
    starttime: '',
    startTimeMoment: null,
    endtime: '',
    tick: 1000,
    switchNum: 0,
    sound: false,
    metric: false,
    activityid: 38, //circuit training, moderate
    calories: 4.3,
    audio: null,
    beep: null,
    go: null,
    readytoswitch: null,
    videoLoop: null,
    imageLogo: null
  }),
  props: {
    workoutid: {
      type: String
    }
  },
  beforeMount() {
    this.videoLoop = this.isPhone
      ? require(`@/assets/LoopSmall.mp4`)
      : require(`@/assets/Loop.mp4`)
    this.loadWorkout(this.workoutid)
    this.audio = new Audio(this.sounds['5secs'])
    this.beep = new Audio(this.sounds['ready'])
    this.go = new Audio(this.sounds['go'])
    this.readytoswitch = new Audio(this.sounds['readytoswitch'])
    this.audio.preload = 'auto'
    this.beep.preload = 'auto'
    this.go.preload = 'auto'
    this.readytoswitch.preload = 'auto'
    this.imageLogo = require('@/assets/Logo100.png')
  },
  beforeUpdate() {
    this.circleSize = this.isPhone ? 170 : 370
    this.circleWidth = this.isPhone ? 5 : 20
  },
  beforeDestroy() {
    this.screenOff()
    clearInterval(this.interval)
  },
  watch: {
    dialog: function(value) {
      if (value) {
        this.screenOn()
      } else this.screenOff()
    }
  },
  computed: {
    percentageActive() {
      return 100 - this.value * (100 / this.workout.active_time)
    },
    percentageRest() {
      return 100 - this.value * (100 / this.workout.rest_time_set)
    },
    progress() {
      return Math.ceil((this.currentRound / this.workout.rounds) * 100)
    },
    lastFinishedRound() {
      return this.completed ? this.currentRound : this.currentRound - 1
    },
    ...appConfig
  },
  methods: {
    setBadge(exercise) {
      let badge = exercise.reps + ' reps'
      if (exercise.weight != null) {
        if (exercise.weightf != null)
          badge += ' @ ' + exercise.weight + ' / ' + exercise.weightf
        else badge += ' @ ' + exercise.weight
      }
      return badge
    },
    roundColor(exercise) {
      if (!this.currentRound > 0) return 'silver--text'
      let val = 'silver--text'
      if (this.rounds[this.currentRound - 1].exercise_id == exercise.id)
        val = 'progressActive--text'
      return val
    },

    iconColor(exercise) {
      if (!this.currentRound > 0) return 'silver'
      let val = 'silver'
      if (this.rounds[this.currentRound - 1].exercise_id == exercise.id)
        val = 'progressActive'
      return val
    },
    calculateTotalVolume() {
      let total = 0
      let totalImp = 0
      let totalReps = 0
      this.rounds.forEach(el => {
        if (el.completed) {
          total = parseFloat(el.volume_metric) + total
          totalImp = parseFloat(el.volume_imperial) + totalImp
          totalReps = parseInt(el.reps) + totalReps
        }
      })
      this.totalMetric = this.twoDigits(total)
      this.totalImperial = this.twoDigits(totalImp)
      this.totalReps = totalReps
    },
    calculateVolume(set) {
      set.volume_metric = set.reps * set.metric
      set.volume_metric = this.twoDigits(set.volume_metric)
      set.volume_imperial = set.reps * set.imperial
      set.volume_imperial = this.twoDigits(set.volume_imperial)
      this.calculateTotalVolume()
      this.$forceUpdate()
    },
    calculateImperial(set) {
      set.imperial = set.metric * (1 / this.factor)
      set.imperial = this.roundFive(this.twoDigits(set.imperial))
      this.calculateVolume(set)
    },
    calculateMetric(set) {
      set.metric = set.imperial * this.factor
      set.metric = this.roundFive(this.twoDigits(set.metric))
      this.calculateVolume(set)
    },
    finalizeNow() {
      this.reset()
      this.completed = true
    },
    reset() {
      this.totalReps = 0
      this.totalImperial = 0
      this.totalMetric = 0
      this.currentRound = 0
      this.prestart = true
      this.value = 0
      this.countdown = 5
      this.mode = ''
      clearInterval(this.interval)
      this.sound = false
      this.switchNum++
    },
    done() {
      clearInterval(this.interval)
      this.calculateTotalVolume()
      this.completed = true
    },
    cancel() {
      this.reset()
      this.dialog = false
    },
    getColor() {
      if (this.mode == 'active') return 'progressActive'
      else return 'paper'
    },
    playStart() {
      if (this.isNativeApp() && !this.isAndroid()) this.playStartNative()
      else this.go.play()
    },
    playEnd() {
      if (this.isNativeApp() && !this.isAndroid()) this.playEndNative()
      else this.audio.play()
    },
    playDing() {
      if (this.isNativeApp() && !this.isAndroid()) this.playDingNative()
      else this.beep.play()
    },
    playSwitch() {
      if (this.isNativeApp() && !this.isAndroid()) this.playSwitchNative()
      else this.readytoswitch.play()
    },
    startWorkout() {
      this.dialog = true
      this.completed = false
      this.value = this.countdown
      this.starttime = this.now()

      if (this.prestart) {
        this.interval = setInterval(() => {
          if (this.value == this.countdown - 3) {
            this.playStart()
          }

          if (this.value == 0) {
            this.startTimeMoment = moment()
            this.prestart = false
            clearInterval(this.interval)
            this.startActive()
            return true
          }
          this.value -= 1
        }, this.tick)
      }
    },
    startActive() {
      this.screenOn()
      this.mode = 'active'
      let active = Math.ceil(this.workout.active_time)
      let activeStart = moment()
      let activeDuration = active

      this.value = active
      this.currentRound++

      if (this.mode == 'active') {
        this.interval = setInterval(() => {
          if (active % 30 == 0 && active != 0) this.playDing()
          if (active == 3) {
            this.playSwitch()
          }
          if (active == 0) {
            this.sound = false
            clearInterval(this.interval)
            this.startRest()
            return true
          } else {
            active = activeDuration - moment().diff(activeStart, 'seconds')
          }
          this.value = active
        }, this.tick)
      }
    },
    startRest() {
      this.mode = 'rest'
      this.sound = false
      let rest = Math.ceil(this.workout.rest_time_set)
      let restStart = moment()
      let restDuration = rest

      this.value = rest

      if (this.mode == 'rest') {
        this.interval = setInterval(() => {
          if (rest == this.soundCutoff) {
            this.playEnd()
          }
          if (rest == 0) {
            this.sound = false
            clearInterval(this.interval)
            if (this.currentRound < this.workout.rounds) {
              this.startActive()
            } else {
              this.completed = this.currentRound == this.workout.rounds
              if (this.completed) this.endtime = this.now()
              return true
            }
          } else {
            rest = restDuration - moment().diff(restStart, 'seconds')
          }
          this.value = rest
        }, this.tick)
      }
    },
    initializeValues() {
      let total = 0
      let totalImp = 0
      let totalReps = 0
      let cycle = this.exercises.length
      let index = 0
      let i = 0

      this.rounds.forEach(set => {
        index = parseInt(i % cycle)
        set.metric = 0
        set.imperial = 0
        set.completed = false
        if (this.useMetric) {
          set.metric =
            this.sex == 'Male'
              ? this.exercises[index].weight
              : this.exercises[index].weightf
          set.imperial = set.metric * (1 / this.factor)
          set.imperial = this.roundFive(this.twoDigits(set.imperial))
          if (set.metric == null) set.metric = 0
        } else {
          set.imperial =
            this.sex == 'Male'
              ? this.exercises[index].weight
              : this.exercises[index].weightf
          set.metric = set.imperial * this.factor
          set.metric = this.roundFive(this.twoDigits(set.metric))
          if (set.imperial == null) set.imperial = 0
        }
        set.volume_metric = set.reps * set.metric
        set.volume_metric = this.twoDigits(set.volume_metric)
        set.volume_imperial = set.reps * set.imperial
        set.volume_imperial = this.twoDigits(set.volume_imperial)
        total = parseFloat(set.volume_metric) + total
        totalImp = parseFloat(set.volume_imperial) + totalImp
        totalReps = parseInt(set.reps) + totalReps
        ++i
      })

      this.calculateTotalVolume()
      this.$forceUpdate()
    },
    loadWorkout(workoutid) {
      {
        return axios
          .get(this.baseURL + '/workout/' + workoutid, {})
          .then(response => {
            if (response.status == 200) {
              this.workout = response.data.data
              this.workout.roundTime =
                parseInt(this.workout.active_time) +
                parseInt(this.workout.rest_time_exercise)
              this.workout.percentage = Math.round(
                (parseInt(this.workout.active_time) / this.workout.roundTime) *
                  100
              )

              this.exercises = JSON.parse(this.workout.exercises)
              let cycle = this.exercises.length

              for (let i = 0; i < this.workout.rounds; ++i) {
                let index = parseInt(i % cycle)
                this.rounds[i] = {
                  round: i + 1,
                  exercise_index: index,
                  exercise_name: this.exercises[index].name,
                  resistance: this.exercises[index].resistance,
                  exercise_id: this.exercises[index].id,
                  reps: this.exercises[index].reps,
                  imperial: 0,
                  metric: 0,
                  volume_imperial: 0,
                  volume_metric: 0
                }
              }
              this.initializeValues()
              this.loaded = true
            } else {
              this.$store.dispatch(
                'notification/addErrors',
                response.data.errors,
                5000,
                true,
                {
                  root: true
                }
              )
              return
            }
          })
      }
    },
    save() {
      let minutesInt = parseInt(this.workout.total_time / 60)
      let minutesFrac = this.twoDigits((this.value % 60) / 60)
      this.minutes = minutesInt + minutesFrac

      return axios
        .post(this.baseURL + '/workout/savewod', {
          active: this.minutes,
          rest_set: Math.ceil(this.workout.rest_time_set),
          rest_exercise: Math.ceil(this.workout.rest_time_exercise),
          total_active: Math.ceil(this.workout.active_time_total),
          total: this.minutes,
          total_imperial: this.totalImperial,
          total_metric: this.totalMetric,
          total_rounds: this.workout.rounds,
          now: this.now(),
          start_time: this.starttime,
          rounds: this.rounds,
          exercises: this.exercises,
          workoutid: this.workout.workoutid
        })
        .then(response => {
          if (response.status == 200) {
            if (this.autoSave) this.saveActivity()
            else {
              this.done()
              this.$router.push({
                path: '/'
              })
              this.toast('Workout was saved..')
            }
          } else {
            this.$store.dispatch(
              'notification/addErrors',
              response.data.errors,
              5000,
              true,
              {
                root: true
              }
            )
            return
          }
        })
    },
    saveActivity() {
      let duration = this.minutes
      let totalCalories = this.twoDigits(this.calories * duration)
      return axios
        .post(this.baseURL + '/activities', {
          duration: duration,
          calories: this.calories,
          totalCalories: totalCalories,
          start_time: this.starttime,
          distance_imperial: 0,
          distance_metric: 0,
          activityid: this.activityid
        })
        .then(response => {
          if (response.status == 200) {
            this.done()
            this.$router.push({
              path: '/myworkouts'
            })
          } else {
            this.$store.dispatch(
              'notification/addErrors',
              response.data.errors,
              5000,
              true,
              {
                root: true
              }
            )
            return
          }
        })
    }
  }
}
</script>
