<template>
  <v-container>
    <v-dialog
      :fullscreen="isPhone"
      :width="dialogWidth"
      overlay-opacity=".95"
      v-model="showReorder"
      persistent
    >
      <ReorderList
        :items="exercisesList"
        title="Re-order Exercises"
        name="exercises"
        @clickedDone="sort()"
        @clickedCancel="sort()"
      />
    </v-dialog>
    <v-dialog
      v-model="showSuccess"
      persistent
      width="900"
      overlay-opacity=".95"
      :fullscreen="isPhone"
    >
      <v-card class="d-flex flex-column" elevation="20">
        <BaseCloseDialogHeader
          @cancel="goToWorkouts"
          heading=" Workout Added Succesfully!"
        />
        <v-card-subtitle style="min-height: 200px" class="body-1 py-4 pl-2">
          Workout <span class="paper--text">{{ oldname }}</span> was succesfully
          added.
          <span class="pt-2">What do you want to do</span>
          now?
        </v-card-subtitle>
        <v-spacer />
        <v-card-actions class="px-0 lightHeader">
          <BaseActionButton
            icon="mdi-eye"
            title="View All Workouts"
            label="View All"
            plain
            color="silver"
            :large="!isPhone"
            @clickedThis="goToWorkouts"
          />
          <v-spacer />
          <BaseActionButton
            icon="mdi-play-box-outline"
            title="Start This Workout Now"
            label="Start Now"
            plain
            color="success"
            :large="!isPhone"
            @clickedThis="startWorkout"
          />
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-form ref="WorkoutForm" name="WorkoutForm">
      <BaseScreenHeader title="Workout Builder" screenInfo="WRK-001" />

      <v-sheet rounded class=" transparent charcoalTile mb-4">
        <v-card-actions class="px-0 paper--text pb-0 mt-2">
          <span :class="textSize">Workout Type</span>
          <v-spacer />
        </v-card-actions>
        <v-card-actions class="pa-0 ">
          <v-spacer />
          <BaseActionButton
            @clickedThis="changeWorkoutType('Workout')"
            :color="type == 'Workout' ? ' progressActive' : ' paper'"
            label="Lifting"
            text
            title="Lifting & Strength Training"
            :large="false"
            icon=""
          />
          <BaseActionButton
            @clickedThis="changeWorkoutType('HIIT')"
            :color="type == 'HIIT' ? ' progressActive' : ' paper'"
            text
            label="Circuit"
            title="Circuit Training or HIIT"
            :large="false"
            icon=""
          />
          <BaseActionButton
            @clickedThis="changeWorkoutType('WOD')"
            :color="type == 'WOD' ? ' progressActive' : ' paper'"
            text
            label="Hybrid"
            :large="false"
            title="Hybrid workouts, including Cross-Training and WOD Style Workouts."
            icon=""
          />
          <v-spacer />
        </v-card-actions>
        <v-card-text
          :class="textSizeXSmall + ' silver--text px-0 text-center pt-0'"
        >
          {{ workoutTypeDescription }}
        </v-card-text>
        <v-card-actions class="pt-0 pr-0">
          <v-spacer />
          <v-btn text class="silver--text" @click="handleUnplanned">
            <span v-if="!unplanned">Change to un-planned?</span>
            <span v-else>Change to planned?</span>
          </v-btn>
        </v-card-actions>
      </v-sheet>

      <v-sheet class="pa-2 transparent charcoalTileMenu paper--text">
        <v-card-actions :class="textSizeSmall + ' pa-0 mb-2'">
          Workout Details
          <v-spacer />
          <BaseActionButton
            color="paper"
            text
            dark
            :large="!isPhone"
            :disabled="$v.$anyError || exercisesListSize == 0"
            @clickedThis="saveWorkout"
          />
        </v-card-actions>

        <template v-if="!unplanned">
          <v-row>
            <v-col cols="12" md="6">
              <v-text-field
                dark
                filled
                v-model="name"
                ref="name"
                :error-messages="nameErrors"
                :counter="50"
                label="Name"
                prepend-inner-icon="mdi-dumbbell"
                @input="$v.name.$touch()"
                @blur="$v.name.$touch()"
              />
            </v-col>
            <v-col cols="12" md="6">
              <v-textarea
                dark
                filled
                v-model="description"
                ref="description"
                :counter="200"
                :rows="3"
                label="Description"
                prepend-inner-icon="mdi-text"
                @input="$v.description.$touch()"
                @blur="$v.description.$touch()"
              />
            </v-col>
          </v-row>
        </template>

        <template v-if="type == 'HIIT'">
          <v-sheet color="transparent silver--text">
            <v-card-title class="pa-0"> Additional Details </v-card-title>
          </v-sheet>

          <v-card-text class="silver--text caption py-2 px-0">
            Enter
            <span
              class="progressActive--text"
              title="The time taken to activelly perform the exercise"
            >
              active time
            </span>
            and
            <span class="progressActive--text" title="Rest time between sets">
              rest time,
            </span>
            (in seconds),
            <span
              class="progressActive--text"
              title="Rest time between exercises"
            >
              exercise rest time
            </span>
            (in seconds) if different than rest time and total time (in
            minutes).
            <v-spacer />
            If you enter total time, we will calculate the number of rounds. If
            you enter the number of rounds, we will calculate total time.
          </v-card-text>

          <v-row class="px-4 mt-0 mb-1">
            <v-switch
              dark
              v-model="tabata"
              label="Tabata"
              color="progressActive"
              @change="tabataSwitch"
            />
            <v-spacer />
            <v-switch
              dark
              v-model="byTime"
              color="progressActive"
              label="By Time"
            />
          </v-row>
          <v-row dense>
            <v-col cols="6" md="3">
              <v-text-field
                dark
                filled
                :disabled="tabata"
                v-model="workout.active_time"
                ref="active"
                :error-messages="activeErrors"
                :counter="5"
                label="Active Time (seconds)"
                prepend-inner-icon="mdi-clock-in"
                @input="activeChanged"
                @blur="$v.active.$touch()"
              />
            </v-col>
            <v-col cols="6" md="3">
              <v-text-field
                dark
                filled
                :disabled="tabata"
                v-model="workout.rest_time_exercise"
                ref="restExercise"
                :error-messages="restExerciseErrors"
                :counter="5"
                label="Rest Time (seconds)"
                prepend-inner-icon="mdi-clock-out"
                @input="restTimeExerciseChanged"
                @blur="$v.restExercise.$touch()"
              />
            </v-col>
            <v-col cols="6" md="3">
              <v-text-field
                dark
                filled
                :disabled="!byTime"
                v-model="workout.total_time"
                ref="total"
                :error-messages="totalErrors"
                :counter="5"
                label="Total Time (minutes)"
                prepend-inner-icon="mdi-clock-digital"
                @input="totalChanged"
                @blur="$v.total.$touch()"
              />
            </v-col>
            <v-col cols="6" md="3">
              <v-text-field
                dark
                filled
                :disabled="byTime"
                v-model="workout.rounds"
                ref="rounds"
                :counter="2"
                :error-messages="roundsErrors"
                label="Rounds"
                type="number"
                prepend-inner-icon="mdi-circle-double"
                @input="roundsChanged"
                @blur="$v.rounds.$touch()"
              />
            </v-col>
          </v-row>
        </template>
      </v-sheet>
      <v-sheet class="pa-2 transparent charcoalTile my-4">
        <v-card-subtitle
          v-if="exercisesList.length > 0"
          :class="textSizeXSmall + ' silver--text pt-0 px-0'"
        >
          Click on each exercise below to adjust the number of sets, reps, and
          load. You can remove an exercise by clicking on the little
          <span class="progressActive--text">x </span> in a white circle.
        </v-card-subtitle>
        <v-card-actions class="pa-0">
          <span :class="textSizeSmall + ' py-2  px-0 silver--text'">
            Selected Exercises
          </span>
          <v-badge
            :content="exercisesList.length"
            :value="exercisesList.length"
            color="transparent paper--text"
            overlap
          >
            <v-icon color="silver" large>
              mdi-checkbox-multiple-marked-circle
            </v-icon>
          </v-badge>
          <v-spacer />
          <v-btn
            text
            v-if="exercisesList.length > 0"
            class="mr-n2 progressActive--text"
            @click="showReorder = true"
          >
            Re-order
          </v-btn>
        </v-card-actions>

        <ExerciseChip
          :type="type"
          :repSchemes="repSchemes"
          @remove="exerciseRemove"
          @done="saveExerciseUpdates"
        />
      </v-sheet>

      <ExercisesList :type="type" />
    </v-form>
  </v-container>
</template>
<script>
import { validationMixin } from 'vuelidate'
import { required, between } from 'vuelidate/lib/validators'
import { appConfig, builder } from '@/store/helpers.js'
import { mapActions } from 'vuex'
import axios from 'axios'
import util from '@/mixins/util.js'
const ExerciseChip = () =>
  import(/* webpackPrefetch: true */ '@/components/pageutil/ExerciseChip.vue')
const ReorderList = () =>
  import(/* webpackPrefetch: true */ '@/components/pageutil/ReorderList.vue')
const ExercisesList = () =>
  import(/* webpackPrefetch: true */ '@/components/pageutil/ExercisesList.vue')

export default {
  components: {
    ExerciseChip,
    ReorderList,
    ExercisesList
  },
  name: 'AddWorkout',
  mixins: [validationMixin, util],
  data: () => ({
    tagsize: 'small',
    byTime: true,
    tabata: false,
    showAddExercise: false,
    search: '',
    active: 0,
    rest: 0,
    restExercise: 0,
    total: 0,
    selectedScheme: {},
    workout: {},
    initial: {
      active_time: 50,
      active_time_total: 60,
      rest_time_exercise: 10,
      rest_time_set: 10,
      total_time: 1,
      rounds: 1
    },
    tabataInitial: {
      active_time: 20,
      active_time_total: 160,
      rest_time_exercise: 10,
      rest_time_set: 10,
      total_time: 4,
      rounds: 8
    },
    repschemeid: 'hzWVL',
    exerciseid: null,
    showExerciseInfo: false,
    type: 'Workout',
    rounds: 1,
    filter: {},
    sortDesc: false,
    unplanned: false,
    loaded: false,
    switchNum: 0,
    sortBy: 'name',
    typeText: 'Lifting',
    workoutTypeDescription: 'Strength & bodybuilding focused workouts.',
    exercises: [],
    deletedExercises: [],
    repSchemes: [],
    selectedForEdit: {},
    mechanicsType: '',
    utilityType: '',
    exercisesType: '',
    name: '',
    isNow: true,
    oldname: '',
    description: 'NA',
    glossary: {},
    showSuccess: false,
    showTooltip: false,
    showReorder: false
  }),

  beforeMount() {
    this.datetime = this.now()
    this.workout = { ...this.initial }
    this.loadRepschemes()
    this.loadGlosary()
  },
  beforeDestroy() {
    this.exercisesClear()
  },
  validations: {
    name: {
      required
    },
    description: {
      required
    },
    rounds: {
      required,
      between: between(1, 99)
    },
    active: {
      between: between(1, 600)
    },
    rest: {
      between: between(1, 600)
    },
    restExercise: {
      between: between(1, 600)
    },
    total: {
      between: between(0, 60)
    },

    workout: {
      roundsHiit: {
        required,
        between: between(1, 99)
      },
      active_time: {
        between: between(1, 600)
      },
      rounds: {
        required,
        between: between(1, 99)
      },
      total_time: {
        between: between(1, 600)
      },
      total_time_seconds: {
        between: between(0, 60)
      },
      total_time_minutes: {
        between: between(0, 99)
      },
      rest_time_exercise: {
        between: between(1, 600)
      }
    }
  },
  computed: {
    ...appConfig,
    ...builder,
    nameErrors() {
      const errors = []
      if (!this.$v.name.$dirty) return errors
      !this.$v.name.required && errors.push('Workout name is required.')
      return errors
    },
    activeErrors() {
      const errors = []
      if (!this.$v.active.$dirty) return errors
      !this.$v.workout.active_time.between &&
        errors.push(
          'The number of active seconds is invalid. Expected (1 to 600)'
        )
      return errors
    },
    restErrors() {
      const errors = []
      if (!this.$v.rest.$dirty) return errors
      !this.$v.workout.rest_time_exercise.between &&
        errors.push(
          'The number of resting seconds between sets is invalid. Expected (1 to 600)'
        )
      return errors
    },
    restExerciseErrors() {
      const errors = []
      if (!this.$v.restExercise.$dirty) return errors
      !this.$v.restExercise.between &&
        errors.push(
          'The number of resting seconds between exercises is invalid. Expected (1 to 600)'
        )
      return errors
    },
    totalErrors() {
      const errors = []
      if (!this.$v.total.$dirty) return errors
      !this.$v.total.between &&
        errors.push('The number of total minutes is invalid.')
      return errors
    },
    roundsErrors() {
      const errors = []
      if (!this.$v.rounds.$dirty) return errors
      !this.$v.rounds.required &&
        errors.push('The number of rounds  is required.')
      !this.$v.rounds.between && errors.push('The number of rounds is invalid.')
      return errors
    }
  },
  methods: {
    removeExercise(exercise) {
      this.exerciseRemove(exercise)
    },
    goToWorkouts() {
      this.$router.push('/myworkouts')
      this.showSuccess = false
    },
    totalChanged() {
      this.$v.total.$touch()
      this.calculateTimes()
    },
    roundsChanged() {
      this.$v.rounds.$touch()
      this.calculateTimes()
    },
    activeChanged() {
      this.$v.active.$touch()
      this.calculateTimes()
    },
    restTimeExerciseChanged() {
      this.$v.restExercise.$touch()
      this.calculateTimes()
    },
    tabataSwitch() {
      this.workout = this.tabata
        ? { ...this.tabataInitial }
        : { ...this.initial }
    },
    reset() {
      this.workout = { ...this.initial }
      this.rounds = 0
      this.$v.$reset()
    },
    calculateTimes() {
      this.workout.rest_time_set = this.workout.rest_time_exercise

      let roundSeconds =
        Number.parseInt(this.workout.active_time) +
        Number.parseInt(this.workout.rest_time_exercise)
      let totalSeconds = 0

      if (this.byTime) {
        totalSeconds = this.workout.total_time * 60
        this.workout.rounds = Math.ceil(totalSeconds / roundSeconds)
      } else {
        totalSeconds = this.workout.rounds * roundSeconds
        this.workout.total_time = Math.ceil(totalSeconds / 60)

        this.workout.rounds = Math.ceil(totalSeconds / roundSeconds)
        this.rounds = this.workout.rounds
      }
    },
    changeWorkoutType(type) {
      this.type = type
      if (type == 'WOD') {
        this.$router.push({
          path: '/workouts/addwod'
        })
      } else {
        if (type == 'Workout') {
          this.workoutTypeDescription =
            'Strength & bodybuilding focused workouts.'
          this.typeText = 'Lifting & Strength Training'
        } else {
          this.workoutTypeDescription =
            'Circuit training or HIIT( High Intensity Interval Training).'
          this.typeText = 'CIRCUIT'
        }
      }
    },
    startWorkout() {
      this.$router.push({
        path: '/workouts/start/' + this.workoutid
      })
    },
    handleUnplanned() {
      this.unplanned = !this.unplanned
      if (this.unplanned) {
        this.name = 'Unplanned: ' + this.datetime
        this.description = 'Unplanned (ad-hoc) workout.'
      } else {
        this.name = ''
        this.description = ''
      }
    },
    sort() {
      this.exercisesSort()
      this.showReorder = false
      this.$forceUpdate()
      this.reOrderIndex++
    },
    saveExerciseUpdates() {
      const asArray = []
      asArray.push(this.selectedForEdit)
      this.exercises = this.exercises.map(
        obj => asArray.find(o => o.id === obj.id) || obj
      )
    },

    toggleDetails() {
      this.showDetails = !this.showDetails
    },
    getMuscles(allMuscles, type) {
      if (this.isEmpty(allMuscles)) return false
      let muscles = JSON.parse(allMuscles)
      muscles = muscles.filter(el => {
        return el.type == type
      })
      return muscles
    },
    isSelected(id) {
      return this.exercisesList.some(sel => sel.id === id)
    },

    addMore() {
      this.showSuccess = false
      this.$v.$reset()
    },
    setexercises(name) {
      this.exercisesType = name
    },

    saveWorkout() {
      this.exercisesList.forEach(ex => {
        let repscheme = this.repSchemes.filter(e => {
          return e.id == ex.repschemeid
        })[0]
        let schemeDetails = JSON.parse(repscheme.details)[0]
        let isWOD = ex.workoutType == 'WOD' || ex.workoutType == 'Hybrid'
        let noSets =
          ex.track_distance == 'Yes' || ex.track_time == 'Yes' || isWOD

        if (noSets) {
          ex.sets = 1
          ex.reps = 1
          ex.pmax = 0
        } else {
          ex.sets = ex.sets == 0 ? repscheme.sets : ex.sets
          ex.pmax = schemeDetails.load
          ex.reps = schemeDetails.reps
        }
      })
      window.scrollTo(0, 0)
      return axios
        .post(this.baseURL + '/workouts', {
          workout: this.workout,
          name: this.name,
          description: this.description,
          repschemeid: this.repschemeid,
          exercises: this.exercisesList,
          now: this.datetime,
          adhoc: this.unplanned ? 'Yes' : 'No',
          active: this.active,
          activeTotal: this.active * this.rounds,
          rest: this.rest,
          restExercise: this.restExercise,
          total: this.total,
          rounds: this.rounds,
          workoutType: this.type
        })
        .then(response => {
          if (response.status == 200) {
            this.workoutid = response.data.data['workoutid']
            this.oldname = this.name
            this.showSuccess = true
            this.$refs.WorkoutForm.reset()
            this.name = ''
            this.description = ''
            this.rounds = ''
            this.exercisesClear()
            this.$store.dispatch(
              'notification/addNotification',
              'Workout added succesfully.',
              2000,
              true,
              {
                root: true
              }
            )
          } else {
            this.$store.dispatch(
              'notification/addErrors',
              response.data.errors,
              5000,
              true,
              {
                root: true
              }
            )
            return
          }
        })
    },
    loadGlosary() {
      return axios
        .get(this.baseURL + '/util/glossary/all', {})
        .then(response => {
          if (response.status == 200) {
            this.glossary = response.data.data

            this.forces = this.glossary.filter(function(item) {
              return item.category == 'Force'
            })
            this.mechanics = this.glossary.filter(function(item) {
              return item.category == 'Mechanics'
            })
            this.utility = this.glossary.filter(function(item) {
              return item.category == 'Utility'
            })
            this.dificultyList = this.glossary.filter(function(item) {
              return item.category == 'Dificulty'
            })
          } else {
            this.$store.dispatch(
              'notification/addErrors',
              response.data.errors,
              5000,
              true,
              {
                root: true
              }
            )
            return
          }
        })
    },
    loadRepschemes() {
      return axios
        .get(this.baseURL + '/tools/repschemes/all', {})
        .then(response => {
          if (response.status == 200) {
            this.repSchemes = response.data.data
            let scheme = this.repSchemes.filter(item => {
              return item.id == this.repschemeid
            })
            this.selectedScheme = scheme[0]
          } else {
            this.$store.dispatch(
              'notification/addErrors',
              response.data.errors,
              5000,
              true,
              {
                root: true
              }
            )
            return
          }
        })
    },
    ...mapActions('builder', [
      'exerciseRemove',
      'exercisesClear',
      'exercisesSort'
    ])
  }
}
</script>
