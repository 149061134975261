<template>
  <v-container>
    <BaseScreenHeader
      title="Search Workouts"
      screenInfo="WRK-010"
      :showBack="true"
      :showNav="true"
    />
    <template>
      <BaseFreeTier v-if="loaded && loadedList.length > 4" />
      <v-card-actions class="pb-0 px-0 mb-n6 mt-2">
        <v-autocomplete
          solo
          height="70"
          background-color="charcoal lighten-2"
          dark
          color="success"
          v-if="loaded"
          v-model="muscleGroup"
          filled
          chips
          return-object
          prepend-inner-icon="mdi-arm-flex"
          :items="muscleGroups"
          label="Workout Focus"
          item-text="muscle_group"
          item-value="muscle_group"
          item-color="success"
          @change="loadPage"
          :menu-props="{
            closeOnContentClick: true,
            closeOnClick: true,
            maxHeight: 250,
            transition: 'fab-transition'
          }"
       >
       <template
                v-slot:selection="{ item, index, selected, disabled, remove }"
              >
                <v-chip
                  class="silver lighten-3 pa-4 text-h6"
                  :key="index"
                  :input-value="item.value"
                  :disabled="disabled"
                >
                  <span class="black--text"> {{ item.muscle_group }}</span>
                </v-chip>
              </template>
              <template v-slot:item="{ item, on, attrs }">
                <v-list-item v-bind="attrs" v-on="on" class="transparent">
                  <v-list-item-content class="paper lighten-2">
                    <v-list-item-title class="text-h6 black--text">{{
                      item.muscle_group
                    }}</v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </template>
      </v-autocomplete>
      </v-card-actions>
      <v-sheet color="transparent charcoalTile" class="pa-2 pt-0 mt-2">
        <v-card-text class="text-center py-0">
          <span title="Active Filter" :class="textSizeSmall + ' silver--text'">
            {{ filterLabel }}
          </span>
        </v-card-text>

        <v-card-text class="text-center py-0">
          <v-icon
            large
            title="ALL"
            color="silver"
            @click="filterWorkouts('All')"
          >
            mdi-checkbox-multiple-blank
          </v-icon>
          <v-icon
            v-for="(item, index) in typesList"
            :key="index"
            large
            :title="'Workout Type: ' + item.name"
            :color="workoutTypeColor(item)"
            @click="filterWorkouts(item.name)"
          >
            mdi-checkbox-blank
          </v-icon>
        </v-card-text>
        <v-card-actions class="pa-0 mt-n2 mb-n2">
          <v-spacer />
          <v-checkbox
            dark
            class="pa-0"
            color="silver"
            v-model="planned"
            label="Planned Only?"
          />
        </v-card-actions>
      </v-sheet>
      <v-card-actions class="mt-2 px-0">
        <BaseActionButton
          dark
          color="silver"
          text
          label="Reset Search"
          icon="mdi-undo-variant"
          @clickedThis="reset"
        />
        <v-spacer />
        <BaseActionButton
          dark
          class="charcoal paper--text"
          text
          label="Search"
          icon="mdi-magnify"
          @clickedThis="loadPage"
        />
      </v-card-actions>
      <template v-if="workouts.length > 0">
        <v-card-actions class="pa-0  mt-4 mb-n8">
          <v-spacer />
          <v-checkbox
            dark
            v-model="showDetails"
            on-icon="mdi-chevron-up-circle"
            off-icon="mdi-chevron-down-circle"
            label="Show Details?"
            color="progressActive"
          />
        </v-card-actions>
        <v-row :key="switchNum" dense>
          <v-col
            class="mt-2"
            v-for="(workout, index) in workouts"
            :key="index"
            cols="12"
            md="6"
          >
            <v-card
              rounded
              class="transparent charcoalTileMenu silver--text px-2 mb-2"
              elevation="1"
            >
              <v-card-actions>
                <v-divider class="charcoal lighten-1 " />
                <BaseActionButton
                  :label="
                    workout.is_incomplete == 'Yes'
                      ? 'Resume Workout'
                      : 'Start ' + workout.type
                  "
                  :title="
                    workout.is_incomplete == 'Yes'
                      ? 'Resume Workout: ' + workout.name
                      : 'Start Workout: ' + workout.name
                  "
                  text
                  color="paper"
                  :class="textSize"
                  icon="mdi-play-circle"
                  @clickedThis="startWorkout(workout)"
                />
                <v-divider class="charcoal lighten-1" />
              </v-card-actions>

              <v-sheet color="transparent " class="silver--text">
                <v-card-title
                  :class="textSizeSmall + ' paper--text  px-1 py-0'"
                >
                  <v-icon
                    v-if="workout.is_favorite == 'Yes'"
                    color="accent"
                    large
                    title="Remove From Favorites"
                    @click="removeFavorite(workout)"
                  >
                    mdi-heart
                  </v-icon>
                  <v-icon
                    v-else
                    color="accent"
                    title="Add To Favorites"
                    large
                    @click="addFavorite(workout)"
                  >
                    mdi-heart-outline
                  </v-icon>
                  <span class="pl-1">{{ trimName(workout) }}</span>
                  <v-spacer />
                </v-card-title>
                <template>
                  <v-card-actions
                    :class="textSizeXSmall + ' silver--text py-0 px-2'"
                  >
                    Used {{ workout.counter }} times, copied
                    {{ workout.copied }} times.
                    <v-spacer />
                    <span
                      v-if="workout.public == 'Yes'"
                      :class="textSizeXSmall + ' success--text link'"
                      @click="manageVisibility(workout)"
                    >
                      PUBLIC
                    </span>
                    <span
                      v-else
                      :class="textSizeXSmall + ' warning--text link'"
                      @click="manageVisibility(workout)"
                    >
                      PRIVATE
                    </span>
                  </v-card-actions>
                  <v-card-actions
                    :class="textSizeXSmall + ' silver--text pt-0 px-2'"
                  >
                    Last Used:
                    {{
                      workout.is_unused == 'No'
                        ? prettyDate(workout.last_run)
                        : 'NA'
                    }}
                    <v-spacer />
                    <span
                      title="Workout Type"
                      :class="textSizeXSmall + ' silver--text'"
                    >
                      {{ workout.type }}
                      <span
                        class="text-uppercase"
                        v-if="workout.type == 'Hybrid'"
                        >- {{ workout.xmode }}</span
                      >
                    </span>
                  </v-card-actions>
                </template>
                <v-card-actions class="pb-2 pt-0">
                  <v-divider class="charcoal lighten-1" />
                  <v-badge
                    color="success"
                    inline
                    :content="badgeContent(workout)"
                  >
                  </v-badge>
                  <v-divider class="charcoal lighten-1" />
                </v-card-actions>
                <v-card-text
                  :class="textSizeSmall + ' paper--text pt-0 pl-2'"
                  v-if="showDetails"
                >
                  {{ workout.description }}
                </v-card-text>
                <v-card-text class="pl-0">
                  <v-list
                    v-if="showExercises"
                    dense
                    subheader
                    class="transparent"
                  >
                    <v-card-text
                      v-for="(exercise, index) in workout.exercises"
                      :key="'e-'.concat(index)"
                      class="pa-0 mb-3"
                    >
                      <v-badge
                        class="pa-0"
                        color="progressActive charcoal--text"
                        left
                        inline
                        :content="exercise.sort"
                      >
                        <span
                          :class="textSizeSmall + ' ml-1 link paper--text'"
                          :title="'View Details for ' + exercise.name"
                          @click="showExercise(exercise.id)"
                        >
                          {{ exercise.name }}
                        </span>
                      </v-badge>

                      <v-card-actions class="pa-0 ml-8">
                        <span class=" silver--text">
                          {{ exerciseInfo(exercise) }}
                        </span>
                      </v-card-actions>
                      <v-card-actions class="pa-0 ml-5 mt-n1">
                        <BaseActionButton
                          v-if="
                            workout.type == 'Lifting' && loaded && showDetails
                          "
                          :label="repSchemeName(exercise.repschemeid)"
                          plain
                          color="silver"
                          small
                          :large="!isPhone"
                          icon="mdi-information-outline"
                          @clickedThis="selectRepScheme(exercise.repschemeid)"
                        />
                      </v-card-actions>
                    </v-card-text>
                  </v-list>
                </v-card-text>
                <v-card-actions class="py-0">
                  <BaseTag
                    v-if="workout.is_incomplete == 'Yes'"
                    color="charcoal silver--text"
                    label="Incomplete"
                    :tagsize="isPhone ? 'small' : 'medium'"
                  />
                </v-card-actions>
                <v-card-actions class="px-0">
                  <BaseActionButton
                    v-if="workout.adhoc == 'No'"
                    :large="!isPhone"
                    icon="mdi-pencil-outline"
                    :title="'Edit Workout: ' + workout.name"
                    label="Edit"
                    text
                    color="silver"
                    @clickedThis="editWorkout(workout)"
                  />
                  <BaseActionButton
                    v-if="workout.adhoc == 'Yes'"
                    :large="!isPhone"
                    icon="mdi-pencil-box-multiple-outline"
                    :title="'Convert Workout: ' + workout.name"
                    label="Convert"
                    color="accent"
                    plain
                    @clickedThis="convertWorkout(workout)"
                  />
                  <BaseActionButton
                    v-if="workout.public == 'Yes'"
                    :large="!isPhone"
                    icon="mdi-share"
                    :title="'Edit Workout: ' + workout.name"
                    label="Share"
                    text
                    color="paper"
                    @clickedThis="shareWorkout(workout)"
                  />
                  <v-spacer />
                  <BaseActionButton
                    :large="!isPhone"
                    icon="mdi-pencil-box-multiple-outline"
                    :title="'Duplicate Workout: ' + workout.name"
                    label="Duplicate"
                    text
                    color="success"
                    @clickedThis="copyWorkout(workout)"
                  />
                </v-card-actions>
              </v-sheet>
            </v-card>
          </v-col>
        </v-row>
      </template>
    </template>

    <LoadingDialog
      :show="!loaded"
      title="LOADING Workouts..."
      color="progressActiver"
    />

    <v-dialog v-model="showConvertDialog" persistent width="700">
      <v-sheet color="primary" class="silver--text">
        <v-card-title class="title">
          Convert Workout?
        </v-card-title>
      </v-sheet>
      <v-form ref="convertWorkoutForm" name="convertWorkoutForm">
        <v-card>
          <v-card-text>
            This allows you to convert an existing unplanned (adhoc) workout to
            a saved, planned workout.
            <v-card-subtitle class="title accent--text">
              Current Workout Name
              <div
                class="body-1 primary--text"
                v-html="selectedWorkout.name"
              ></div>
            </v-card-subtitle>
            <v-row dense>
              <v-col cols="12">
                <v-text-field
                  v-model="selectedWorkout.newname"
                  ref="name"
                  :counter="50"
                  label="New Workout Name"
                  prepend-icon="mdi-rename-box"
                />
              </v-col>
            </v-row>
            <v-card-subtitle class="body-1 pt-5">
              Update the workout name above and then click the
              <span class="accent--text">Convert </span> button.
            </v-card-subtitle>
          </v-card-text>
          <v-card-actions>
            <BaseActionButton
              icon="mdi-close"
              label="Cancel"
              plain
              @clickedThis="showConvertDialog = false"
            />
            <v-spacer />
            <BaseActionButton
              label="Convert"
              plain
              icon="mdi-pencil-box-multiple-outline"
              @clickedThis="convertNow()"
            />
          </v-card-actions>
        </v-card>
      </v-form>
    </v-dialog>
    <v-dialog v-model="showRepSchemeDialog" persistent fullscreen width="850">
      <v-card
        class="d-flex flex-column charcoal noscroll-x"
        tile
        elevation="20"
      >
        <BaseCloseDialogHeader
          :heading="selectedRepScheme.name"
          @cancel="showRepSchemeDialog = false"
        />
        <v-card-subtitle
          :class="textSizeXSmall + ' paper--text pt-4 px-2 pb-1'"
        >
          REPSCHEME DETAILS
        </v-card-subtitle>
        <template>
          <v-sheet rounded class="silver--text pa-2 mx-2 charcoal charcoalTile">
            <v-card-title class="paper--text pa-0">
              Info
            </v-card-title>
            <v-card-text :class="textSizeXSmall + ' pb-0'">
              {{ selectedRepScheme.display_name }}
              <br />
              Utility:{{ selectedRepScheme.utility }}<br />
              Scheme Type: {{ selectedRepScheme.type }}
            </v-card-text>
          </v-sheet>
          <v-sheet rounded class="silver--text pa-2 ma-2 charcoal charcoalTile">
            <v-card-title class="progressActive--text px-0 pb-0">
              Sets: {{ selectedRepScheme.sets }}
            </v-card-title>
            <v-card
              flat
              class="py-0 transparent silver--text"
              v-for="(set, index) in selectedRepScheme.detailsObject"
              :key="'rs-'.concat(index)"
            >
              <BaseIconNumber
                :value="set.setnum"
                :large="false"
                color="progressActive"
              />
              <span class="paper--text">
                {{ set.reps }} reps with {{ set.rest }} seconds rest between
                sets.
              </span>
              <v-card-actions class="pa-0 pl-6">
                @
                <template v-if="set.load_type == '1RM'">
                  {{ set.load }}% {{ set.load_type }}
                </template>
                <template v-else>
                  {{ set.load_imperial }} lbs / {{ set.load_metric }} kg
                </template>
                + {{ set.load_increment }} lbs/kg
              </v-card-actions>
            </v-card>
            <template v-if="selectedRepScheme.description != null">
              <v-card-title class="accent--text pb-0 px-0">
                Description
              </v-card-title>
              <v-card-text v-html="selectedRepScheme.description">
              </v-card-text>
            </template>
          </v-sheet>
        </template>

        <v-spacer />
        <v-card-actions class="pa-0 pr-2">
          <v-spacer />
          <BaseActionButton
            dark
            icon="mdi-checkbox-outline"
            large
            title="Done"
            label="Done"
            text
            color="silver"
            @clickedThis="showRepSchemeDialog = false"
          />
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="showCopyDialog"
      persistent
      width="700"
      :fullscreen="isPhone"
    >
      <v-card tile class="d-flex flex-column">
        <v-sheet color="lightHeader" class="charcoal--text">
          <v-card-title class="title">
            Duplicate Workout?

            <v-spacer />
            <BaseCloseDialog @cancel="showCopyDialog = false" />
          </v-card-title>
        </v-sheet>
        <v-card-text class="px-2 mt-2">
          This allows you to duplicate one of your workouts. You will then be
          able to tweak it and use it as you see fit.
          <v-card-text class="title accent--text px-0">
            Current Workout Name
            <div
              class="body-1 primary--text"
              v-html="selectedWorkout.name"
            ></div>
          </v-card-text>
          <v-row dense>
            <v-col cols="12">
              <v-text-field
                v-model="selectedWorkout.newname"
                ref="name"
                :counter="50"
                label="New Workout Name"
                prepend-icon="mdi-rename-box"
                @focus="$event.target.select()"
              />
            </v-col>
          </v-row>
          <v-card-text class="body-1 pt-5 px-0">
            Update the workout name above and then click the
            <span class="accent--text">Duplicate </span> button.
          </v-card-text>
        </v-card-text>
        <v-spacer />
        <v-card-actions class="px-0">
          <BaseActionButton
            icon="mdi-close"
            label="Cancel"
            plain
            @clickedThis="showCopyDialog = false"
          />
          <v-spacer />
          <BaseActionButton
            :disabled="isEmpty(selectedWorkout.newname)"
            label="Duplicate"
            plain
            title="Duplicate Workout"
            color="success"
            icon="mdi-pencil-box-multiple-outline"
            @clickedThis="copyNow()"
          />
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      :width="dialogWidth"
      persistent
      scrollable
      v-model="showExerciseInfo"
      :fullscreen="isPhone"
      overlay-color="charcoal"
      overlay-opacity="0.97"
    >
      <ExerciseInfo
        :exerciseid="selectedExercise"
        @keydown.esc="cancelInfo"
        @cancel="cancelInfo"
        :key="switchNum"
        @swipe_off="cancelInfo"
      />
    </v-dialog>
    <v-dialog
      v-model="showVisibilityDialog"
      persistent
      width="700"
      :fullscreen="isPhone"
      overlay-color="primary"
      overlay-opacity="70"
    >
      <v-card class="d-flex flex-column charcoal">
        <v-sheet class="charcoal paper--text">
          <v-card-title
            :class="
              selectedWorkout.public == 'Yes' ? 'warning--text' : 'success--text'
            "
          >
            <span class="paper--text pr-2">Make Workout</span>
            {{ selectedWorkout.public == 'Yes' ? 'PRIVATE' : 'PUBLIC' }} ?
          </v-card-title>
        </v-sheet>
        <v-spacer />
        <v-card-actions class="px-0">
          <BaseActionButton
            icon="mdi-close"
            label="No"
            color="paper"
            plain
            @clickedThis="cancelVisibility"
          />
          <v-spacer />
          <BaseActionButton
            label="Yes"
            text
            color="progressActive"
            icon="mdi-checkbox-outline"
            @clickedThis="saveVisibility()"
          />
        </v-card-actions>
      </v-card>
    </v-dialog>
    <BaseUpgradeWarning
      :show="showUpgradeWarning"
      @cancel="showUpgradeWarning = false"
      limitation="Maximum five workout templates."
      remediation="edit an existing workout, delete a workout and then create a new one,"
    />
  </v-container>
</template>
<script>
import axios from 'axios'
import { appConfig, builder } from '@/store/helpers.js'
import util from '@/mixins/util.js'
const ExerciseInfo = () =>
  import(/* webpackPrefetch: true */ '@/components/dialogs/ExerciseInfo.vue')
const LoadingDialog = () =>
  import(/* webpackPrefetch: true */ '@/components/dialogs/Loading.vue')

export default {
  components: { ExerciseInfo, LoadingDialog },
  mixins: [util],
  name: 'SearchWorkouts',
  data: () => ({
    name: '',
    oldname: '',
    description: '',
    filterLabel: 'All',
    workouts: [],
    loadedList: [],
    muscleslist: [],
    repSchemes: [],
    muscleGroups: [],
    muscleGroup: {},
    initMuscleGroup: { muscle_group: 'All', body_position: 'All' },
    glossary: [],
    typesList: [],
    selectedRepScheme: {},
    selectedWorkout: {},
    selectedExercise: '',
    showExerciseInfo: false,
    showConvertDialog: false,
    showRepSchemeDialog: false,
    showCopyDialog: false,
    showVisibilityDialog: false,
    showDetails: false,
    showExercises: true,
    showMore: false,
    planned: true,
    loaded: false,
    refresh: false,
    filtering: false,
    showUpgradeWarning: false,
    search: null,
    deletable: {},
    workoutType: 'All',
    switchNum: 0,
    mode: '',
    previousMode: ''
  }),
  beforeMount() {
    this.muscleGroup = this.initMuscleGroup
    this.$store.dispatch('session/loadSubscription', {})
    this.loadMuscleGroups()
    this.loadGlosary()
    this.loaded = true
  },
  mounted() {
    window.addEventListener('message', this.receiveMessage)
  },
  beforeDestroy() {
    window.removeEventListener('message', this.receiveMessage)
  },

  watch: {
    showExerciseInfo: function(value) {
      this.$store.dispatch('session/toggleModal', value)
    }
  },
  computed: {
    ...appConfig,
    ...builder,
    itemsCount() {
      return this.workouts.length
    }
  },
  methods: {
    receiveMessage(event) {
      if (event.data.type == 'CLOSE_MODAL' && event.data.key == 'UoMc92xqvA') {
        if (this.showExerciseInfo) this.cancelInfo()
      }
    },
    reset() {
      this.muscleGroup = this.initMuscleGroup
      this.planned = true
      this.filterWorkouts('All')
      this.workouts = []
      this.loadedList = []
    },
    addMore() {
      if (!this.is_pro && this.itemsCount > 4) {
        this.showUpgradeWarning = true
      } else
        this.$router.push({
          path: '/workouts/add'
        })
    },
    setResults(value) {
      this.workouts = value
    },
    handleScroll(/*event*/) {
      this.isUserScrolling = window.scrollY > 0
      this.bottom = this.bottomVisible()
    },
    manageVisibility(workout) {
      this.selectedWorkout = workout
      this.showVisibilityDialog = true
    },
    cancelVisibility() {
      this.selectedWorkout = {}
      this.showVisibilityDialog = false
    },
    showExercise(exerciseid) {
      this.switchNum++
      this.selectedExercise = exerciseid
      this.showExerciseInfo = true
    },
    cancelInfo() {
      this.selectedExercise = {}
      this.showExerciseInfo = false
    },
    filterWorkouts(type) {
      this.filterLabel = type
      this.workoutType = type
    },
    editWorkout(workout) {
      this.$router.push({
        path: '/workouts/edit/' + workout.workoutid
      })
    },
    convertWorkout(workout) {
      this.selectedWorkout = workout
      this.showConvertDialog = true
    },
    copyWorkout(workout) {
      if (!this.is_pro && this.itemsCount > 4) {
        this.showUpgradeWarning = true
      } else {
        this.selectedWorkout = workout
        this.selectedWorkout.newname = 'Copy Of ' + this.selectedWorkout.name
        this.showCopyDialog = true
      }
    },

    copyNow() {
      return axios
        .post(this.baseURL + '/workout/copy', {
          workoutid: this.selectedWorkout.workoutid,
          description: this.selectedWorkout.description,
          newname: this.selectedWorkout.newname,
          now: this.now()
        })
        .then(response => {
          if (response.status == 200) {
            this.showCopyDialog = false
            this.planned = true
            this.toast(
              'Workout ' + this.selectedWorkout.newname + ' was copied!'
            )
            this.loadPage()
          } else {
            this.$store.dispatch(
              'notification/addErrors',
              response.data.errors,
              5000,
              true,
              {
                root: true
              }
            )
            return
          }
        })
    },
    saveVisibility() {
      this.selectedWorkout.public =
        this.selectedWorkout.public == 'Yes' ? 'No' : 'Yes'
      return axios
        .put(this.baseURL + '/workout/visibility', {
          workout: this.selectedWorkout
        })
        .then(response => {
          if (response.status == 200) {
            this.toast('Workout visibility was succesfully set..')
            this.cancelVisibility()
          } else {
            this.$store.dispatch(
              'notification/addErrors',
              response.data.errors,
              5000,
              true,
              {
                root: true
              }
            )
            return
          }
        })
    },
    addFavorite(workout) {
      return axios
        .post(this.baseURL + '/workouts/favorites', {
          workoutid: workout.workoutid
        })
        .then(response => {
          if (response.status == 200) {
            this.switchNum++
            workout.is_favorite = 'Yes'
            this.toast(
              'Workout: ' + workout.name + ' succesfully added to favorites.'
            )
          } else {
            this.$store.dispatch(
              'notification/addErrors',
              response.data.errors,
              5000,
              true,
              {
                root: true
              }
            )
            return
          }
        })
    },
    removeFavorite(workout) {
      return axios
        .delete(this.baseURL + '/workouts/favorites/' + workout.workoutid, {})
        .then(response => {
          if (response.status == 200) {
            this.switchNum++
            workout.is_favorite = 'No'
            this.toast(
              'Workout: ' +
                workout.name +
                ' succesfully removed from favorites.'
            )
          } else {
            this.$store.dispatch(
              'notification/addErrors',
              response.data.errors,
              5000,
              true,
              {
                root: true
              }
            )
            return
          }
        })
    },

    loadGlosary() {
      {
        return axios
          .get(this.baseURL + '/util/glossary/all', {})
          .then(response => {
            if (response.status == 200) {
              this.glossary = response.data.data
              this.typesList = this.glossary.filter(function(item) {
                return item.category == 'Workout Types'
              })
            } else {
              this.$store.dispatch(
                'notification/addErrors',
                response.data.errors,
                5000,
                true,
                {
                  root: true
                }
              )
              return
            }
          })
      }
    },
    toggleFavorites() {
      if (this.favoritesOnly) {
        this.workouts = this.loadedList.filter(function(item) {
          return item.is_favorite == 'Yes'
        })
        this.favoritesNum = this.workouts.length
      } else {
        this.workouts = this.loadedList
        this.favoritesNum = 0
      }
    },
    convertNow() {
      return axios
        .put(this.baseURL + '/workout/convert', {
          workout: this.selectedWorkout
        })
        .then(response => {
          if (response.status == 200) {
            this.showConvertDialog = false
            this.loadPage()
          } else {
            this.$store.dispatch(
              'notification/addErrors',
              response.data.errors,
              5000,
              true,
              {
                root: true
              }
            )
            return
          }
        })
    },
    loadRepschemes() {
      return axios
        .get(this.baseURL + '/tools/repschemes/all', {})
        .then(response => {
          if (response.status == 200) {
            this.repSchemes = response.data.data
          } else {
            this.$store.dispatch(
              'notification/addErrors',
              response.data.errors,
              5000,
              true,
              {
                root: true
              }
            )
            return
          }
        })
    },
    loadMuscleGroups() {
      return axios
        .get(this.baseURL + '/util/muscles/groups/all', {})
        .then(response => {
          if (response.status == 200) {
            this.muscleGroups = response.data.data
          } else {
            this.$store.dispatch(
              'notification/addErrors',
              response.data.errors,
              5000,
              true,
              {
                root: true
              }
            )
            return
          }
        })
    },
    repSchemeName(repschemeid) {
      let scheme = this.repSchemes.filter(item => {
        return item.id == repschemeid
      })
      return scheme[0].name
    },
    selectRepScheme(repschemeid) {
      let scheme = this.repSchemes.filter(item => {
        return item.id == repschemeid
      })
      this.selectedRepScheme = scheme[0]
      this.selectedRepScheme.detailsObject = JSON.parse(
        this.selectedRepScheme.details
      )
      this.showRepSchemeDialog = true
    },
    startWorkout(workout) {
      let path = '/workouts/start/' + workout.workoutid
      if (workout.type == 'Circuit')
        path = '/workouts/start/hiit/' + workout.workoutid
      else if (workout.type == 'Hybrid') {
        let type = 'rft'
        if (workout.xmode == 'time') type = 'rft'
        else type = workout.xmode
        path = '/workouts/start/' + type + '/' + workout.workoutid
        // emom, rft, amrap
      }
      this.$router.push({
        path: path
      })
    },
    loadPage() {
      this.loaded = false
      let url = '/workouts/search'
      const urlParams = new URLSearchParams()
      urlParams.append('musclegroup', this.muscleGroup.muscle_group)
      urlParams.append('adhoc', this.planned ? 'No' : 'Yes')
      urlParams.append('type', this.workoutType)

      return axios
        .get(this.baseURL + url, { params: urlParams })
        .then(response => {
          if (response.status == 200) {
            let newResponse = response.data.data
            newResponse.forEach(el => {
              el.exercises = JSON.parse(el.exercises)
              el.showExercises = false
            })

            if (
              this.mode == this.previousMode &&
              this.previousMode != '' &&
              !this.refresh
            ) {
              this.workouts = this.workouts.concat(newResponse)
            } else {
              this.workouts = newResponse
            }
            this.loadedList = this.workouts.slice()

            this.loadRepschemes()
            this.previousMode = this.mode
            this.loaded = true
          } else {
            this.$store.dispatch(
              'notification/addErrors',
              response.data.errors,
              5000,
              true,
              {
                root: true
              }
            )
            return
          }
        })
    }
  }
}
</script>
